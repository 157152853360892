import React, { useEffect, useState } from "react";
import NavBar from "./components/NavBar";
import {
  Button,
  Col,
  Div,
  Icon,
  Image,
  Modal,
  Notification,
  Row,
  Tag,
  Text,
} from "atomize";
import useDocumentTitle from "./components/useDocumentTitle";
import { ReactComponent as Close } from "./assets/icon/close.svg";
import Intro1 from "./assets/images/report_detail/intro1.png";
import Intro2 from "./assets/images/report_detail/intro2.png";
import Intro3 from "./assets/images/report_detail/intro3.png";
import CardBg from "./assets/images/report_detail/card_bg.png";
import CardRole1 from "./assets/images/report_detail/card_role1.png";
import CardRole2 from "./assets/images/report_detail/card_role2.png";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ReactComponent as Arrow } from "./assets/icon/arrow.svg";
import { ConfigProvider, Table } from "antd";
import First from "./assets/images/report_detail/first.png";
import Second from "./assets/images/report_detail/second.png";
import Third from "./assets/images/report_detail/third.png";
import Fourth from "./assets/images/report_detail/forth.png";
import Fifth from "./assets/images/report_detail/fifth.png";
import { ReactComponent as Information } from "./assets/icon/information.svg";
import SmallModal from "./components/SmallModal";
import { ReactComponent as PetsPurple } from "./assets/icon/pets_purple.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Btn from "./components/Btn";
import ToBeUnlocked from "./assets/images/report_easy/tobeunlocked.png";
import CardPage20 from "./assets/images/report_easy/card_page20.png";
import { ReactComponent as Line } from "./assets/icon/line.svg";
// import { ReactComponent as Link } from "./assets/icon/link.svg";
// import { ReactComponent as Instagram } from "./assets/icon/Instagram.svg";

const Title = ({ title }) => (
  <Text
    textSize={{ xs: "heading2", md: "heading1" }}
    textWeight="600"
    textColor={{ xs: "yellow", md: "neutral900" }}
    textAlign="center"
  >
    {title}
  </Text>
);

const SubTitle = ({ title }) => (
  <Div
    style={{ borderLeft: "8px solid", borderColor: "#555555" }}
    h={{ xs: "64px", md: "56px", lg: "48px" }}
  >
    <Text
      textSize={{ xs: "header", lg: "reportHeading2" }}
      textColor="neutral900"
      textWeight="600"
      m={{ l: "0.5rem", y: "0rem" }}
    >
      {title}
    </Text>
  </Div>
);

const Bar = ({ type, forwardPosition }) => (
  <Div d="flex" flexDir="column" m={{ x: "0.5rem" }}>
    {/* 低中高 */}
    <Div h="20px" w="100%" rounded="xl" d="flex" flexDir="row">
      <Div w="25%" textAlign="center">
        <Text textSize="reportHeading4" textColor="#000000" textWeight="600">
          低
        </Text>
      </Div>
      <Div w="50%" textAlign="center">
        <Text textSize="reportHeading4" textColor="#000000" textWeight="600">
          中
        </Text>
      </Div>
      <Div w="25%" textAlign="center">
        <Text textSize="reportHeading4" textColor="#000000" textWeight="600">
          高
        </Text>
      </Div>
    </Div>
    {/* bar and pointer */}
    <Div pos="relative">
      <Div h="20px" w="15rem" d="flex" flexDir="row" m={{ t: "0.5rem" }}>
        <Div w="25%" h="17px" bg={type === "higher" ? "#FF9696" : "#69BDA9"} />
        <Div w="50%" h="17px" bg="#FEDD8D" />
        <Div w="25%" h="17px" bg={type === "higher" ? "#69BDA9" : "#FF9696"} />
      </Div>
      <Div w="100%" pos="absolute" z-index="10" top="0.4rem">
        {/* //TODO: 用width決定箭頭置放位置，數值需+3 */}
        <Div w={`${forwardPosition + 3}%`} d="flex" justify="end">
          <Div bg="#48222C" w="15px" h="20px" rounded="3px" />
        </Div>
      </Div>
    </Div>
  </Div>
);

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const pieOption = {
  plugins: {
    legend: {
      position: "right",
      labels: {
        usePointStyle: true,
        font: {
          size: 13,
        },
      },
      onClick: () => {
        // 簡單地返回，阻止默認的點擊行為
        return;
      },
    },
    datalabels: {
      formatter: (value, context) => {
        return value + "%";
      },
      color: "#242424",
      display: true,
      align: (context) => {
        // 使用函數動態設定對齊方式
        const value = context.dataset.data[context.dataIndex];
        return value < 10 ? "end" : "center";
      },
      // anchor: (context) => {
      //   // 使用函數動態設定錨點
      //   const value = context.dataset.data[context.dataIndex];
      //   return value < 10 ? "end" : "center";
      // },
      borderRadius: 3,
      font: (context) => {
        // 使用函數動態設定字體大小
        const value = context.dataset.data[context.dataIndex];
        return {
          size: value > 9 ? 20 : 13,
          weight: 900,
        };
      },
    },
  },
};

const DiseaseResult = ({ name, chineseName, engName, description, score }) => (
  <Div>
    <Div d="flex" justify="center" align="center">
      <Div
        border="1px solid"
        borderColor="neutral900"
        rounded="0.5rem"
        bg="#FFECB1"
        m={{ y: "1.5rem" }}
        p={{ x: "1.5rem", y: "0.5rem" }}
      >
        <Text
          textAlign="center"
          textSize="header"
          textColor="neutral900"
          textWeight="600"
        >
          {chineseName}
          <br /> {engName}
        </Text>
      </Div>
    </Div>

    <Text textSize="body1" textColor="neutral800">
      {description}
    </Text>

    <Div
      d="flex"
      flexDir={{ xs: "column", md: "row" }}
      align="flex-end"
      m={{ t: "0.75rem", b: "1.25rem" }}
    >
      <Div
        w="100%"
        d="flex"
        flexDir={{ xs: "column", md: "row" }}
        align={{ xs: "flex-start", md: "flex-end" }}
      >
        <Text textSize="header" textColor="neutral700" textWeight="600">
          疾病風險評估：
        </Text>
        <Bar type="lower" forwardPosition={score} />
        <Div
          w={{ xs: "100%", md: "30%" }}
          d={{ xs: "flex" }}
          justify={{ xs: "flex-end", md: "flex-start" }}
          m={{ l: { md: "0.5rem" } }}
        >
          <Text
            tag="span"
            textSize="body1"
            textColor="neutral700"
            textWeight="400"
          >
            (越低越好)
          </Text>
        </Div>
      </Div>
    </Div>

    <Text tag="span" textSize="header" textColor="neutral700" textWeight="600">
      根據腸道菌相分析結果，{name} 患有 {chineseName} 的風險為：
      <Text
        tag="span"
        textColor={
          score > 75
            ? "#FF9696"
            : score >= 25 && score <= 75
            ? "#FFC842"
            : "#69BDA9"
        }
      >
        {score > 75
          ? "高風險"
          : score >= 25 && score <= 75
          ? "中風險"
          : "低風險"}
      </Text>
    </Text>
  </Div>
);

const ReportsDetail = () => {
  useDocumentTitle("報告結果 - PetSci");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 540);
  const [isPad, setIsPad] = useState(window.innerWidth < 1280);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 540);
      setIsPad(window.innerWidth < 1280);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  //目錄描點
  const scrollToSection = ({ id }) => {
    const toSection = document.getElementById(id);
    if (toSection) {
      //解決描點位置偏移
      const yOffset = -80;
      const y =
        toSection.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({
        top: y,
        behavior: "smooth",
      });
    }
  };

  //電腦版目錄隨之滾動
  const [desktopPaddingTop, setdesktopPaddingTop] = useState("23rem");
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        // 滾動時paddingTop改變數值
        setdesktopPaddingTop("3rem");
      } else {
        setdesktopPaddingTop("23rem");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //手機版目錄隨之滾動
  const [phonePosition, setphonePosition] = useState("static");
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        // 滾動時位置固定，且為menu未展開狀態
        setphonePosition("fixed");
        setMenuOpen(true);
      } else {
        setphonePosition("static");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  //若api回傳非200/402就給錯誤訊息
  const [showErrorNotification, setShowErrorNotification] = useState(false);

  const { reportId, reportSha } = useParams();
  // 使用者使用
  const detailApiUrl = `${process.env.REACT_APP_API_URL}/users/report/premium-report/${reportSha}`;
  // 內部使用
  const detailApiUrlForAdmin = `${process.env.REACT_APP_API_URL}/bar/report/premium-report/${reportId}`;
  // 取得當前的路徑資訊
  const location = useLocation();

  // 點按專業版按鈕時就打api，若是200可以前往該頁，若是402要跳pop up訊息
  const [unpaidModal, setUnpaidModal] = useState(false);

  const fetchReport = () => {
    setIsLoading(true);

    let apiEndpoint = "";

    if (location.pathname.includes("/reports/detail/")) {
      // 使用者使用
      apiEndpoint = detailApiUrl;
    } else if (location.pathname.includes("/reports/bar/PeSciGetRich/")) {
      // 內部使用
      apiEndpoint = detailApiUrlForAdmin;
    }

    fetch(apiEndpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 402) {
          setIsLoading(false);
          setUnpaidModal(true);
          // alert("unpaidModal " + unpaidModal);
        } else if (response.status === 404) {
          navigate(`/*`);
        } else if (response.status === 500) {
          setShowErrorNotification(true);
        } else {
          return response.json();
        }
      })
      .then((data) => {
        const aboutPet = data.detail_report_results.pet_info;
        setName(aboutPet.pet_name);
        setPet(aboutPet.pet_species);
        setPetInfo({
          pet_age: aboutPet.pet_age,
          pet_sex: aboutPet.pet_sex,
          pet_strain: aboutPet.pet_strain,
        });

        const aboutReport = data.detail_report_results.report_info;
        // console.log("aboutReport " + JSON.stringify(aboutReport));

        setReport({
          reportId: location.pathname.includes("/reports/detail/")
            ? reportSha?.substring(0, 8) || ""
            : data.detail_report_results.report_sha,
          owner_name: aboutReport.owner_name,
          report_date: aboutReport.report_date,
          report_abstract: aboutReport.report_abstract,
          report_summary: aboutReport.report_summary,
        });

        setReportPrScore({
          alpha_diversity: data.detail_report_results.alpha_diversity.pr_score,
          probiotic:
            data.detail_report_results.probiotic_pathogen_scores.probiotocs[0]
              .difference.pr_score,
          pathogens:
            data.detail_report_results.probiotic_pathogen_scores.pathogens[0]
              .difference.pr_score,
        });

        setTopFive(data.detail_report_results.top_5_taxon.rankings);
        setTopOther(
          data.detail_report_results.top_5_taxon.others.relative_abundance
        );

        const aboutDisease = data.detail_report_results.diseases;
        setDisease({
          gdv_risk: aboutDisease.gdv_risk?.pr_score || "",
          ce_risk: aboutDisease.ce_risk?.pr_score || "",
          ibd_risk: aboutDisease.ibd_risk?.pr_score || "",
        });

        setMainProbiotic(data.detail_report_results.dominant_probiotic);

        setGoodBac(data.detail_report_results.probiotics);
        //console.log("好菌 " + JSON.stringify(aboutProbiotic));

        setMainPathogen(data.detail_report_results.dominant_pathogen);

        setBadBac(data.detail_report_results.pathogens);
        // console.log(
        //   "壞菌 " + JSON.stringify(data.detail_report_results.pathogens)
        // );

        setFood(data.detail_report_results.food_recommendation);
        // console.log(
        //   "food " +
        //     JSON.stringify(data.detail_report_results.food_recommendation)
        // );

        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    fetchReport();
  }, []);

  const navigate = useNavigate();

  //寵物資訊
  const [pet, setPet] = useState("cat");
  const [name, setName] = useState("姆姆");
  //年齡、性別
  const [petInfo, setPetInfo] = useState({});
  // console.log("pet " + JSON.stringify(pet));
  // console.log("petInfo " + JSON.stringify(petInfo));

  //報告資訊
  const [report, setReport] = useState({});
  //console.log("report " + JSON.stringify(report));

  //腸道菌群檢測結果總覽
  const [reportPrScore, setReportPrScore] = useState({});
  //console.log("reportPrScore " + JSON.stringify(reportPrScore));

  //核心5菌
  const [topFive, setTopFive] = useState([]);
  // console.log("topFive  " + JSON.stringify(topFive));

  //其他(第6)
  const [topOther, setTopOther] = useState();
  //console.log("topOther  " + JSON.stringify(topOther));

  const coreBacData = topFive.map((item) => ({
    // 如果pieDataLabel的中文名有null，以英文名代替
    chineseName: item.bac_name_ch || item.bac_name_en,
    result: (item.relative_abundance * 100).toFixed(1),
  }));
  const OtherData = (topOther * 100).toFixed(1);
  //取名字
  const pieDataLabel = coreBacData.map((item) => item.chineseName);
  pieDataLabel.push("其他");
  //取數值
  const pieDataNumber = coreBacData.map((item) => parseFloat(item.result));
  pieDataNumber.push(parseFloat(OtherData));

  //如果相加超過100，或其他為負值
  const adjustPieData = (pieData, pieLabels) => {
    let sum = 0;
    let thresholdIndex = 0;

    // 找出開始超過100的index，index-1只加到前一格
    pieData.some((value, index) => {
      sum += value;
      if (sum > 100) {
        thresholdIndex = index - 1;
        return true; // 终止循环
      }
      return false;
    });

    // 如果總和不超過100，直接返回原陣列
    if (sum <= 100) {
      return { pieData: pieData, labels: pieLabels };
    }

    // 切割第0格~第N+1格(包括0、N)，遍歷當前每格(val)並壘加(acc)
    const partialSum = pieData
      .slice(0, thresholdIndex + 1)
      .reduce((acc, val) => acc + val, 0);

    // 計算剩餘的值
    const remainingValue = (100 - partialSum).toFixed(2);

    // 建立新的陣列，切割從第0格~第N格，帶上其他
    const adjustedPieData = [
      ...pieData.slice(0, thresholdIndex + 1),
      remainingValue,
    ];
    const adjustedLabels = [
      ...pieDataLabel.slice(0, thresholdIndex + 1),
      "其他",
    ];

    return { pieData: adjustedPieData, labels: adjustedLabels };
  };
  const { pieData: adjustedPieData, labels: adjustedPieLabels } = adjustPieData(
    pieDataNumber,
    pieDataLabel
  );

  //放進圓餅圖中
  const pieData = {
    labels: adjustedPieLabels,
    datasets: [
      {
        data: adjustedPieData,
        backgroundColor: (function () {
          const labels = adjustedPieLabels;
          const colors = [
            "#69BDA9",
            "#DC6B9A",
            "#FEDD8D",
            "#F2936A",
            "#AB89F3",
          ];

          //如果labels是其他，顯示灰色，若不是顯示下一個顏色
          return labels.map((label, index) =>
            label === "其他" ? "#A6A4A1" : colors[index % colors.length]
          );
        })(),
      },
    ],
  };

  //將原先格式轉化為符合coreBac.dataSorce的格式(因為需要符合table的欄位值)
  const coreBacSource = topFive.map((item, index) => ({
    key: item.ranking.toString(),
    image:
      index === 0
        ? First
        : index === 1
        ? Second
        : index === 2
        ? Third
        : index === 3
        ? Fourth
        : Fifth,
    name: {
      image: item.bac_img_src,
      chineseName: item.bac_name_ch,
      engName: item.bac_name_en,
      work: item.occupation,
    },
    function: item.description,
    result: (item.relative_abundance * 100).toFixed(2) + "%",
  }));
  const coreBac = {
    //antd 規定要是array
    //直接賦值
    dataSource: [...coreBacSource],
    //antd 規定要是array
    columns: [
      {
        title: <div style={{ textAlign: "center" }}>菌種名稱</div>,
        dataIndex: "name",
        key: "name",
        width: "25%",
        // 答案需要的UI樣式，且設定要搭配dataSource顯示的變數值
        render: (name, record) => (
          <Div d="flex" flexDir="column" justify="center" align="center">
            <Image w="70%" src={name.image} />
            <Text
              textAlign="center"
              textSize="body"
              textWeight="600"
              textColor="#555555"
            >
              {isMobile && (
                <>
                  <Div pos="absolute" top="0.5rem" right="0.5rem">
                    <SmallModal object={<Information />}>
                      <Div>
                        <Div
                          d="flex"
                          flexDir="row"
                          justify="start"
                          align="center"
                          p={{ x: "1rem", y: "1rem" }}
                        >
                          <Image w="30%" src={name.image} />
                          <Div w="80vw" m={{ l: "0.5rem" }}>
                            <Text
                              tag="p"
                              textAlign="center"
                              textSize="body"
                              textWeight="600"
                              textColor="#555555"
                            >
                              {name.chineseName}
                              <br />
                              <Text tag="span" style={{ fontStyle: "italic" }}>
                                {name.engName}
                              </Text>
                              <br />
                              {name.work}
                            </Text>
                          </Div>
                        </Div>

                        <Div>
                          <Div bg="#FFECB1" p={{ y: "1.5rem" }}>
                            <Text
                              textColor="neutral900"
                              textSize="14px"
                              textAlign="center"
                              textWeight="600"
                            >
                              菌種功能
                            </Text>
                          </Div>
                          <Div
                            d="felx"
                            justify="center"
                            align="center"
                            p={{ x: "1rem", y: "1rem" }}
                          >
                            <Text
                              textColor="neutral800"
                              textSize="reportHeading4"
                              textAlign="start"
                              textWeight="600"
                            >
                              {record.function}
                            </Text>
                          </Div>
                        </Div>
                      </Div>
                    </SmallModal>
                  </Div>
                </>
              )}
              {name.chineseName}
              <br />
              <Text tag="span" style={{ fontStyle: "italic" }}>
                {name.engName}
              </Text>
              <br />
              {name.work}
            </Text>
          </Div>
        ),
      },
      {
        title: <div style={{ textAlign: "center" }}>菌種功能</div>,
        dataIndex: "function",
        key: "function",
        width: "50%",
        render: (functional) => (
          <Div>
            <Text
              textAlign="start"
              textSize="body"
              textWeight="600"
              textColor="#555555"
            >
              {functional}
            </Text>
          </Div>
        ),
      },
      {
        title: <div style={{ textAlign: "center" }}>檢測結果</div>,
        dataIndex: "result",
        key: "result",
        width: "25%",
        render: (resultNum) => (
          <Div>
            <Text
              textAlign="center"
              textSize="heading3"
              textWeight="600"
              textColor="#555555"
            >
              {resultNum}
            </Text>
          </Div>
        ),
      },
    ],
  };

  //益生菌及致病菌table欄位設定
  const columnTable = [
    {
      title: <div style={{ textAlign: "center" }}>菌種名稱</div>,
      dataIndex: "name",
      key: "name",
      width: "25%",
      // 答案需要的UI樣式，且設定要搭配dataSource顯示的變數值
      render: (name, record) => (
        <Div d="flex" flexDir="column" justify="center" align="center">
          <Image w="70%" src={name.image} />
          <Text
            textAlign="center"
            textSize="body"
            textWeight="600"
            textColor="#555555"
          >
            {isMobile && (
              <>
                <Div pos="absolute" top="0.5rem" right="0.5rem">
                  <SmallModal object={<Information />}>
                    <Div>
                      <Div
                        d="flex"
                        flexDir="row"
                        justify="start"
                        align="center"
                        p={{ x: "1rem", y: "1rem" }}
                      >
                        <Image w="30%" src={name.image} />
                        <Div w="80vw" m={{ l: "0.5rem" }}>
                          <Text
                            textAlign="center"
                            textSize="body"
                            textWeight="600"
                            textColor="#555555"
                          >
                            {name.chineseName}
                            <br />
                            <Text tag="span" style={{ fontStyle: "italic" }}>
                              {name.engName}
                            </Text>
                            <br />
                            {name.work}
                          </Text>
                        </Div>
                      </Div>

                      <Div>
                        <Div bg="#FFECB1" p={{ y: "1.5rem" }}>
                          <Text
                            textColor="neutral900"
                            textSize="14px"
                            textAlign="center"
                            textWeight="600"
                          >
                            菌種功能
                          </Text>
                        </Div>
                        <Div
                          d="felx"
                          justify="center"
                          align="center"
                          p={{ x: "1rem", y: "1rem" }}
                        >
                          <Text
                            textColor="neutral800"
                            textSize="reportHeading4"
                            textAlign="start"
                            textWeight="600"
                          >
                            {record.function}
                          </Text>
                        </Div>
                      </Div>

                      <Div m={{ t: "2rem" }}>
                        <Text
                          textSize="reportHeading4"
                          textAlign="center"
                          textWeight="600"
                          textColor="neutral600"
                        >
                          參考區間：{record.interval}
                        </Text>
                      </Div>
                    </Div>
                  </SmallModal>
                </Div>
              </>
            )}
            {name.chineseName}
            <br />
            <Text tag="span" style={{ fontStyle: "italic" }}>
              {name.engName}
            </Text>
            <br />
            {name.work}
          </Text>
        </Div>
      ),
    },
    {
      title: <div style={{ textAlign: "center" }}>菌種功能</div>,
      dataIndex: "function",
      key: "function",
      width: "40%",
      render: (functional) => (
        <Div>
          <Text
            textAlign="start"
            textSize="body"
            textWeight="600"
            textColor="#555555"
          >
            {functional}
          </Text>
        </Div>
      ),
    },
    {
      title: <div style={{ textAlign: "center" }}>參考區間</div>,
      dataIndex: "interval",
      key: "interval",
      width: "10%",
      render: (interval) => (
        <Div>
          <Text
            textAlign="center"
            textSize="14px"
            textWeight="600"
            textColor="#555555"
          >
            {interval}
          </Text>
        </Div>
      ),
    },
    {
      title: <div style={{ textAlign: "center" }}>檢測結果</div>,
      dataIndex: "result",
      key: "result",
      width: "25%",
      render: (result) => {
        return (
          <Div>
            <Div m={{ y: "0.5rem" }}>
              <Text
                textAlign="center"
                textSize="heading3"
                textWeight="600"
                textColor="#000000"
              >
                {result.num}%
              </Text>
            </Div>
            {result.prscore !== 0 && (
              <Div d="flex" flexDir="column">
                <Div h="20px" w="100%" rounded="xl" d="flex" flexDir="row">
                  <Div w="25%" textAlign="center">
                    <Text
                      textSize={isMobile ? "11px" : "reportHeading4"}
                      textColor="#000000"
                      textWeight="600"
                    >
                      {result.type === "higher" ? "不足" : "稀少"}
                    </Text>
                  </Div>
                  <Div w="50%" textAlign="center">
                    <Text
                      textSize={isMobile ? "11px" : "reportHeading4"}
                      textColor="#000000"
                      textWeight="600"
                    >
                      尚可
                    </Text>
                  </Div>
                  <Div w="25%" textAlign="center">
                    <Text
                      textSize={isMobile ? "11px" : "reportHeading4"}
                      textColor="#000000"
                      textWeight="600"
                    >
                      {result.type === "higher" ? "充足" : "過多"}
                    </Text>
                  </Div>
                </Div>
                <Div pos="relative">
                  <Div
                    h="20px"
                    w="100%"
                    d="flex"
                    flexDir="row"
                    m={{ t: "0.5rem" }}
                  >
                    <Div
                      w="25%"
                      h="17px"
                      bg={result.type === "higher" ? "#FF9696" : "#69BDA9"}
                    />
                    <Div w="50%" h="17px" bg="#FEDD8D" />
                    <Div
                      w="25%"
                      h="17px"
                      bg={result.type === "higher" ? "#69BDA9" : "#FF9696"}
                    />
                  </Div>
                  <Div w="100%" pos="absolute" z-index="10" top="0.4rem">
                    <Div w={`${result.prscore + 3}%`} d="flex" justify="end">
                      <Div bg="#48222C" w="15px" h="20px" rounded="3px" />
                    </Div>
                  </Div>
                </Div>
              </Div>
            )}
          </Div>
        );
      },
    },
  ];

  const bacColumn = [
    {
      title: <div style={{ textAlign: "center" }}>菌種名稱</div>,
      dataIndex: "name",
      key: "name",
      width: "25%",
      // 答案需要的UI樣式，且設定要搭配dataSource顯示的變數值
      render: (name, record) => (
        <Div d="flex" flexDir="column" justify="center" align="center">
          <Image w="70%" src={name.image} />
          <Text
            textAlign="center"
            textSize="body"
            textWeight="600"
            textColor="#555555"
          >
            {isMobile && (
              <>
                <Div pos="absolute" top="0.5rem" right="0.5rem">
                  <SmallModal object={<Information />}>
                    <Div>
                      <Div
                        d="flex"
                        flexDir="row"
                        justify="start"
                        align="center"
                        p={{ x: "1rem", y: "1rem" }}
                      >
                        <Image w="30%" src={name.image} />
                        <Div w="80vw" m={{ l: "0.5rem" }}>
                          <Text
                            textAlign="center"
                            textSize="body"
                            textWeight="600"
                            textColor="#555555"
                          >
                            {name.chineseName}
                            <br />
                            <Text tag="span" style={{ fontStyle: "italic" }}>
                              {name.engName}
                            </Text>
                            <br />
                            {name.work}
                          </Text>
                        </Div>
                      </Div>

                      <Div>
                        <Div bg="#FFECB1" p={{ y: "1.5rem" }}>
                          <Text
                            textColor="neutral900"
                            textSize="14px"
                            textAlign="center"
                            textWeight="600"
                          >
                            菌種功能
                          </Text>
                        </Div>
                        <Div
                          d="flex"
                          justify="center"
                          align="center"
                          p={{ x: "1rem", y: "1rem" }}
                        >
                          <Text
                            textColor="neutral800"
                            textSize="reportHeading4"
                            textAlign="start"
                            textWeight="600"
                          >
                            {record.function}
                          </Text>
                        </Div>
                      </Div>
                    </Div>
                  </SmallModal>
                </Div>
              </>
            )}
            {name.chineseName}
            <br />
            <Text tag="span" style={{ fontStyle: "italic" }}>
              {name.engName}
            </Text>
            <br />
            {name.work}
          </Text>
        </Div>
      ),
    },
    {
      title: <div style={{ textAlign: "center" }}>菌種功能</div>,
      dataIndex: "function",
      key: "function",
      width: "50%",
      render: (functional) => (
        <Div>
          <Text
            textAlign="start"
            textSize="body"
            textWeight="600"
            textColor="#555555"
          >
            {functional}
          </Text>
        </Div>
      ),
    },
    {
      title: <div style={{ textAlign: "center" }}>檢測結果</div>,
      dataIndex: "result",
      key: "result",
      width: "25%",
      render: (resultNum) => (
        <Div>
          <Text
            textAlign="center"
            textSize="heading3"
            textWeight="600"
            textColor="#555555"
          >
            {resultNum}
          </Text>
        </Div>
      ),
    },
  ];

  const [mainProbiotic, setMainProbiotic] = useState({});
  const [mainPathogen, setMainPathogen] = useState({});
  //主要益生菌、致病菌
  const mainBacData = [
    {
      type: "Good",
      name: {
        image: mainProbiotic.bac_img_src,
        chineseName: mainProbiotic.bac_name_ch,
        engName: mainProbiotic.bac_name_en,
        work: mainProbiotic.occupation,
      },
      function: mainProbiotic.description,
      result: (mainProbiotic.relative_abundance * 100).toFixed(2) + "%",
    },
    {
      type: "Bad",
      name: {
        image: mainPathogen.bac_img_src,
        chineseName: mainPathogen.bac_name_ch,
        engName: mainPathogen.bac_name_en,
        work: mainPathogen.occupation,
      },
      function: mainPathogen.description,
      result: (mainPathogen.relative_abundance * 100).toFixed(2) + "%",
    },
  ];

  const [goodBac, setGoodBac] = useState({});
  //console.log("goodBac " + JSON.stringify(goodBac));
  // object.entries 取得所有property的name和value，並以陣列回傳
  const goodBacData = Object.entries(goodBac).map(([key, values]) => ({
    key: key,
    data: values
      .filter((item) => (item.relative_abundance * 100).toFixed(2) != 0)
      .map((item, idx) => ({
        key: idx,
        name: {
          image: item.bac_img_src,
          chineseName: item.bac_name_ch,
          engName: item.bac_name_en,
          work: item.occupation,
        },
        function: item.description,
        interval:
          item.difference.Q1_relative_abundance == 0 ||
          item.difference.Q3_relative_abundance == 0
            ? "尚無文獻"
            : (item.difference.Q1_relative_abundance * 100).toFixed(2) +
              "-" +
              (item.difference.Q3_relative_abundance * 100).toFixed(2) +
              "%",
        result: {
          num: (item.relative_abundance * 100).toFixed(2),
          prscore: Number(item.difference.pr_score),
          type: "higher",
        },
      })),
  }));
  // console.log("益生菌DATA " + JSON.stringify(goodBacData));

  const goodBacTable = [...goodBacData];

  const [badBac, setBadBac] = useState([]);
  // console.log("hh " + JSON.stringify(badBac));
  const badBacData = badBac
    .filter((item) => (item.relative_abundance * 100).toFixed(2) != 0)
    .map((item, index) => ({
      key: index,
      name: {
        image: item.bac_img_src,
        chineseName: item.bac_name_ch,
        engName: item.bac_name_en,
        work: item.occupation,
      },
      function: item.description,
      interval:
        item.difference.Q1_relative_abundance == 0 ||
        item.difference.Q3_relative_abundance == 0
          ? "尚無文獻"
          : (item.difference.Q1_relative_abundance * 100).toFixed(2) +
            "-" +
            (item.difference.Q3_relative_abundance * 100).toFixed(2) +
            "%",
      result: {
        num: (item.relative_abundance * 100).toFixed(2),
        prscore: item.difference.pr_score,
        type: "lower",
      },
    }));
  // console.log("badbacdata " + JSON.stringify(badBacData));
  const badBacTable = [...badBacData];

  //疾病風險評估
  const [disease, setDisease] = useState({});
  //console.log("disease " + JSON.stringify(disease));

  const [food, setFood] = useState([]);
  //console.log("food " + JSON.stringify(food));
  const foodData = food.map((item, index) => ({
    key: index,
    no: `🏅第 ${index + 1} 名`,
    type: item.group_name,
    recommend:
      item.food_names.length > 1
        ? item.food_names.join("、")
        : item.food_names[0],
    suggest: item.food_descriptions,
  }));
  //console.log("foodData " + JSON.stringify(foodData));

  const foodTable = {
    data: [...foodData],
    columns: [
      {
        title: "",
        dataIndex: "no",
        key: "no",
        width: "20%",
        className: "header-no",
        onHeaderCell: () => ({
          style: {
            backgroundColor: "#ffffff",
            borderTop: "none",
            borderLeft: "none",
          },
        }),
        render: (no) => (
          <>
            <Div>
              <Text
                textSize="reportHeading4"
                textColor="neutral900"
                textWeight="900"
                textAlign="center"
              >
                {no}
              </Text>
            </Div>
          </>
        ),
      },
      {
        title: <div style={{ textAlign: "center" }}>食物種類</div>,
        dataIndex: "type",
        key: "type",
        width: "20%",
        render: (type) => (
          <>
            <Text
              textSize="reportHeading4"
              textColor="neutral900"
              textWeight="900"
              textAlign="center"
            >
              {type}
            </Text>
          </>
        ),
      },
      {
        title: <div style={{ textAlign: "center" }}>強力推薦食物</div>,
        dataIndex: "recommend",
        key: "recommend",
        width: "20%",
        render: (food) => (
          <>
            <Text
              textSize="reportHeading4"
              textColor="#EB4C27"
              textWeight="900"
              textAlign="center"
            >
              {food}
            </Text>
          </>
        ),
      },
      {
        title: <div style={{ textAlign: "center" }}>科學毛怪的建議</div>,
        key: "suggest",
        dataIndex: "suggest",
        width: "40%",
        onHeaderCell: () => ({
          style: { backgroundColor: "#D8E8FF" },
        }),
        render: (suggest) => (
          <>
            <Text
              textSize="reportHeading4"
              textColor="neutral900"
              textWeight="900"
              textAlign="start"
            >
              {suggest}
            </Text>
          </>
        ),
      },
    ],
  };

  //float button
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  //全文內容不得複製
  useEffect(() => {
    const handleCopy = (event) => {
      event.preventDefault();
      alert("內容受到保護");
    };

    document.addEventListener("copy", handleCopy);

    return () => {
      document.removeEventListener("copy", handleCopy);
    };
  }, []);

  //分享區域
  // const [copied, setCopied] = useState(false);
  // const copyUrl = () => {
  //   const url = window.location.href; // 獲取當前網址
  //   navigator.clipboard
  //     .writeText(url) // 將網址寫入剪貼簿
  //     .then(() => {
  //       setCopied(true); // 設置提示信息為「已複製」
  //       setTimeout(() => setCopied(false), 2000); // 兩秒後清除提示信息
  //     })
  //     .catch((err) => {
  //       console.error("複製失敗: ", err);
  //     });
  // };

  // const shareToInstagram = () => {
  //   alert("cc")
  //   const instagramShareUrl = `instagram://camera`;
  //   window.location.href = instagramShareUrl;
  // };

  const shareToLine = () => {
    const url = window.location.href;
    const text =
      "快來看牠的腸道菌相檢測結果，我發現好多酷酷的菌，快到科學毛怪一同檢測一波!";

    const lineShareUrl = `line://msg/text/${encodeURIComponent(
      text
    )}%20${encodeURIComponent(url)}`;
    window.location.href = lineShareUrl;
  };

  return (
    <>
      {isLoading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          {unpaidModal ? (
            <Modal
              isOpen={unpaidModal}
              rounded="md"
              maxW="48rem"
              align={{ xs: "start", md: "center", lg: "center" }}
              justify="center"
            >
              <Div>
                <Text textSize="header" textColor="#242424" textWeight="900">
                  解鎖專業腸道菌相檢測報告
                </Text>
                <Row
                  d="flex"
                  justify="center"
                  align="center"
                  m={{ t: { xs: "1.5rem", md: "0rem" } }}
                >
                  <Col size={{ xs: 12, md: 6, lg: 6 }}>
                    <Div
                      d="flex"
                      flexDir="column"
                      align="center"
                      justify="center"
                      rounded={{
                        b: "xl",
                      }}
                    >
                      <Div
                        w="70px"
                        h="70px"
                        bgImg={CardPage20}
                        bgSize="cover"
                        bgPos="center"
                      />

                      <Div m={{ t: "1rem" }}>
                        <Text
                          textSize="24px"
                          textColor="#242424"
                          textWeight="700"
                          textAlign="center"
                        >
                          \ 歡迎解鎖更多內容 /
                        </Text>

                        <Div
                          d="flex"
                          flexDir="column"
                          justify="center"
                          align="center"
                          m={{ t: "1.5rem" }}
                        >
                          <Text
                            tag="span"
                            textSize="16px"
                            textColor="#242424"
                            textWeight="700"
                            m={{ b: "0.5rem" }}
                          >
                            想了解更多毛孩
                            <Text
                              tag="span"
                              textSize="16px"
                              textColor="#EB4C27"
                              textWeight="700"
                              textDecor="underline"
                            >
                              健康資訊
                            </Text>
                            嗎?
                          </Text>
                          <Text
                            tag="span"
                            textSize="16px"
                            textColor="#242424"
                            textWeight="700"
                            m={{ b: "0.5rem" }}
                          >
                            想了解毛孩
                            <Text
                              tag="span"
                              textSize="16px"
                              textColor="#EB4C27"
                              textWeight="700"
                              textDecor="underline"
                            >
                              適合補充的營養
                            </Text>
                            嗎?
                          </Text>
                          <Text
                            tag="span"
                            textSize="16px"
                            textColor="#242424"
                            textWeight="700"
                            m={{ b: "0.5rem" }}
                          >
                            想為毛孩健康
                            <Text
                              tag="span"
                              textSize="16px"
                              textColor="#EB4C27"
                              textWeight="700"
                              textDecor="underline"
                            >
                              採取行動
                            </Text>
                            嗎?
                          </Text>
                        </Div>
                      </Div>
                    </Div>
                  </Col>
                  <Col size={{ xs: 12, md: 6, lg: 6 }}>
                    <Div
                      d="flex"
                      flexDir="column"
                      justify="center"
                      align="center"
                    >
                      <Div
                        d="flex"
                        flexDir="column"
                        align="center"
                        justify="center"
                        rounded={{
                          b: "xl",
                        }}
                        m={{ t: "2rem", x: "3rem" }}
                      >
                        <Div m={{ t: "0.5rem" }}>
                          <Image src={ToBeUnlocked} w="220px" />
                        </Div>
                        <Div d="flex" justify="center" align="center">
                          <Text
                            tag="span"
                            textSize="header"
                            textColor="#242424"
                            textWeight="700"
                          >
                            NT $
                          </Text>
                          <Text
                            tag="span"
                            textSize="2.25rem"
                            textColor="#242424"
                            textWeight="700"
                          >
                            999
                          </Text>
                        </Div>
                      </Div>
                      <Div
                        d="flex"
                        flexDir="row"
                        justify="center"
                        align="flex-start"
                        m={{ t: "1.25rem" }}
                      >
                        <Div d="flex" flexDir="column">
                          <Text
                            textSize="subheading1"
                            textWeight="500"
                            textColor="#555555"
                          >
                            📍優勢菌概況
                          </Text>
                          <Text
                            textSize="subheading1"
                            textWeight="500"
                            textColor="#555555"
                          >
                            📍益生菌比例
                          </Text>
                          <Text
                            textSize="subheading1"
                            textWeight="500"
                            textColor="#555555"
                          >
                            📍致病菌比例
                          </Text>
                          <Text
                            textSize="subheading1"
                            textWeight="500"
                            textColor="#555555"
                          >
                            📍疾病風險評估
                          </Text>
                          <Text
                            textSize="subheading1"
                            textWeight="500"
                            textColor="#555555"
                          >
                            📍報告總評
                          </Text>
                          <Text
                            textSize="subheading1"
                            textWeight="500"
                            textColor="#555555"
                          >
                            📍核心菌群
                          </Text>
                          <Text
                            textSize="subheading1"
                            textWeight="500"
                            textColor="#555555"
                          >
                            📍菌種功能解析
                          </Text>
                        </Div>

                        <Div d="flex" flexDir="column" m={{ l: "1rem" }}>
                          <Text
                            textSize="subheading1"
                            textWeight="500"
                            textColor="#555555"
                          >
                            📍多樣性分析
                          </Text>
                          <Text
                            textSize="subheading1"
                            textWeight="500"
                            textColor="#555555"
                          >
                            📍報告摘要
                          </Text>
                          <Text
                            textSize="subheading1"
                            textWeight="500"
                            textColor="#555555"
                          >
                            📍益生菌檢測
                          </Text>
                          <Text
                            textSize="subheading1"
                            textWeight="500"
                            textColor="#555555"
                          >
                            📍致病菌檢測
                          </Text>
                          <Text
                            textSize="subheading1"
                            textWeight="500"
                            textColor="#555555"
                          >
                            📍整體腸道菌比例
                          </Text>
                          <Text
                            textSize="subheading1"
                            textWeight="500"
                            textColor="#555555"
                          >
                            📍客製化飲食推薦
                          </Text>
                        </Div>
                      </Div>
                    </Div>
                  </Col>
                </Row>
                <Div
                  m={{ t: "1.5rem" }}
                  d="flex"
                  justify="center"
                  onClick={() =>
                    window.open("https://lin.ee/zjeRT9i", "_blank")
                  }
                >
                  <Div w="200px">
                    <Btn text="立即解鎖" />
                  </Div>
                </Div>
              </Div>
            </Modal>
          ) : (
            <>
              <NavBar />

              <Div
                d="flex"
                flexDir="row"
                m={{ t: "45px" }}
                //禁止被複製
                style={{
                  userSelect: "none",
                  WebkitUserSelect: "none",
                  MozUserSelect: "none",
                  msUserSelect: "none",
                }}
              >
                {/* 電腦版目錄 */}
                <Div
                  d={isPad ? "none" : "flex"}
                  p={{ l: "4.5rem", t: desktopPaddingTop }}
                  pos="fixed"
                  zIndex="999"
                >
                  <Div
                    border="2px solid"
                    borderColor="neutral800"
                    bg="#ffffff"
                    w="180px"
                    h="320px"
                    p={{ x: "2rem", y: "1.5rem" }}
                    rounded="0.5rem"
                  >
                    <Text
                      textSize="heading3"
                      textColor="neutral900"
                      textWeight="600"
                    >
                      內容目錄
                    </Text>
                    <Text
                      textSize="heading3"
                      textColor="neutral800"
                      textWeight="400"
                      textDecor="underline"
                      m={{ t: "0.5rem", b: "0rem" }}
                      onClick={() => scrollToSection({ id: "intro" })}
                      cursor="pointer"
                    >
                      報告介紹
                    </Text>
                    <Text
                      textSize="heading3"
                      textColor="neutral800"
                      textWeight="400"
                      textDecor="underline"
                      m={{ t: "0.5rem", b: "0rem" }}
                      onClick={() => scrollToSection({ id: "abstract" })}
                      cursor="pointer"
                    >
                      報告摘要
                    </Text>
                    <Text
                      textSize="heading3"
                      textColor="neutral800"
                      textWeight="400"
                      textDecor="underline"
                      m={{ t: "0.5rem", b: "0rem" }}
                      onClick={() => scrollToSection({ id: "bac" })}
                      cursor="pointer"
                    >
                      腸道族群
                    </Text>
                    <Text
                      textSize="heading3"
                      textColor="neutral800"
                      textWeight="400"
                      textDecor="underline"
                      m={{ t: "0.5rem", b: "0rem" }}
                      onClick={() => scrollToSection({ id: "disease" })}
                      cursor="pointer"
                    >
                      疾病風險評估
                    </Text>
                    <Text
                      textSize="heading3"
                      textColor="neutral800"
                      textWeight="400"
                      textDecor="underline"
                      m={{ t: "0.5rem", b: "0rem" }}
                      onClick={() => scrollToSection({ id: "food-recommend" })}
                      cursor="pointer"
                    >
                      食物營養建議
                    </Text>
                    <Text
                      textSize="heading3"
                      textColor="neutral800"
                      textWeight="400"
                      textDecor="underline"
                      m={{ t: "0.5rem", b: "0rem" }}
                      onClick={() => scrollToSection({ id: "doctor" })}
                      cursor="pointer"
                    >
                      報告總評
                    </Text>
                    <Text
                      textSize="heading3"
                      textColor="neutral800"
                      textWeight="400"
                      textDecor="underline"
                      m={{ t: "0.5rem", b: "0rem" }}
                      onClick={() =>
                        scrollToSection({ id: "more-information" })
                      }
                      cursor="pointer"
                    >
                      補充說明
                    </Text>
                  </Div>
                </Div>

                <Div
                  m={{
                    t: { xs: "0rem", md: "0rem", lg: "0rem", xl: "5rem" },
                    b: { lg: "4rem" },
                    x: { xs: "0rem", md: "0rem", lg: "0rem" },
                    l: { xl: "18rem" },
                    r: { xl: "18rem" },
                  }}
                >
                  {/* 寵物資訊 */}
                  <Div
                    d={isPad ? "none" : "flex"}
                    flexDir="column"
                    justify="center"
                    align="flex-start"
                    m={{ b: "3rem" }}
                  >
                    <Div m={{ b: "0.75rem" }}>
                      <Text
                        textSize={{ xs: "heading2", lg: "36px" }}
                        textWeight="600"
                        textColor="neutral900"
                      >
                        腸毛相 ｜寵物腸道菌相檢測報告
                      </Text>
                    </Div>
                    <Text
                      textSize={{ xs: "subheading1", lg: "header" }}
                      textWeight="600"
                      textColor="neutral700"
                    >
                      檢測編號：{report.reportId}
                    </Text>
                    <Text
                      textSize={{ xs: "subheading1", lg: "header" }}
                      textWeight="600"
                      textColor="neutral700"
                    >
                      檢測時間：{report.report_date}
                    </Text>
                    <Text
                      textSize={{ xs: "subheading1", lg: "header" }}
                      textWeight="600"
                      textColor="neutral700"
                    >
                      寵物姓名：{name} （{pet === "D" ? "犬" : "貓"}）
                    </Text>
                    <Text
                      textSize={{ xs: "subheading1", lg: "header" }}
                      textWeight="600"
                      textColor="neutral700"
                    >
                      寵物種類：{petInfo.pet_strain}
                    </Text>
                    <Text
                      textSize={{ xs: "subheading1", lg: "header" }}
                      textWeight="600"
                      textColor="neutral700"
                    >
                      寵物年齡：{petInfo.pet_age}歲
                    </Text>
                    <Text
                      textSize={{ xs: "subheading1", lg: "header" }}
                      textWeight="600"
                      textColor="neutral700"
                    >
                      飼主姓名：{report.owner_name}
                    </Text>
                  </Div>

                  {/* 白框區域 */}
                  <Div
                    border={{
                      xs: "none",
                      md: "none",
                      lg: "none",
                      xl: "2px solid",
                    }}
                    borderColor="neutral800"
                    bg="#ffffff"
                    p={{
                      x: {
                        xs: "1rem",
                        md: "3.5rem",
                        lg: "4.5rem",
                        xl: "5.5rem",
                      },
                      y: { xs: "2.5rem", lg: "3.5rem" },
                    }}
                    rounded="8px"
                    d="flex"
                    flexDir="column"
                    maxW="100vw"
                  >
                    {/* 寵物資訊 */}
                    <Div
                      d={isPad ? "flex" : "none"}
                      flexDir="column"
                      justify="center"
                      align="flex-start"
                      m={{ b: "1.75rem" }}
                    >
                      <Div m={{ b: "0.75rem" }}>
                        <Text
                          textSize={{ xs: "heading2", lg: "36px" }}
                          textWeight="600"
                          textColor="neutral900"
                        >
                          腸毛相 ｜寵物腸道菌相檢測報告
                        </Text>
                      </Div>
                      <Text
                        textSize={{ xs: "subheading1", lg: "header" }}
                        textWeight="600"
                        textColor="neutral700"
                      >
                        檢測編號：{report.reportId}
                      </Text>
                      <Text
                        textSize={{ xs: "subheading1", lg: "header" }}
                        textWeight="600"
                        textColor="neutral700"
                      >
                        檢測時間：{report.report_date}
                      </Text>
                      <Text
                        textSize={{ xs: "subheading1", lg: "header" }}
                        textWeight="600"
                        textColor="neutral700"
                      >
                        寵物姓名：{name} （{pet === "D" ? "犬" : "貓"}）
                      </Text>
                      <Text
                        textSize={{ xs: "subheading1", lg: "header" }}
                        textWeight="600"
                        textColor="neutral700"
                      >
                        寵物種類：{petInfo.pet_strain}
                      </Text>
                      <Text
                        textSize={{ xs: "subheading1", lg: "header" }}
                        textWeight="600"
                        textColor="neutral700"
                      >
                        寵物年齡：{petInfo.pet_age}歲
                      </Text>
                      <Text
                        textSize={{ xs: "subheading1", lg: "header" }}
                        textWeight="600"
                        textColor="neutral700"
                      >
                        飼主姓名：{report.owner_name}
                      </Text>
                    </Div>

                    {/* 手機版目錄 */}
                    <Div
                      d={isPad ? "flex" : "none"}
                      pos={phonePosition}
                      zIndex="999"
                      top="5rem"
                    >
                      <Div
                        border="2px solid"
                        borderColor="neutral800"
                        bg="rgb(241, 237, 238, 0.85)"
                        w="100%"
                        p={{ x: "0.5rem", y: "0.5rem" }}
                        m={{ b: "2.25rem" }}
                        rounded="0.5rem"
                      >
                        {isMenuOpen ? (
                          <Div d="flex" justify="space-between" align="center">
                            <Div
                              d={phonePosition === "fixed" ? "none" : "flex"}
                            >
                              <Text
                                textSize="heading3"
                                textColor="neutral900"
                                textWeight="600"
                              >
                                內容目錄
                              </Text>
                            </Div>

                            <Icon
                              name="DownArrow"
                              size="20px"
                              onClick={toggleMenu}
                              transition
                            />
                          </Div>
                        ) : (
                          <>
                            <Div
                              d="flex"
                              flexDir="row"
                              justify="space-between"
                              align="center"
                            >
                              <Text
                                textSize="heading3"
                                textColor="neutral900"
                                textWeight="600"
                              >
                                內容目錄
                              </Text>
                              <Close onClick={toggleMenu} />
                            </Div>

                            <Div>
                              <Text
                                textSize="heading3"
                                textColor="neutral800"
                                textWeight="400"
                                textDecor="underline"
                                m={{ t: "0.5rem", b: "0rem" }}
                                onClick={() => scrollToSection({ id: "intro" })}
                                cursor="pointer"
                              >
                                報告介紹
                              </Text>
                              <Text
                                textSize="heading3"
                                textColor="neutral800"
                                textWeight="400"
                                textDecor="underline"
                                m={{ t: "0.5rem", b: "0rem" }}
                                onClick={() =>
                                  scrollToSection({ id: "abstract" })
                                }
                                cursor="pointer"
                              >
                                報告摘要
                              </Text>
                              <Text
                                textSize="heading3"
                                textColor="neutral800"
                                textWeight="400"
                                textDecor="underline"
                                m={{ t: "0.5rem", b: "0rem" }}
                                onClick={() => scrollToSection({ id: "bac" })}
                                cursor="pointer"
                              >
                                腸道族群
                              </Text>
                              <Text
                                textSize="heading3"
                                textColor="neutral800"
                                textWeight="400"
                                textDecor="underline"
                                m={{ t: "0.5rem", b: "0rem" }}
                                onClick={() =>
                                  scrollToSection({ id: "disease" })
                                }
                                cursor="pointer"
                              >
                                疾病風險評估
                              </Text>
                              <Text
                                textSize="heading3"
                                textColor="neutral800"
                                textWeight="400"
                                textDecor="underline"
                                m={{ t: "0.5rem", b: "0rem" }}
                                onClick={() =>
                                  scrollToSection({ id: "food-recommend" })
                                }
                                cursor="pointer"
                              >
                                食物營養建議
                              </Text>
                              <Text
                                textSize="heading3"
                                textColor="neutral800"
                                textWeight="400"
                                textDecor="underline"
                                m={{ t: "0.5rem", b: "0rem" }}
                                onClick={() =>
                                  scrollToSection({ id: "doctor" })
                                }
                                cursor="pointer"
                              >
                                報告總評
                              </Text>
                              <Text
                                textSize="heading3"
                                textColor="neutral800"
                                textWeight="400"
                                textDecor="underline"
                                m={{ t: "0.5rem", b: "0rem" }}
                                onClick={() =>
                                  scrollToSection({ id: "more-information" })
                                }
                                cursor="pointer"
                              >
                                補充說明
                              </Text>
                            </Div>
                          </>
                        )}
                      </Div>
                    </Div>

                    <Title
                      title={
                        <>
                          歡迎來到科學毛怪， <br />
                          腸毛相究竟裝了哪些秘密 ?
                        </>
                      }
                    />

                    {/* 產品介紹-1 */}
                    <Div
                      id="intro"
                      d="flex"
                      flexDir="column"
                      justify="center"
                      align="flex-start"
                      m={{ t: { xs: "2rem", md: "4rem" } }}
                    >
                      <SubTitle title="作為毛孩的飼主，看完這份報告會怎麼幫助你?" />

                      <Div w="100%" m={{ t: "1.5rem" }}>
                        <Div
                          d="flex"
                          flexDir={{ xs: "column", md: "row", lg: "row" }}
                          justify={{
                            xs: "center",
                            md: "space-between",
                            lg: "space-between",
                          }}
                          align="center"
                        >
                          <Div
                            m={{
                              r: {
                                xs: "0rem",
                                md: "3rem",
                                lg: "4rem",
                                xl: "5rem",
                              },
                            }}
                            d="flex"
                            flexDir="column"
                            justify="center"
                            align="center"
                            w="176px"
                          >
                            <Image src={Intro1} />
                            <Text
                              textSize="body1"
                              textColor="neutral900"
                              m={{ t: "1rem" }}
                            >
                              透過寵物腸道微生物精準分析，讓腸毛相幫你快速掌握寵物健康！
                            </Text>
                          </Div>
                          <Div
                            m={{
                              r: {
                                xs: "0rem",
                                md: "3rem",
                                lg: "4rem",
                                xl: "5rem",
                              },
                            }}
                            d="flex"
                            flexDir="column"
                            justify="center"
                            align="center"
                            w="176px"
                          >
                            <Image src={Intro2} />
                            <Text
                              textSize="body1"
                              textColor="neutral900"
                              m={{ t: "1rem" }}
                            >
                              擁有台灣大學專業分析技術，能夠為你的毛孩精準推薦食物。
                            </Text>
                          </Div>
                          <Div
                            m={{ r: "0rem" }}
                            d="flex"
                            flexDir="column"
                            justify="center"
                            align="center"
                            w="176px"
                          >
                            <Image src={Intro3} />
                            <Text
                              textSize="body1"
                              textColor="neutral900"
                              m={{ t: "1rem" }}
                            >
                              利用此份寵物腸道檢測報告，與獸醫師共同探討毛孩的健康狀況。
                            </Text>
                          </Div>
                        </Div>
                      </Div>
                    </Div>

                    {/* 產品介紹-2 */}
                    <Div
                      d="flex"
                      flexDir="column"
                      justify="center"
                      align="flex-start"
                      m={{ t: { xs: "2rem", md: "4rem" } }}
                    >
                      <SubTitle title="你知道什麼是「腸道菌相」嗎?" />
                      <Text
                        tag="span"
                        textSize="body1"
                        textColor="neutral800"
                        textWeight="400"
                        m={{ t: "1.5rem" }}
                      >
                        腸道菌相 (Microbiome)，是指
                        <Text
                          tag="span"
                          textSize="body1"
                          textColor="neutral800"
                          textWeight="700"
                        >
                          居住在寵物腸道中的細菌、病毒、真菌和原生動物等微小的族群
                        </Text>
                        ，擁有好的腸道菌相能有效幫助身體健康；反之，則產生疾病。根據目前研究指出，
                        <Text
                          tag="span"
                          textSize="body1"
                          textColor="neutral800"
                          textWeight="700"
                        >
                          腸道菌相與糖尿病、心臟病、氣喘、過敏、腸胃疾病及其他疾病之間皆存在著相關性。
                        </Text>
                        因此，維持腸道菌相平衡對寵物的整體健康非常重要。然而，
                        <Text
                          tag="span"
                          textSize="body1"
                          textColor="neutral800"
                          textWeight="700"
                        >
                          食物
                        </Text>
                        對於寵物的腸道菌相的健康和族群有非常大的影響，當你每天在餵食寵物時，其實也在餵養著寵物的腸道菌相，透過補充不同的營養可大幅改變寵物的腸道菌相，進一步促進寵物的整體健康。
                      </Text>
                    </Div>

                    {/* 產品介紹-3 */}
                    <Div
                      d="flex"
                      flexDir="column"
                      justify="center"
                      align="flex-start"
                      m={{
                        t: { xs: "2rem", md: "4rem" },
                        b: { xs: "2rem", lg: "1.5rem" },
                      }}
                    >
                      <SubTitle title="科學毛怪會怎麼為寵物進行「腸道菌相檢測」呢？" />
                      <Text
                        tag="span"
                        textSize="body1"
                        textColor="neutral800"
                        textWeight="400"
                        m={{ t: "1.5rem" }}
                      >
                        腸道菌相檢測簡單來說就是對住在寵物腸道中的微生物進行「人口普查」來幫助我們了解這些微生物的種類、職業、功能，進一步了解他們如何影響寵物的健康。
                      </Text>
                      <br />
                      <Text
                        tag="span"
                        textSize="body1"
                        textColor="neutral800"
                        textWeight="400"
                        m={{ t: "1.5rem" }}
                      >
                        💡如果我們把住在腸道的微生物們，想像是一個小小國家,那麼腸道國的居民會長什麼樣呢...
                      </Text>
                    </Div>

                    <Div
                      className="responsive-container"
                      d="flex"
                      flexDir="row"
                      justify="start"
                      align="start"
                      m={{ b: "4rem", x: "0rem" }}
                    >
                      <Div
                        minW="328px"
                        w="100%"
                        h="627px"
                        m={{ r: { xs: "0.5rem", lg: "1.75rem" } }}
                        bg="#ffffff"
                        shadow="4"
                        rounded="22px"
                        p={{ x: "1.2rem", y: "1.5rem" }}
                      >
                        <Div>
                          <Text
                            textSize="reportHeading1"
                            textWeight="600"
                            textColor="yellow"
                          >
                            🔎 一隻住在腸道的菌種
                          </Text>
                          <Div
                            w="100%"
                            h="308px"
                            bgImg={CardBg}
                            rounded="xl"
                            bgSize="cover"
                            bgRepeat="no-repeat"
                            bgPos="center"
                            pos="relative"
                            m={{ t: "0.81rem" }}
                          >
                            {/* 角色 */}
                            <Div pos="absolute" zIndex="2" top="25px">
                              <Div d="flex" justify="center" align="center">
                                <Image
                                  w={{
                                    xs: "95%",
                                    md: "80%",
                                    lg: "70%",
                                    xl: "80%",
                                  }}
                                  src={CardRole1}
                                />
                              </Div>
                            </Div>
                          </Div>
                          <Div m={{ t: "0.8rem" }}>
                            {/* 姓名 */}
                            <Div
                              d="flex"
                              flexDir="row"
                              justify="start"
                              align="center"
                              flexWrap="nowrap"
                            >
                              <Div d="flex">
                                <Tag
                                  bg="#555555"
                                  textColor="#ffffff"
                                  textSize="reportHeading3"
                                  textWeight="600"
                                  p={{ x: "0.625rem", y: "0.25rem" }}
                                  m={{ r: "0.7rem" }}
                                >
                                  姓名
                                </Tag>
                              </Div>

                              <Div d="flex" flexDir="row" flexGrow={1}>
                                <Text
                                  tag="span"
                                  textColor="#242424"
                                  textSize="reportHeading2"
                                  textWeight="600"
                                >
                                  雙歧桿菌
                                  <Text
                                    textColor="#242424"
                                    textSize="12px"
                                    textWeight="400"
                                    style={{ fontStyle: "italic" }}
                                  >
                                    Bifidobacterium pullorum
                                  </Text>
                                </Text>
                              </Div>
                            </Div>
                            {/* 種族 */}
                            <Div
                              d="flex"
                              flexDir="row"
                              justify="start"
                              align="center"
                              m={{ t: "0.8rem" }}
                            >
                              <Tag
                                bg="#555555"
                                textColor="#ffffff"
                                textSize="reportHeading3"
                                textWeight="600"
                                p={{ x: "0.625rem", y: "0.25rem" }}
                                m={{ r: "0.7rem" }}
                              >
                                種族
                              </Tag>
                              <Text
                                textColor="#242424"
                                textSize="reportHeading2"
                                textWeight="600"
                              >
                                雙岐桿菌
                              </Text>
                            </Div>
                            {/* 職業 */}
                            <Div
                              d="flex"
                              flexDir="row"
                              justify="start"
                              align="center"
                              m={{ t: "0.8rem" }}
                            >
                              <Tag
                                bg="#555555"
                                textColor="#ffffff"
                                textSize="reportHeading3"
                                textWeight="600"
                                p={{ x: "0.625rem", y: "0.25rem" }}
                                m={{ r: "0.7rem" }}
                              >
                                職業
                              </Tag>
                              <Text
                                textColor="#242424"
                                textSize="reportHeading2"
                                textWeight="600"
                              >
                                發酵工程師
                              </Text>
                            </Div>

                            {/* 功能 */}
                            <Div
                              d="flex"
                              flexDir="row"
                              justify="start"
                              align="start"
                              m={{ t: "0.8rem" }}
                              flexWrap="nowrap"
                            >
                              <Div d="flex">
                                <Tag
                                  bg="#555555"
                                  textColor="#ffffff"
                                  textSize="reportHeading3"
                                  textWeight="600"
                                  p={{ x: "0.625rem", y: "0.25rem" }}
                                  m={{ r: "0.7rem" }}
                                >
                                  功能
                                </Tag>
                              </Div>
                              <Div flexGrow={1}>
                                <Text
                                  textColor="#242424"
                                  textSize="reportHeading2"
                                  textWeight="600"
                                >
                                  進行發酵產生好物質治療與預防腹瀉等
                                </Text>
                              </Div>
                            </Div>
                          </Div>
                        </Div>
                      </Div>

                      <Div
                        minW="328px"
                        w="100%"
                        h="627px"
                        bg="#ffffff"
                        shadow="4"
                        rounded="22px"
                        p={{ x: "1.2rem", y: "1.5rem" }}
                      >
                        <Div>
                          <Text
                            textSize="reportHeading1"
                            textWeight="600"
                            textColor="yellow"
                          >
                            🔎 一位住在台灣的小明
                          </Text>
                          <Div
                            w="100%"
                            h="308px"
                            bgImg={CardBg}
                            rounded="xl"
                            bgSize="cover"
                            bgRepeat="no-repeat"
                            bgPos="center"
                            pos="relative"
                            m={{ t: "0.81rem" }}
                          >
                            {/* 角色 */}
                            <Div pos="absolute" zIndex="2" top="25px">
                              <Div d="flex" justify="center" align="center">
                                <Image
                                  w={{
                                    xs: "95%",
                                    md: "75%",
                                    lg: "70%",
                                    xl: "80%",
                                  }}
                                  src={CardRole2}
                                />
                              </Div>
                            </Div>
                          </Div>
                          <Div m={{ t: "0.8rem" }}>
                            {/* 姓名 */}
                            <Div
                              d="flex"
                              flexDir="row"
                              justify="start"
                              align="center"
                              flexWrap="nowrap"
                            >
                              <Div d="flex">
                                <Tag
                                  bg="#555555"
                                  textColor="#ffffff"
                                  textSize="reportHeading3"
                                  textWeight="600"
                                  p={{ x: "0.625rem", y: "0.25rem" }}
                                  m={{ r: "0.7rem" }}
                                >
                                  姓名
                                </Tag>
                              </Div>

                              <Div d="flex" flexDir="row" flexGrow={1}>
                                <Text
                                  tag="span"
                                  textColor="#242424"
                                  textSize="reportHeading2"
                                  textWeight="600"
                                >
                                  王小明
                                  <Text
                                    textColor="#242424"
                                    textSize="12px"
                                    textWeight="400"
                                    style={{ fontStyle: "italic" }}
                                  >
                                    Jason
                                  </Text>
                                </Text>
                              </Div>
                            </Div>
                            {/* 種族 */}
                            <Div
                              d="flex"
                              flexDir="row"
                              justify="start"
                              align="center"
                              m={{ t: "0.8rem" }}
                            >
                              <Tag
                                bg="#555555"
                                textColor="#ffffff"
                                textSize="reportHeading3"
                                textWeight="600"
                                p={{ x: "0.625rem", y: "0.25rem" }}
                                m={{ r: "0.7rem" }}
                              >
                                種族
                              </Tag>
                              <Text
                                textColor="#242424"
                                textSize="reportHeading2"
                                textWeight="600"
                              >
                                客家人
                              </Text>
                            </Div>
                            {/* 職業 */}
                            <Div
                              d="flex"
                              flexDir="row"
                              justify="start"
                              align="center"
                              m={{ t: "0.8rem" }}
                            >
                              <Tag
                                bg="#555555"
                                textColor="#ffffff"
                                textSize="reportHeading3"
                                textWeight="600"
                                p={{ x: "0.625rem", y: "0.25rem" }}
                                m={{ r: "0.7rem" }}
                              >
                                職業
                              </Tag>
                              <Text
                                textColor="#242424"
                                textSize="reportHeading2"
                                textWeight="600"
                              >
                                會計師
                              </Text>
                            </Div>

                            {/* 功能 */}
                            <Div
                              d="flex"
                              flexDir="row"
                              justify="start"
                              align="start"
                              m={{ t: "0.8rem" }}
                              flexWrap="nowrap"
                            >
                              <Div d="flex">
                                <Tag
                                  bg="#555555"
                                  textColor="#ffffff"
                                  textSize="reportHeading3"
                                  textWeight="600"
                                  p={{ x: "0.625rem", y: "0.25rem" }}
                                  m={{ r: "0.7rem" }}
                                >
                                  功能
                                </Tag>
                              </Div>
                              <Div flexGrow={1}>
                                <Text
                                  textColor="#242424"
                                  textSize="reportHeading2"
                                  textWeight="600"
                                >
                                  協助公司財報檢核
                                </Text>
                              </Div>
                            </Div>
                          </Div>
                        </Div>
                      </Div>
                    </Div>

                    {/* 產品介紹-4 */}
                    <Div
                      d="flex"
                      flexDir="column"
                      justify="center"
                      align="flex-start"
                    >
                      <SubTitle title="那麼，在這份報告中你會得到什麼資訊?" />
                      <Text
                        tag="span"
                        textSize="body1"
                        textColor="neutral700"
                        textWeight="400"
                        m={{ t: "1.5rem" }}
                      >
                        <Text
                          tag="span"
                          textSize="body1"
                          textColor="neutral900"
                          textWeight="600"
                        >
                          📍腸道核心菌群組成
                        </Text>
                        – 了解寵物腸道的主要菌種
                      </Text>
                      <Text
                        tag="span"
                        textSize="body1"
                        textColor="neutral700"
                        textWeight="400"
                      >
                        <Text
                          tag="span"
                          textSize="body1"
                          textColor="neutral900"
                          textWeight="600"
                        >
                          📍腸道益生菌種類
                        </Text>
                        – 了解寵物腸道中益生菌種類及占比
                      </Text>
                      <Text
                        tag="span"
                        textSize="body1"
                        textColor="neutral700"
                        textWeight="400"
                      >
                        <Text
                          tag="span"
                          textSize="body1"
                          textColor="neutral900"
                          textWeight="600"
                        >
                          📍腸道致病菌種類
                        </Text>
                        – 了解寵物腸道中致病菌種類及比
                      </Text>
                      <Text
                        tag="span"
                        textSize="body1"
                        textColor="neutral700"
                        textWeight="400"
                      >
                        <Text
                          tag="span"
                          textSize="body1"
                          textColor="neutral900"
                          textWeight="600"
                        >
                          📍疾病風險評估
                        </Text>
                        – 根據腸道菌相評估可能的疾病風險
                      </Text>
                      <Text
                        tag="span"
                        textSize="body1"
                        textColor="neutral700"
                        textWeight="400"
                      >
                        <Text
                          tag="span"
                          textSize="body1"
                          textColor="neutral900"
                          textWeight="600"
                        >
                          📍寵物食物營養建議
                        </Text>
                        – 根據寵物腸道菌相計算所缺乏之營養素及建議補中的食物
                      </Text>
                      <Text
                        tag="span"
                        textSize="body1"
                        textColor="neutral700"
                        textWeight="400"
                      >
                        <Text
                          tag="span"
                          textSize="body1"
                          textColor="neutral900"
                          textWeight="600"
                        >
                          📍報告總評
                        </Text>
                        – 針對每隻寵物腸道菌相給予日常照護的專屬建議
                      </Text>
                    </Div>

                    {/* 報告摘要 */}
                    <Div id="abstract" m={{ t: "4rem" }}>
                      <Title title="報告摘要" />
                      <Div
                        m={{ t: { xs: "2rem", lg: "1.5rem" } }}
                        p={{ x: "1.5rem", y: "0.75rem" }}
                        bg="rgba(255, 236, 177, 0.50)"
                        rounded="16px"
                      >
                        <div
                          style={{ fontSize: "20px", color: "#595959" }}
                          dangerouslySetInnerHTML={{
                            __html: report.report_abstract,
                          }}
                        />
                      </Div>
                    </Div>

                    <Div id="bac" m={{ y: { xs: "2rem", lg: "4rem" } }}>
                      <Title
                        title={
                          <>
                            讓我們一起來看看 <br />『{name}』的腸道族群組成吧！
                          </>
                        }
                      />
                    </Div>

                    {/* 腸道菌檢測結果總覽 */}
                    <Div>
                      <SubTitle title={`${name} 的腸道菌群檢測結果總覽`} />
                      {/* 腸道菌多樣性 */}
                      <Div>
                        <Div
                          m={{ t: "1.5rem" }}
                          p={{ x: "1.5rem", y: "0.5rem" }}
                          border="1px solid"
                          borderColor="neutral900"
                          rounded="0.5rem"
                          w="170px"
                        >
                          <Text
                            textSize="header"
                            textColor="neutral900"
                            textWeight="600"
                          >
                            腸道菌多樣性
                          </Text>
                        </Div>

                        <Div m={{ y: "0.75rem" }}>
                          <Text
                            textSize="body1"
                            textColor="neutral800"
                            textWeight="400"
                          >
                            💡判斷腸道菌相健康與否的標準之一，高多樣性的腸道菌相代表腸道菌種種類多且分布均勻，顯示寵物的各項生理、代謝功能上都有足夠的能力；反之，低多樣性的腸道菌則代表少數菌群主導了腸道，表示在寵物許多代謝功能上可能有所缺乏。
                          </Text>
                        </Div>

                        <Div
                          d="flex"
                          flexDir={{ xs: "column", md: "row" }}
                          align="flex-end"
                        >
                          <Div
                            w="100%"
                            d="flex"
                            flexDir={{ xs: "column", md: "row" }}
                            align={{ xs: "flex-start", md: "flex-end" }}
                          >
                            <Text
                              textSize="header"
                              textColor="neutral700"
                              textWeight="600"
                            >
                              多樣性：
                            </Text>
                            <Bar
                              type="higher"
                              forwardPosition={reportPrScore.alpha_diversity}
                            />
                            <Div
                              w={{ xs: "100%", md: "30%" }}
                              d={{ xs: "flex" }}
                              justify={{ xs: "flex-end", md: "flex-start" }}
                              m={{ l: { md: "0.5rem" } }}
                            >
                              <Text
                                tag="span"
                                textSize="body1"
                                textColor="neutral700"
                                textWeight="400"
                              >
                                (越高越好)
                              </Text>
                            </Div>
                          </Div>

                          {/* <Text
          tag="span"
          textSize="body1"
          textColor="neutral700"
          textWeight="400"
        >
          (越高越好)
        </Text> */}
                        </Div>
                      </Div>

                      {/* 腸道益生菌及致病菌比例 */}
                      <Div>
                        <Div
                          m={{ t: "1.5rem" }}
                          p={{ x: "1.5rem", y: "0.5rem" }}
                          border="1px solid"
                          borderColor="neutral900"
                          rounded="0.5rem"
                          w="270px"
                        >
                          <Text
                            textSize="header"
                            textColor="neutral900"
                            textWeight="600"
                          >
                            腸道益生菌及致病菌比例
                          </Text>
                        </Div>

                        <Div m={{ y: "0.75rem" }}>
                          <Text
                            textSize="body1"
                            textColor="neutral800"
                            textWeight="400"
                          >
                            💡腸道細菌主要可分為三個種類分別為益生菌、致病菌及中性菌，三者互相平衡來維持腸道健康，透過檢測腸道中益生菌及致病菌的比例，可了解寵物在日常生活中是否需要補充益生菌及可能產生的疾病。
                          </Text>
                        </Div>

                        <Div
                          d="flex"
                          flexDir={{ xs: "column", md: "row" }}
                          align="flex-end"
                        >
                          <Div
                            w="100%"
                            d="flex"
                            flexDir={{ xs: "column", md: "row" }}
                            align={{ xs: "flex-start", md: "flex-end" }}
                          >
                            <Text
                              textSize="header"
                              textColor="neutral700"
                              textWeight="600"
                            >
                              整體益生菌比例：
                            </Text>
                            <Bar
                              type="higher"
                              forwardPosition={reportPrScore.probiotic}
                            />
                            <Div
                              w={{ xs: "100%", md: "30%" }}
                              d={{ xs: "flex" }}
                              justify={{ xs: "flex-end", md: "flex-start" }}
                              m={{ l: { md: "0.5rem" } }}
                            >
                              <Text
                                tag="span"
                                textSize="body1"
                                textColor="neutral700"
                                textWeight="400"
                              >
                                (越高越好)
                              </Text>
                            </Div>
                          </Div>
                        </Div>

                        <Div
                          d="flex"
                          flexDir={{ xs: "column", md: "row" }}
                          align="flex-end"
                          m={{ t: "0.75rem" }}
                        >
                          <Div
                            w="100%"
                            d="flex"
                            flexDir={{ xs: "column", md: "row" }}
                            align={{ xs: "flex-start", md: "flex-end" }}
                          >
                            <Text
                              textSize="header"
                              textColor="neutral700"
                              textWeight="600"
                            >
                              整體致病菌比例：
                            </Text>
                            <Bar
                              type="lower"
                              forwardPosition={reportPrScore.pathogens}
                            />
                            <Div
                              w={{ xs: "100%", md: "30%" }}
                              d={{ xs: "flex" }}
                              justify={{ xs: "flex-end", md: "flex-start" }}
                              m={{ l: { md: "0.5rem" } }}
                            >
                              <Text
                                tag="span"
                                textSize="body1"
                                textColor="neutral700"
                                textWeight="400"
                              >
                                (越低越好)
                              </Text>
                            </Div>
                          </Div>
                        </Div>
                      </Div>
                    </Div>

                    {/* 腸道核心菌群組成 */}
                    <Div m={{ t: "4rem" }}>
                      <SubTitle title={`${name} 的腸道核心菌群組成`} />
                      <Div m={{ y: "1.5rem" }}>
                        <Text
                          tag="span"
                          textSize="body1"
                          textColor="neutral800"
                          textWeight="400"
                        >
                          每隻寵物的腸道微生物菌群組成都是獨一無二的，本次報告中我們取出寵物腸道中
                          <Text tag="span" textWeight="600">
                            前五名的核心菌種
                          </Text>
                          ，來幫助您進一步了解寵物的各項身體機能，包含寵物的消化能力、免疫力、心血管疾病等。（因計算時會將屬與種合併計算，因此前五名菌種有可能超過
                          100%，為正常現象）
                        </Text>
                      </Div>

                      <Div
                        d="flex"
                        flexDir="column"
                        justify="center"
                        align="center"
                        m={{ y: "1.5rem" }}
                      >
                        <Div
                          p={{ x: "1.5rem", y: "0.5rem" }}
                          border="1px solid"
                          borderColor="neutral900"
                          rounded="0.5rem"
                          w="230px"
                        >
                          <Text
                            textSize="header"
                            textColor="neutral900"
                            textWeight="600"
                          >
                            前五名核心菌種比例
                          </Text>
                        </Div>
                        <Div p={{ l: "2rem" }} w="380px">
                          <Pie data={pieData} options={pieOption} />
                        </Div>

                        <Arrow />

                        <Div
                          m={{ t: "1.5rem" }}
                          p={{ x: "1.5rem", y: "0.5rem" }}
                          border="1px solid"
                          borderColor="neutral900"
                          rounded="0.5rem"
                        >
                          <Text
                            textSize="header"
                            textColor="neutral900"
                            textWeight="600"
                          >
                            ☝️前五名核心菌種介紹
                          </Text>
                        </Div>

                        <Div
                          minW="100%"
                          maxW={{ xs: "89vw", lg: "100vw", xl: "100vw" }}
                        >
                          <ConfigProvider
                            theme={{
                              components: {
                                Table: {
                                  headerBg: "#FFECB1",
                                  headerColor: "#242424",
                                  borderColor: "rgba(166, 164, 161, 0.50)",
                                  fontSize: 14,
                                  rowHoverBg: "#F6F6F6",
                                },
                              },
                            }}
                          >
                            {/* 抓出dataSource的array中的每個object */}
                            {coreBac.dataSource.map((index) => (
                              <>
                                <Div key={index.key} pos="relative">
                                  <Div
                                    pos="absolute"
                                    zIndex="1"
                                    top="0px"
                                    left="-15px"
                                  >
                                    <Image w="50%" src={index.image} />
                                  </Div>
                                </Div>

                                <Div m={{ t: "3rem" }}>
                                  <Table
                                    columns={
                                      isMobile
                                        ? coreBac.columns.filter(
                                            (column) =>
                                              column.key === "name" ||
                                              column.key === "result"
                                          )
                                        : coreBac.columns
                                    }
                                    dataSource={[index]}
                                    pagination={false}
                                  />
                                </Div>
                              </>
                            ))}
                          </ConfigProvider>
                        </Div>
                      </Div>
                    </Div>

                    {/* 腸道益生菌及致病菌 */}
                    <Div>
                      <Div m={{ y: "4rem" }}>
                        <Text
                          textAlign="center"
                          textSize="heading2"
                          textWeight="600"
                          textColor="neutral900"
                        >
                          \ 進一步看看 {name} 的腸道益生菌和致病菌 /
                        </Text>
                      </Div>
                      {/* 益生菌 */}
                      <Div>
                        <Div m={{ b: "4rem" }}>
                          <SubTitle title={`${name} 的腸道益生菌種類`} />
                          <Div m={{ y: "1.5rem" }}>
                            <Text
                              textSize="body1"
                              textWeight="400"
                              textColor="neutral800"
                            >
                              腸道中的益生菌可協助寵物消化吸收養分，抵禦有害的細菌並增強腸道的保護力，以下是寵物腸道中及市面上常見的益生菌，主要可分為比菲德式菌屬、乳酸菌屬、擬桿菌屬三類，透過檢測結果，可了解寵物腸道中益生菌的分佈及日常生活可加強補充的益生菌種類。
                            </Text>
                          </Div>

                          <Div
                            d="flex"
                            flexDir={{ xs: "column", md: "row" }}
                            align="flex-end"
                          >
                            <Div
                              w="100%"
                              d="flex"
                              flexDir={{ xs: "column", md: "row" }}
                              align={{ xs: "flex-start", md: "flex-end" }}
                            >
                              <Text
                                textSize="header"
                                textColor="neutral700"
                                textWeight="600"
                              >
                                整體益生菌比例：
                              </Text>
                              <Bar
                                type="higher"
                                forwardPosition={reportPrScore.probiotic}
                              />
                              <Div
                                w={{ xs: "100%", md: "30%" }}
                                d={{ xs: "flex" }}
                                justify={{ xs: "flex-end", md: "flex-start" }}
                                m={{ l: { md: "0.5rem" } }}
                              >
                                <Text
                                  tag="span"
                                  textSize="body1"
                                  textColor="neutral700"
                                  textWeight="400"
                                >
                                  (越高越好)
                                </Text>
                              </Div>
                            </Div>
                          </Div>
                        </Div>

                        <Div>
                          <Div d="flex" justify="center" align="center">
                            <Div
                              p={{ x: "1.5rem", y: "0.5rem" }}
                              border="1px solid"
                              borderColor="neutral900"
                              rounded="0.5rem"
                            >
                              <Text
                                textSize="header"
                                textColor="neutral900"
                                textWeight="600"
                              >
                                主要益生菌
                              </Text>
                            </Div>
                          </Div>

                          <Div maxW="100vw">
                            <ConfigProvider
                              theme={{
                                components: {
                                  Table: {
                                    headerBg: "#FFECB1",
                                    headerColor: "#242424",
                                    borderColor: "rgba(166, 164, 161, 0.50)",
                                    fontSize: 14,
                                    rowHoverBg: "#F6F6F6",
                                  },
                                },
                              }}
                            >
                              <Div m={{ t: "1.5rem" }}>
                                {/* 手機版時只抓特定欄位 */}
                                <Table
                                  columns={
                                    isMobile
                                      ? bacColumn.filter(
                                          (column) =>
                                            column.key === "name" ||
                                            column.key === "result"
                                        )
                                      : bacColumn
                                  }
                                  dataSource={mainBacData.filter(
                                    (bac) => bac.type === "Good"
                                  )}
                                  pagination={false}
                                />
                              </Div>
                            </ConfigProvider>
                          </Div>
                        </Div>

                        <Div m={{ t: "2rem" }}>
                          <Div d="flex" justify="center" align="center">
                            <Div
                              p={{ x: "1.5rem", y: "0.5rem" }}
                              border="1px solid"
                              borderColor="neutral900"
                              rounded="0.5rem"
                            >
                              <Text
                                textSize="header"
                                textColor="neutral900"
                                textWeight="600"
                              >
                                常見益生菌
                              </Text>
                            </Div>
                          </Div>

                          <Div maxW="100vw">
                            <Div>
                              {goodBacTable.map((item) =>
                                item.data.length > 0 ? (
                                  <Div key={item.key}>
                                    <Div m={{ t: "1.5rem" }}>
                                      <Text
                                        tag="span"
                                        textSize="header"
                                        textColor="neutral900"
                                        textWeight="600"
                                      >
                                        📍{item.key.split("_")[0]}
                                      </Text>
                                      <Text
                                        tag="span"
                                        textSize="header"
                                        textColor="neutral900"
                                        textWeight="600"
                                        style={{ fontStyle: "italic" }}
                                      >
                                        {" "}
                                        {item.key.split("_")[1]}
                                      </Text>
                                    </Div>

                                    <ConfigProvider
                                      theme={{
                                        components: {
                                          Table: {
                                            headerBg: "#FFECB1",
                                            headerColor: "#242424",
                                            borderColor:
                                              "rgba(166, 164, 161, 0.50)",
                                            fontSize: 14,
                                            rowHoverBg: "#F6F6F6",
                                          },
                                        },
                                      }}
                                    >
                                      <Div m={{ t: "1.5rem" }}>
                                        <Table
                                          columns={
                                            isMobile
                                              ? columnTable.filter(
                                                  (column) =>
                                                    column.key === "name" ||
                                                    column.key === "result"
                                                )
                                              : columnTable
                                          }
                                          dataSource={item.data}
                                          pagination={false}
                                        />
                                      </Div>
                                    </ConfigProvider>
                                  </Div>
                                ) : null
                              )}
                            </Div>
                          </Div>
                        </Div>
                      </Div>

                      {/* 致病菌 */}
                      <Div>
                        <Div m={{ y: "4rem" }}>
                          <SubTitle title={`${name} 的腸道致病菌種類`} />
                          <Div m={{ y: "1.5rem" }}>
                            <Text
                              textSize="body1"
                              textWeight="400"
                              textColor="neutral800"
                            >
                              正常來說，健康的寵物腸道中存在微量的致病菌，但當致病菌數量過多時就可能對寵物健康產生負面影響，嚴重也可能導致疾病，透過檢測寵物腸道中致病菌的數量及種類，可幫助我們了解寵物可能的疾病原因及整體健康狀況。{" "}
                            </Text>
                          </Div>

                          <Div
                            d="flex"
                            flexDir={{ xs: "column", md: "row" }}
                            align="flex-end"
                          >
                            <Div
                              w="100%"
                              d="flex"
                              flexDir={{ xs: "column", md: "row" }}
                              align={{ xs: "flex-start", md: "flex-end" }}
                            >
                              <Text
                                textSize="header"
                                textColor="neutral700"
                                textWeight="600"
                              >
                                整體致病菌比例：
                              </Text>
                              <Bar
                                type="lower"
                                forwardPosition={reportPrScore.pathogens}
                              />
                              <Div
                                w={{ xs: "100%", md: "30%" }}
                                d={{ xs: "flex" }}
                                justify={{ xs: "flex-end", md: "flex-start" }}
                                m={{ l: { md: "0.5rem" } }}
                              >
                                <Text
                                  tag="span"
                                  textSize="body1"
                                  textColor="neutral700"
                                  textWeight="400"
                                >
                                  (越低越好)
                                </Text>
                              </Div>
                            </Div>
                          </Div>
                        </Div>

                        <Div>
                          <Div d="flex" justify="center" align="center">
                            <Div
                              p={{ x: "1.5rem", y: "0.5rem" }}
                              border="1px solid"
                              borderColor="neutral900"
                              rounded="0.5rem"
                            >
                              <Text
                                textSize="header"
                                textColor="neutral900"
                                textWeight="600"
                              >
                                主要致病菌
                              </Text>
                            </Div>
                          </Div>

                          <Div maxW="100vw">
                            <ConfigProvider
                              theme={{
                                components: {
                                  Table: {
                                    headerBg: "#FFECB1",
                                    headerColor: "#242424",
                                    borderColor: "rgba(166, 164, 161, 0.50)",
                                    fontSize: 14,
                                    rowHoverBg: "#F6F6F6",
                                  },
                                },
                              }}
                            >
                              <Div m={{ t: "1.5rem" }}>
                                {/* 手機版時只抓特定欄位 */}
                                <Table
                                  columns={
                                    isMobile
                                      ? bacColumn.filter(
                                          (column) =>
                                            column.key === "name" ||
                                            column.key === "result"
                                        )
                                      : bacColumn
                                  }
                                  dataSource={mainBacData.filter(
                                    (bac) => bac.type === "Bad"
                                  )}
                                  pagination={false}
                                />
                              </Div>
                            </ConfigProvider>
                          </Div>
                        </Div>

                        <Div m={{ t: "2rem" }}>
                          <Div d="flex" justify="center" align="center">
                            <Div
                              p={{ x: "1.5rem", y: "0.5rem" }}
                              border="1px solid"
                              borderColor="neutral900"
                              rounded="0.5rem"
                            >
                              <Text
                                textSize="header"
                                textColor="neutral900"
                                textWeight="600"
                              >
                                常見致病菌
                              </Text>
                            </Div>
                          </Div>

                          <Div maxW="100vw">
                            <Div>
                              {badBacTable.map((item) => (
                                <Div key={item.key}>
                                  <ConfigProvider
                                    theme={{
                                      components: {
                                        Table: {
                                          headerBg: "#FFECB1",
                                          headerColor: "#242424",
                                          borderColor:
                                            "rgba(166, 164, 161, 0.50)",
                                          fontSize: 14,
                                          rowHoverBg: "#F6F6F6",
                                        },
                                      },
                                    }}
                                  >
                                    <Div m={{ t: "1.5rem" }}>
                                      <Table
                                        columns={
                                          isMobile
                                            ? columnTable.filter(
                                                (column) =>
                                                  column.key === "name" ||
                                                  column.key === "result"
                                              )
                                            : columnTable
                                        }
                                        dataSource={[item]}
                                        pagination={false}
                                      />
                                    </Div>
                                  </ConfigProvider>
                                </Div>
                              ))}
                            </Div>
                          </Div>
                        </Div>
                      </Div>
                    </Div>

                    {/* 疾病風險評估 */}
                    <Div id="disease" m={{ t: "4rem" }}>
                      <Title title={`${name} 的疾病風險評估`} />
                      <Div m={{ t: { xs: "2rem", lg: "4rem" }, b: "1.5rem" }}>
                        <SubTitle
                          title={`科學毛怪針對寵物常見的疾病，幫助 ${name} 進行疾病風險評估`}
                        />
                      </Div>
                      {pet === "C" ? (
                        <DiseaseResult
                          name={name}
                          chineseName="炎症性腸炎"
                          engName="Inflammatory bowel disease (IBD)"
                          description="是貓咪常見的一種慢性腸道疾病，常見症狀包括持續或間歇性腹瀉、嘔吐、食慾減退和體重下降。科學研究指出，貓咪腸道中的特定細菌群，如腸桿菌科、大腸桿菌和梭菌屬的數量，與腸道黏膜的健康狀態有著緊密的聯繫。這些細菌若過度增長，可能會破壞腸道黏膜的正常結構，進而引發 IBD。由於 IBD 無法根治，臨床上通常採用藥物治療來緩解發炎反應。此外，調整飲食和補充益生菌被認為是常見的輔助治療方式，有助於維持腸道菌群的平衡，保持腸道黏膜的健康，從而減輕症狀並提高貓咪的生活品質。"
                          score={disease.ibd_risk || ""}
                        />
                      ) : (
                        <>
                          <Div>
                            <DiseaseResult
                              name={name}
                              chineseName="慢性腸病"
                              engName="Chronic enteropathy (CE)"
                              description="是犬隻常見的一種慢性腸道疾病，症狀包含反覆的腹瀉及嘔吐，伴隨體重減輕。科學研究指出，狗狗腸道中的普拉梭菌屬可透過促進短鏈脂肪酸產生，來幫助維護腸黏膜健康，這些細菌若減少，可能會導致腸道黏膜受損，進而引發 CE。臨床上，治療方法包括藥物治療、寄生蟲驅除、飲食調整以及益生菌補充，透過恢復腸道菌相平衡，來減輕症狀並提高狗狗的生活品質。"
                              score={disease.ce_risk || ""}
                            />
                          </Div>
                          <Div m={{ t: "1.5rem" }}>
                            <DiseaseResult
                              name={name}
                              chineseName="胃扭轉"
                              engName="gastric dilatation-volvulus (GDV)"
                              description="好發於大型犬種，是一種嚴重的消化系統疾病，死亡率極高，其症狀為進食後兩到三個小時內出現腹腔膨大、乾嘔、流口水、喘氣等。 GDV 的起因為胃中空氣過多導致急性擴張，進一步引發胃部扭轉。若不及時治療，可能導致胃組織壞死，嚴重則導致狗狗死亡。科學研究指出， GDV 與腸胃道內的產氣微生物，如梭菌屬的過度生長有關。常規的治療方式是使用緊急手術來解除扭轉並固定胃部以防再次發生。在日常生活中，避免狗狗劇烈運動及一次食用過量食物可有效預防 GDV 的發生。"
                              score={disease.gdv_risk || ""}
                            />
                          </Div>
                        </>
                      )}

                      <Div m={{ t: "1.25rem" }}>
                        <Text textSize="body1" textColor="neutral600">
                          📢
                          此檢測結果由毛孩的腸道菌相檢測推得，若需確切診斷結果或後續治療，建議至獸醫院與醫生討論
                        </Text>
                      </Div>
                    </Div>

                    {/* 食物營養建議 */}
                    <Div id="food-recommend" m={{ y: "4rem" }}>
                      <Title title={`給 ${name} 的食物營養建議`} />
                      <Div m={{ t: { xs: "2rem", lg: "4rem" } }}>
                        <SubTitle
                          title={`經過腸道菌相檢測後，我們希望 ${name} 額外攝取的營養是...`}
                        />
                      </Div>

                      <Div m={{ y: "1.5rem" }}>
                        <Text textSize="body1">
                          食品營養推薦分析是我們的獨家技術，透過 AI
                          分析腸道所有居民的功能，來了解寵物的腸道中目前缺乏那些營養素，更進一步將營養素對應到可補充的食物，幫助毛孩的腸胃更加健康。
                        </Text>
                      </Div>
                      <Div maxW="100vw">
                        <ConfigProvider
                          theme={{
                            components: {
                              Table: {
                                headerBg: "#FFECB1",
                                headerColor: "#242424",
                                borderColor: "rgba(166, 164, 161, 0.50)",
                                fontSize: 14,
                                rowHoverBg: "#F6F6F6",
                              },
                            },
                          }}
                        >
                          <Table
                            columns={foodTable.columns}
                            dataSource={foodTable.data}
                            pagination={false}
                            scroll={{ x: 540 }}
                            bordered
                          />
                        </ConfigProvider>
                      </Div>
                    </Div>

                    {/* 獸醫師給的評價 */}
                    <Div id="doctor">
                      <Title title={`${name} 的報告總評`} />
                      <Div
                        m={{ t: { xs: "2rem", lg: "4rem" } }}
                        p={{ x: "1.5rem", y: "0.75rem" }}
                        bg="rgba(255, 236, 177, 0.50)"
                        rounded="16px"
                      >
                        <div
                          style={{ fontSize: "20px", color: "#595959" }}
                          dangerouslySetInnerHTML={{
                            __html: report.report_summary,
                          }}
                        />
                      </Div>
                    </Div>

                    {/* 補充說明 */}
                    <Div id="more-information" m={{ y: "4rem" }}>
                      <Title title="補充說明" />
                      <Div m={{ t: { xs: "2rem", lg: "4rem" } }}>
                        <Div textSize="body1" textColor="neutral700">
                          <ol
                            style={{
                              listStylePosition: "outside",
                              paddingLeft: "20px",
                            }}
                          >
                            <li>
                              本次檢體處理假國立彰化師範大學生物學系實驗室進行，並由科學毛怪團隊生物、資訊相關專業研究人員進行分析和判斷。如果對報告內容有任何問題，歡迎透過
                              E-mail、 Instagram 或 Facebook 與我們聯繫。{" "}
                            </li>
                            <li>
                              族群與比例是衡量腸道國是否健康的指標之一，本份報告我們參考文獻中健康犬/貓其腸道國組成，報告中所見的參考範圍都是來自於這些毛孩們的健康數據。若該菌種尚無相關文獻資料，則以「尚無文獻」標示，這或許您會感到困惑，然而事實上人類對於大自然的瞭解還只是皮毛而已，這些空白也促使著人類義無反顧地探索未知的領域~{" "}
                            </li>
                            <li>
                              菌相分析技術在學術界不斷演進，我們將持續改進 AI
                              模型和報告呈現方式，以及最佳化推薦結果，以提高閱讀的準確性。科學毛怪團隊擁有對於報告結果的最終解釋權。{" "}
                            </li>
                            <li>
                              我們致力於以淺顯易懂的方式呈現科學資訊，以便更容易理解和吸收。然而，如果飼主在收到報告後感到心理上有擔憂或受到後續心理影響，我們不承擔相關責任。{" "}
                            </li>
                            <li>
                              每個毛孩的數據對科學界來說都是寶貴的資源，我們將對毛孩和飼主的身份進行去識別化處理，進行科學研究，以促進寵物菌相檢測技術的進一步發展，如不便提供數據請直接聯繫我們。{" "}
                            </li>
                            <li>
                              這份報告是以毛孩糞便為基礎分析得出，非臨床診斷結果。如果毛孩已經有相關疾病，在未諮詢相關專業人員或未獲得獸醫師同意之前，請不要擅自改變原有的藥物和習慣。{" "}
                            </li>
                          </ol>
                        </Div>
                      </Div>
                    </Div>

                    <Text
                      textSize="header"
                      textColor="neutral900"
                      textWeight="600"
                      textAlign="center"
                    >
                      \ 報告結束📘若有問題請聯絡科學毛怪 PetSci /
                    </Text>
                  </Div>

                  {/* 分享區域 */}
                  {/* <Div
                    d="flex"
                    flexDir="row"
                    justify="center"
                    align="center"
                    m={{ t: "2rem", b: "2rem" }}
                  >
                    <Div m={{ r: "1.5rem" }} onClick={copyUrl}>
                      <Link />
                    </Div>
                    <Div m={{ r: "1.5rem" }} onClick={shareToInstagram}>
                      <Instagram />
                    </Div>
                    <Div m={{ r: "1.5rem" }} onClick={shareToLine}>
                      <Line />
                    </Div>
                  </Div> */}
                </Div>
              </Div>

              {/* float button */}
              <Div pos="relative">
                <Div
                  d="flex"
                  justify="center"
                  align="center"
                  pos="absolute"
                  zIndex="9"
                >
                  <Button
                    h="3rem"
                    w="3rem"
                    bg="#F3B23E"
                    border="2px solid"
                    borderColor="#FEF9DE"
                    hoverBorderColor="#FEF9DE"
                    rounded="circle"
                    shadow="3"
                    pos="fixed"
                    right={{ xs: "0.75rem", md: "1.75rem" }}
                    bottom={{ xs: "0.75rem", md: "1.75rem" }}
                    onClick={scrollToTop}
                  >
                    <Div m={{ t: "0.2rem" }}>
                      <Text
                        textSize="10px"
                        textColor="#79384A"
                        textWeight="600"
                      >
                        Top
                      </Text>
                      <PetsPurple />
                    </Div>
                  </Button>
                </Div>
                <Div
                  d="flex"
                  justify="center"
                  align="center"
                  pos="absolute"
                  zIndex="9"
                >
                  <Button
                    h="3rem"
                    w="3rem"
                    bg="#F3B23E"
                    border="2px solid"
                    borderColor="#FEF9DE"
                    hoverBorderColor="#FEF9DE"
                    rounded="circle"
                    shadow="3"
                    pos="fixed"
                    right={{ xs: "0.75rem", md: "1.75rem" }}
                    bottom={{ xs: "4.5rem", md: "5.5rem" }}
                    onClick={shareToLine}
                  >
                    <Div m={{ t: "0.3rem" }}>
                      <Line />
                    </Div>
                  </Button>
                </Div>
              </Div>
            </>
          )}
        </>
      )}

      {/* api回傳非200/402/500時顯示錯誤訊息 */}
      <Div d="flex" justify="center" align="center">
        <Notification
          d={showErrorNotification ? "flex" : "none"}
          bg="#FFE0E0"
          textColor="#F03D3D"
          isOpen={showErrorNotification}
          onClose={() => setShowErrorNotification(false)}
        >
          發生未知錯誤，請洽客服。
        </Notification>

        {/* <Notification
          d={copied ? "flex" : "none"}
          bg="#E2FCF0"
          textColor="#0A9C55"
          isOpen={copied}
          onClose={() => setShowErrorNotification(false)}
        >
          已複製連結
        </Notification> */}
      </Div>
    </>
  );
};

export default ReportsDetail;
