import { Button, Div, Icon, Text } from "atomize";
import React, { useCallback, useEffect, useState } from "react";
import Btn from "./components/Btn";
import InputField from "./components/InputField";
import { ConfigProvider, Divider, Radio } from "antd";
import { useNavigate } from "react-router-dom";

const FieldName = ({ name }) => (
  <Div
    bg="#F3B23E"
    p={{ x: "0.5rem", y: "0.5rem" }}
    rounded="0.75rem"
    w={{ xs: "4.5rem", md: "7.5rem", xl: "7.5rem" }}
    style={{ whiteSpace: "nowrap" }}
  >
    <Text
      textSize="heading3"
      textWeight="600"
      textColor="#ffffff"
      textAlign="center"
    >
      {name}
    </Text>
  </Div>
);

const EditCustomerInfo = () => {
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    fetchCustomerInfo();
  }, []);

  const [customerInfo, setCustomerInfo] = useState({});
  //紀錄暫時儲存下來的更新，當按下確定時才送出，否則取消鍵會回到修改前的內容
  const [tempCustomerInfo, setTempCustomerInfo] = useState({});

  const customerInfoApiUrl = `${process.env.REACT_APP_API_URL}/users/register/owner-info/`;

  const fetchCustomerInfo = useCallback(() => {
    const storedAccess = localStorage.getItem("access");

    fetch(customerInfoApiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedAccess}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          // alert("access 過期");
          refreshRequest(fetchCustomerInfo);
          throw new Error("Unauthorized"); //使不執行.then(data)，直接跳到.catch()
        } else {
          return response.json();
        }
      })
      .then((data) => {
        const deepCopyData = JSON.parse(JSON.stringify(data));
        setCustomerInfo(deepCopyData.customer_info);
        setTempCustomerInfo(data.customer_info);

        setPageLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  });

  const [editStatus, setEditStatus] = useState(false);
  const editCustomerClick = () => {
    //取消後再次修改，得更新回原始資料
    fetchCustomerInfo();

    setEditStatus(true);
    // 修改過信箱且驗證成功，但又按下修改鈕
    setEmailVerify(true);
    setVerifyClick(false);
    setVerifyCheck(false);
    setVerifyCode("");
  };

  //customerInfo物件單一對象的更新
  const handleCustomerChange = (field, value) => {
    if (field === "phoneNumber") {
      const phoneRegex = /^(0|\+?[1-9][0-9]{1,4})[0-9]{6,10}$/; // 修改为适合你需求的正则表达式
      if (phoneRegex.test(value)) {
        setIsPhoneValid("correct");
      } else {
        setIsPhoneValid("error");
      }
    } else if (field === "email") {
      setEmailVerify(false);
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(value)) {
        setIsEmailValid("correct");
      } else {
        setIsEmailValid("error");
      }
    }

    //紀錄暫時的內容更新
    setTempCustomerInfo({ ...tempCustomerInfo, [field]: value }); //保留原屬性，更新每個field的值，[屬性名變數]：值

    // console.log("原本 " + JSON.stringify(customerInfo));
    // console.log("變更過 " + JSON.stringify(tempCustomerInfo));
  };

  const getChangedFields = () => {
    const changes = {};
    //tempPetInfo的每一格和originalPetInfo比對改變的欄位和值，紀錄在changes中
    Object.keys(tempCustomerInfo).forEach((key) => {
      if (tempCustomerInfo[key] !== customerInfo[key]) {
        changes[key] = tempCustomerInfo[key];
      }
    });

    return changes; // 過濾掉沒有變更的對象
  };

  const handleCustomerSave = useCallback(() => {
    setEditStatus(false);
    //只送出有變動過的值
    const changedData = getChangedFields();
    // console.log("sendValue " + JSON.stringify(changedData));

    const storedAccess = localStorage.getItem("access");

    fetch(customerInfoApiUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedAccess}`,
      },
      body: JSON.stringify(changedData),
    })
      .then((response) => {
        if (response.status === 401) {
          // alert("access 過期");
          refreshRequest(handleCustomerSave);
        } else {
          return response.json();
        }
      })
      .then((data) => {
        const newAccess = data.access;
        localStorage.setItem("access", newAccess);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  });

  const cancelCustomerClick = useCallback(() => {
    setEditStatus(false);
    //當按下取消鍵時，回到原先內容
    setTempCustomerInfo(customerInfo);
  });

  //手機信箱格式
  const [isPhoneValid, setIsPhoneValid] = useState("default");
  const [isEmailValid, setIsEmailValid] = useState("default");

  //修改信箱>重新驗證
  const [emailVerify, setEmailVerify] = useState(true); //原信箱有無受過驗證
  const [verifyClick, setVerifyClick] = useState(false); //改過信箱後出現驗證鈕
  const [verifyCheck, setVerifyCheck] = useState(); //新信箱有無受過驗證
  const [emailApi, setEmailApi] = useState(false); //接收到api回傳
  const [timeLeft, setTimeLeft] = useState(180);

  useEffect(() => {
    if (emailApi) {
      const intervalId = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime === 1) {
            clearInterval(intervalId);
            setVerifyClick(false);
            return 180;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [emailApi]);

  //寄發驗證信
  const emailSendApiUrl = `${process.env.REACT_APP_API_URL}/users/register/email-verify-sending/`;
  const emailSend = useCallback(() => {
    setVerifyClick(true);

    const storedAccess = localStorage.getItem("access");

    fetch(emailSendApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedAccess}`,
      },
      body: JSON.stringify({
        client_email: tempCustomerInfo.email,
      }),
    })
      .then((response) => {
        setEmailApi(true);
        if (response.status === 401) {
          // alert("access 過期");
          refreshRequest(emailSend);
        } else {
          return response.json();
        }
      })
      // .then((data) => {
      //   console.log(data);
      // })
      .catch((error) => {
        console.error("Error:", error);
      });
  });

  //確認驗證碼
  const [verifyCode, setVerifyCode] = useState("");
  const handleVerifyCode = (value) => {
    // console.log(verifyCode);
    setVerifyCode(value);
  };

  const verifyCodeApiUrl = `${process.env.REACT_APP_API_URL}/users/register/email-verify-checking/`;
  const verifyEmail = () => {
    const storedAccess = localStorage.getItem("access");
    fetch(verifyCodeApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedAccess}`,
      },
      body: JSON.stringify({
        client_email: tempCustomerInfo.email,
        verification_code: verifyCode,
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          // alert("access 過期");
          refreshRequest(verifyEmail);
        } else if (response.status === 400) {
          setVerifyCheck(false);
        } else if (response.status === 200) {
          setEmailVerify(true);
          setVerifyCheck(true);
        } else {
          return response.json();
        }
      })
      // .then((data) => {
      //   console.log(data);
      // })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    if (verifyCode.length === 6) {
      verifyEmail();
    }
  }, [verifyCode]);

  const navigate = useNavigate();
  const refreshApiUrl = `${process.env.REACT_APP_API_URL}/users/login/token-refresh/`;

  const refreshRequest = useCallback((callback) => {
    const storedRefresh = localStorage.getItem("refresh");

    fetch(refreshApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refresh: storedRefresh,
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          // alert("refresh");
          localStorage.removeItem("access");
          localStorage.removeItem("refresh");
          navigate("/login");
        } else if (response.status === 400) {
          navigate("/login");
        } else {
          return response.json();
        }
      })
      .then((data) => {
        const newAccess = data.access;
        localStorage.setItem("access", newAccess);
        if (typeof callback === "function") {
          callback(); // 调用传入的回调函数
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        navigate("/login");
      });
  });

  return (
    <>
      {pageLoading ? (
        <Div d="flex" justify="center" align="center" w="100%" h="100vh">
          <Div d="flex" justify="center" align="center" className="loader" />
        </Div>
      ) : (
        <Div d="flex" justify="center" align="center">
          <Div
            d="flex"
            flexDir="column"
            justify="center"
            align={editStatus ? "flex-end" : "center"}
          >
            <Div
              w={{ xs: "22rem", md: "27rem", lg: "32rem" }}
              rounded="12px"
              bg="#FFFFFF"
              border={editStatus ? "3px solid" : "none"}
              borderColor={editStatus ? "neutral600" : "none"}
              shadow="3"
              p={{
                x: { xs: "1.5rem", md: "2rem", lg: "2.5rem" },
                y: { xs: "1.5rem", md: "1.6rem", lg: "1.7rem" },
              }}
            >
              <Div d="flex" justify="center" align="center" flexDir="column">
                <Text textSize="heading2" textWeight="600">
                  修改飼主資訊
                </Text>
                <Divider style={{ margin: "16px" }} />
              </Div>

              <Div d="flex" flexDir="row" justify="start" align="center">
                <FieldName name="姓名" />

                <Div w="100%">
                  {editStatus ? (
                    <Div
                      d="flex"
                      flexDir={{ xs: "column", md: "row" }}
                      m={{ l: "1.3rem" }}
                    >
                      <Div>
                        <InputField
                          type="text"
                          name="customerName" //name屬性標示輸入字段
                          value={tempCustomerInfo.customer_name}
                          onChange={(e) =>
                            handleCustomerChange(
                              "customer_name",
                              e.target.value
                            )
                          }
                          maxLength={10}
                        />
                      </Div>
                    </Div>
                  ) : (
                    <Div m={{ l: "2rem" }}>
                      <Text textSize="heading3" textColor="neutral600">
                        {tempCustomerInfo.customer_name}
                      </Text>
                    </Div>
                  )}
                </Div>
              </Div>

              <Div
                d="flex"
                flexDir="row"
                justify="start"
                align="center"
                m={{ t: "1rem" }}
              >
                <FieldName name="性別" />

                <Div w="100%">
                  {editStatus ? (
                    <Div
                      d="flex"
                      flexDir={{ xs: "column", md: "row" }}
                      m={{ l: "1.3rem" }}
                    >
                      <Div>
                        <ConfigProvider
                          theme={{
                            token: {
                              colorPrimary: "#F3B23E",
                              fontSize: 16,
                              controlInteractiveSize: 24,
                            },
                          }}
                        >
                          <Radio.Group
                            onChange={(e) =>
                              handleCustomerChange("gender", e.target.value)
                            }
                            name="gender"
                            value={tempCustomerInfo.gender}
                          >
                            <Radio value="M">生理男</Radio>
                            <Radio value="F">生理女</Radio>
                          </Radio.Group>
                        </ConfigProvider>
                      </Div>
                    </Div>
                  ) : (
                    <Div m={{ l: "2rem" }}>
                      <Text textSize="heading3" textColor="neutral600">
                        {tempCustomerInfo.gender === "M" ? "生理男" : "生理女"}
                      </Text>
                    </Div>
                  )}
                </Div>
              </Div>

              <Div
                d="flex"
                flexDir="row"
                justify="start"
                align="center"
                m={{ t: "1rem" }}
              >
                <FieldName name="手機" />

                <Div w="100%">
                  {editStatus ? (
                    <Div
                      d="flex"
                      flexDir={{ xs: "column", md: "row" }}
                      m={{ l: "1.3rem" }}
                    >
                      <Div>
                        <InputField
                          type="text"
                          name="phoneNumber" //name屬性標示輸入字段
                          value={tempCustomerInfo.phone_number}
                          onChange={(e) =>
                            handleCustomerChange("phone_number", e.target.value)
                          }
                          maxLength={15}
                        />
                        {isPhoneValid === "error" && (
                          <Div d="flex" align="center" m={{ t: "0.25rem" }}>
                            <Icon
                              name="AlertSolid"
                              size="14px"
                              color="#555555"
                              m={{ r: "0.25rem" }}
                            />
                            <Text
                              textSize="paragraph"
                              textColor="#555555"
                              textWeight="600"
                            >
                              格式錯誤
                            </Text>
                          </Div>
                        )}
                      </Div>
                    </Div>
                  ) : (
                    <Div m={{ l: "2rem" }}>
                      <Text textSize="heading3" textColor="neutral600">
                        {tempCustomerInfo.phone_number}
                      </Text>
                    </Div>
                  )}
                </Div>
              </Div>

              <Div
                d="flex"
                flexDir="row"
                justify="start"
                align={editStatus ? "start" : "center"}
                m={{ t: "1rem" }}
              >
                <FieldName name="信箱" />

                <Div w="100%">
                  {editStatus ? (
                    <Div
                      d="flex"
                      flexDir={{ xs: "column", md: "row" }}
                      m={{ l: "1.3rem" }}
                    >
                      <Div w="100%">
                        <InputField
                          type="email"
                          name="email"
                          value={tempCustomerInfo.email}
                          disabled={verifyCheck}
                          onChange={(e) =>
                            handleCustomerChange("email", e.target.value)
                          }
                        />
                        {emailVerify ? (
                          <Div d="flex" align="center" m={{ t: "0.25rem" }}>
                            <Icon
                              name="Checked"
                              size="14px"
                              color="#0BAA60"
                              m={{ r: "0.25rem" }}
                            />
                            <Text
                              textSize="paragraph"
                              textColor="#0BAA60"
                              textWeight="600"
                            >
                              信箱驗證完成
                            </Text>
                          </Div>
                        ) : verifyClick ? (
                          <Div d="flex" align="center" m={{ t: "0.25rem" }}>
                            <Icon
                              name="AlertSolid"
                              size="14px"
                              color="#555555"
                              m={{ r: "0.25rem" }}
                            />
                            <Text
                              textSize="paragraph"
                              textColor="#555555"
                              textWeight="600"
                            >
                              請至信箱收取驗證碼
                            </Text>
                          </Div>
                        ) : (
                          <Div d="flex" align="center" m={{ t: "0.25rem" }}>
                            <Icon
                              name="AlertSolid"
                              size="14px"
                              color="#555555"
                              m={{ r: "0.25rem" }}
                            />
                            <Text
                              textSize="paragraph"
                              textColor="#555555"
                              textWeight="600"
                            >
                              尚未驗證
                            </Text>
                          </Div>
                        )}

                        {isEmailValid === "error" && (
                          <Div d="flex" align="center" m={{ t: "0.25rem" }}>
                            <Icon
                              name="AlertSolid"
                              size="14px"
                              color="#555555"
                              m={{ r: "0.25rem" }}
                            />
                            <Text
                              textSize="paragraph"
                              textColor="#555555"
                              textWeight="600"
                            >
                              格式錯誤
                            </Text>
                          </Div>
                        )}
                      </Div>
                    </Div>
                  ) : (
                    <Div m={{ l: "2rem" }} overflow="auto">
                      <Text textSize="heading3" textColor="neutral600">
                        {tempCustomerInfo.email}
                      </Text>
                    </Div>
                  )}
                </Div>
              </Div>

              {/* 如果修改過信箱需要重新驗證 */}
              {emailVerify === false && (
                <>
                  {/* 驗證鈕 */}
                  <Div
                    m={{
                      l: { xs: "0rem", md: "1.5rem" },
                      t: { xs: "1.5rem", md: "0rem" },
                    }}
                    d="flex"
                    justify="flex-end"
                    align="center"
                  >
                    {emailApi && (
                      <Div m={{ r: "1.5rem" }}>
                        <Text
                          textColor={verifyClick ? "neutral800" : "#ffffff"}
                          textSize="body1"
                          textWeight="400"
                        >
                          {timeLeft}S
                        </Text>
                      </Div>
                    )}

                    <Div w="120px">
                      <Btn
                        text={verifyClick === true ? "傳送中" : "驗證"}
                        onClick={() => emailSend()}
                        disabled={
                          verifyClick === true ||
                          verifyCheck === true ||
                          isEmailValid === "error"
                        }
                      />
                    </Div>
                  </Div>

                  {verifyClick && (
                    <>
                      {/* 驗證碼 */}
                      <Div
                        d="flex"
                        flexDir="row"
                        justify="start"
                        align={editStatus ? "start" : "center"}
                        m={{ t: "1rem" }}
                      >
                        <FieldName name="驗證碼" />

                        <Div
                          w="100%"
                          d="flex"
                          flexDir={{ xs: "column", md: "row" }}
                          m={{ l: "1.3rem" }}
                        >
                          <Div>
                            <InputField
                              type="text"
                              name="verify" //name屬性標示輸入字段
                              value={verifyCode}
                              disabled={emailVerify}
                              onChange={(e) => handleVerifyCode(e.target.value)}
                            />
                            {verifyCheck === false && (
                              <Div d="flex" align="center" m={{ t: "0.25rem" }}>
                                <Icon
                                  name="AlertSolid"
                                  size="14px"
                                  color="#555555"
                                  m={{ r: "0.25rem" }}
                                />
                                <Text
                                  textSize="paragraph"
                                  textColor="#555555"
                                  textWeight="600"
                                >
                                  驗證碼錯誤
                                </Text>
                              </Div>
                            )}
                          </Div>
                        </Div>
                      </Div>
                    </>
                  )}
                </>
              )}
            </Div>

            {editStatus ? (
              <Div
                m={{ t: { xs: "1rem", lg: "2rem" } }}
                d="flex"
                justify="flex-end"
                align="center"
              >
                <Div w="108px">
                  <Button
                    w="100%"
                    rounded="lg"
                    textSize="14px"
                    textColor="#79384A"
                    bg="#ffffff"
                    d="flex"
                    justify="center"
                    align="center"
                    textWeight="600"
                    border="2px solid"
                    borderColor="#79384A"
                    hoverTextColor="#79384A"
                    hoverBg="#FFFFFF"
                    hoverBorderColor="#79384A"
                    onClick={() => cancelCustomerClick()}
                  >
                    取消
                  </Button>
                </Div>
                <Div m={{ l: "0.75rem" }} w="108px">
                  <Btn text="確定" onClick={() => handleCustomerSave()} />
                </Div>
              </Div>
            ) : (
              <Div
                w="232px"
                m={{ t: { xs: "1rem", lg: "2rem" } }}
                d="flex"
                justify="center"
                align="center"
              >
                <Btn text="修改" onClick={() => editCustomerClick()} />
              </Div>
            )}
          </Div>
        </Div>
      )}
    </>
  );
};

export default EditCustomerInfo;
