import { Button, Icon } from "atomize";
import React, { useState } from "react";

const BtnWithIcon = ({ text, iconName, hoverIconName, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <>
      <Button
        rounded="lg"
        textSize="14px"
        textColor="#FFFFFF"
        bg="#79384A"
        d="flex"
        justify="center"
        align="center"
        textWeight="600"
        border="1px solid"
        borderColor="#79384A"
        hoverTextColor="#79384A"
        hoverBg="#FFFFFF"
        hoverBorderColor="#79384A"
        p={{ x: { xs: "2.5rem", lg: "4.5rem" }, y: "1.25rem" }}
        shadow="4"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={onClick}
      >
        {isHovered ? hoverIconName : iconName}&ensp;{text}
      </Button>
    </>
  );
};

export default BtnWithIcon;
