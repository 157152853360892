import { Div, Text } from "atomize";
import React from "react";

const stepBorder = (color) => ({
  borderTop: "4px solid",
  borderTopColor: color,
});

const Step = ({ step, text, borderColor }) => {
  return (
    <>
      <Div m="0.62rem" w="150px">
        <Div style={stepBorder(borderColor)}>
          <Text textSize="heading3" textWeight="600" textColor="#576275">
            {step}
          </Text>
          <Text textSize="body1" textWeight="400" textColor="#576275">
            {text}
          </Text>
        </Div>
      </Div>
    </>
  );
};

export default Step;
