import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useDocumentTitle from "./components/useDocumentTitle";
import NavBar from "./components/NavBar";
import {
  Button,
  Div,
  Icon,
  Image,
  Modal,
  Notification,
  Tag,
  Text,
} from "atomize";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation, HashNavigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import background from "./assets/images/report_easy/background.png";
import { ReactComponent as BannerBtnRight } from "./assets/icon/banner_btn_right.svg";
import { ReactComponent as BannerBtnLeft } from "./assets/icon/banner_btn_left.svg";
import Swipe from "./assets/images/report_easy/swipe_gif.gif";
import cardBg from "./assets/images/report_easy/card_bg.png";
import cardPage1 from "./assets/images/report_easy/card_page1.png";
import cardPage2 from "./assets/images/report_easy/card_page2.png";
import cardBgGood from "./assets/images/report_easy/card_bg_goodbac.png";
import cardBgBad from "./assets/images/report_easy/card_bg_badbac.png";
import cardBgNeutral from "./assets/images/report_easy/card_bg_neutral.png";
import GoodBacBg from "./assets/images/report_easy/goodbac_bg.png";
import BadBacBg from "./assets/images/report_easy/badbac_bg.png";
import NeutralBacBg from "./assets/images/report_easy/neutralbac_bg.png";
import GoodBacAngel from "./assets/images/report_easy/goodbac_angel.png";
import BadBacDemon from "./assets/images/report_easy/badbac_demon.png";
import NeutralBacRole from "./assets/images/report_easy/neutralbac_role.png";
import cardPage4 from "./assets/images/report_easy/card_page4.png";
import cardPage5 from "./assets/images/report_easy/card_page5.gif";
import cardBgRatio from "./assets/images/report_easy/card_bg_ratio.png";
import RatioHigh from "./assets/images/report_easy/ratio_high.png";
import RatioMedium from "./assets/images/report_easy/ratio_medium.png";
import RatioLow from "./assets/images/report_easy/ratio_low.png";
import Forward from "./assets/images/report_easy/forward.png";
import ResultHigh from "./assets/images/report_easy/ratio_result_high.gif";
import ResultMedium from "./assets/images/report_easy/ratio_result_medium.gif";
import ResultLow from "./assets/images/report_easy/ratio_result_low.gif";
import CardPage9 from "./assets/images/report_easy/card_page9.png";
import CardPage10 from "./assets/images/report_easy/card_page10.png";
import Warning from "./assets/images/report_easy/warning.png";
import CardPage15Top from "./assets/images/report_easy/card_page15_top.png";
import CardPage15Bottom from "./assets/images/report_easy/card_page15_bottom.png";
import CardPage16 from "./assets/images/report_easy/card_page16.png";
import DiseaseHigh from "./assets/images/report_easy/disease_high.png";
import DiseaseMedium from "./assets/images/report_easy/disease_medium.png";
import DiseaseLow from "./assets/images/report_easy/disease_low.png";
import CardPage19 from "./assets/images/report_easy/card_page19.png";
import CardPage19Btm from "./assets/images/report_easy/card_page19_bottom.png";
import CardPage20 from "./assets/images/report_easy/card_page20.png";
import BtnWithIcon from "./components/BtnWithIcon";
import { ReactComponent as PetsWhite } from "./assets/icon/pets_white.svg";
import { ReactComponent as PetsPurple } from "./assets/icon/pets_purple.svg";
import ToBeUnlocked from "./assets/images/report_easy/tobeunlocked.png";
import Btn from "./components/Btn";
import UnlockedTable from "./assets/images/report_easy/unclockedTable.png";
import { ReactComponent as Line } from "./assets/icon/line_gray.svg";
import { ReactComponent as Copy } from "./assets/icon/copy.svg";

// 整頁背景、當前頁數、卡片內容
const CardSlide = ({ cardBackground, pageNum, totalPage, content }) => (
  <Div d="flex" justify="center" align="start">
    {/* 卡片本身 */}
    <Div
      w="100%"
      h="99%"
      border="2px solid"
      borderColor="#242424"
      rounded="24px"
      bgImg={cardBackground}
      bgSize="cover"
      bgPos="center"
      overflow="hidden"
      pos="absolute"
      zIndex="1"
    >
      <Div
        d="flex"
        justify="center"
        align="center"
        p={{ t: "1rem", b: "1rem" }}
        bg="#ffffff"
        rounded={{
          t: "xl",
        }}
        style={{
          borderBottom: "1px dashed",
          borderColor: "#A6A4A1",
        }}
      >
        <Text textSize="reportHeading4" textColor="#555555">
          當前頁數 {pageNum}/{totalPage}
        </Text>
      </Div>

      {content}
    </Div>
  </Div>
);

// 好菌&壞菌&中立菌卡片中的內容
const BacIntro = ({
  title,
  bacType,
  roleImage,
  chineseName,
  engName,
  job,
  feature,
}) => (
  <Div p={{ x: "1.7rem", y: "1rem" }}>
    <Text
      textSize="reportHeading1"
      textWeight="600"
      textColor={bacType === "bad" ? "#ffffff" : "#242424"}
    >
      {title}
    </Text>

    <Div p={{ t: "0.5rem" }} h="308px" id="charactor">
      {/* 角色方框 */}
      <Div
        w="100%"
        h="100%"
        bgImg={
          bacType === "good"
            ? GoodBacBg
            : bacType === "bad"
            ? BadBacBg
            : NeutralBacBg
        }
        bgSize="contain"
        bgRepeat="no-repeat"
        bgPos="center"
        pos="relative"
      >
        {/* 右上角角色 */}
        <Div pos="absolute" right="-4px" zIndex="1">
          <Div>
            <Image
              w="75px"
              src={
                bacType === "good"
                  ? GoodBacAngel
                  : bacType === "bad"
                  ? BadBacDemon
                  : NeutralBacRole
              }
            />
          </Div>
        </Div>

        {/* 角色 */}
        <Div pos="absolute" bottom="50px" zIndex="2">
          <Div d="flex" justify="center" align="center" overflow="hidden">
            <Image w="60%" src={roleImage} />
          </Div>
        </Div>
      </Div>

      {/* 說明文字 */}
      <Div m={{ t: "0.5rem" }}>
        {/* 菌名 */}
        <Div d="flex" flexDir="row" justify="start" align="center">
          <Tag
            bg={bacType === "bad" ? "#ffffff" : "#555555"}
            textColor={bacType === "bad" ? "#555555" : "#ffffff"}
            textSize="reportHeading3"
            textWeight="600"
            p={{ x: "0.625rem", y: "0.25rem" }}
            m={{ r: "0.75rem" }}
          >
            菌名
          </Tag>
          <Div d="flex" flexDir="row">
            <Text
              tag="span"
              textColor={bacType === "bad" ? "#ffffff" : "#555555"}
              textSize="reportHeading2"
              textWeight="600"
            >
              {chineseName}
              <Text
                textColor={bacType === "bad" ? "#ffffff" : "#555555"}
                textSize="12px"
                textWeight="400"
                // p={{ l: "0.5rem" }}
                style={{ fontStyle: "italic" }}
              >
                {engName}
              </Text>
            </Text>
          </Div>
        </Div>
        {/* 職業 */}
        <Div
          d="flex"
          flexDir="row"
          justify="start"
          align="center"
          m={{ t: "0.75rem" }}
        >
          <Tag
            bg={bacType === "bad" ? "#ffffff" : "#555555"}
            textColor={bacType === "bad" ? "#555555" : "#ffffff"}
            textSize="reportHeading3"
            textWeight="600"
            p={{ x: "0.625rem", y: "0.25rem" }}
            m={{ r: "0.75rem" }}
          >
            職業
          </Tag>
          <Text
            textColor={bacType === "bad" ? "#ffffff" : "#555555"}
            textSize="reportHeading2"
            textWeight="600"
          >
            {job}
          </Text>
        </Div>
        {/* 特色 */}
        <Div
          d="flex"
          flexDir="row"
          justify="start"
          align="start"
          m={{ t: "0.75rem" }}
          flexWrap="nowrap"
        >
          <Div d="flex">
            <Tag
              bg={bacType === "bad" ? "#ffffff" : "#555555"}
              textColor={bacType === "bad" ? "#555555" : "#ffffff"}
              textSize="reportHeading3"
              textWeight="600"
              p={{ x: "0.625rem", y: "0.25rem" }}
              m={{ r: "0.75rem" }}
            >
              特色
            </Tag>
          </Div>
          <Div flexGrow={1} overflow="hidden">
            <Text
              textColor={bacType === "bad" ? "#ffffff" : "#555555"}
              textSize="reportHeading2"
              textWeight="600"
              flexWrap="wrap"
            >
              {feature}
            </Text>
          </Div>
        </Div>
      </Div>
    </Div>
  </Div>
);

// 依據菌種、比例數值對應要顯示的圖片、文字、建議
const getRatioShow = (type, forwardPosition) => {
  //益生菌
  if (type === "good") {
    if (forwardPosition > 75)
      return {
        imageSrc: RatioHigh,
        color: "#69BDA9",
        text: "非常優秀",
        advise: "建議保持目前飼養方式，以維持腸道健康。",
        resultImageSrc: ResultHigh,
      };
    else if (forwardPosition >= 25 && forwardPosition <= 75)
      return {
        imageSrc: RatioMedium,
        color: "#FFC842",
        text: "還可以",
        advise: "建議於日常飲食中定期添加益生菌，以維持腸道健康。",
        resultImageSrc: ResultMedium,
      };
    else
      return {
        imageSrc: RatioLow,
        color: "#FF9696",
        text: "待加強",
        advise:
          "建議密切注意腸道狀況並於日常飲食中定期添加益生菌，以改善腸道健康。",
        resultImageSrc: ResultLow,
      };
  }
  //致病菌
  else {
    if (forwardPosition > 75)
      return {
        imageSrc: RatioLow,
        color: "#FF9696",
        text: "高風險",
        advise:
          "需密切留意是否出現腹瀉等症狀，若有嚴重腹瀉，應立即尋求獸醫的專業診斷。",
        resultImageSrc: ResultLow,
      };
    else if (forwardPosition >= 25 && forwardPosition <= 75)
      return {
        imageSrc: RatioMedium,
        color: "#FFC842",
        text: "還可以",
        advise:
          "建議於日常飲食中定期添加益生菌來改善腸道環境，抑制致病菌生長。",
        resultImageSrc: ResultMedium,
      };
    else
      return {
        imageSrc: RatioHigh,
        color: "#69BDA9",
        text: "低風險",
        advise: "建議保持目前飼養方式，以維持腸道健康。",
        resultImageSrc: ResultHigh,
      };
  }
};

// 比例頁內容
const RatioPage = ({ name, title, type, forwardPosition }) => {
  //回傳菌種、比例數值給該函式，取得圖片、顏色、文字、建議顯示
  const { imageSrc, color, text, advise } = getRatioShow(type, forwardPosition);
  return (
    <Div
      h="100%"
      d="flex"
      flexDir="column"
      align="center"
      rounded={{
        b: "xl",
      }}
      m={{ t: "1.5rem", x: "1rem" }}
    >
      <Div>
        <Text textSize="reportHeading1" textWeight="600" textColor="#242424">
          腸道{title}比例
        </Text>
      </Div>

      {/* bar圖-低中高 */}
      <Div
        h="20px"
        w="100%"
        rounded="xl"
        m={{ t: "1rem" }}
        d="flex"
        flexDir="row"
      >
        <Div w="25%" textAlign="center">
          <Text textSize="reportHeading2" textColor="#555555" textWeight="600">
            低
          </Text>
        </Div>
        <Div w="50%" textAlign="center">
          <Text textSize="reportHeading2" textColor="#555555" textWeight="600">
            中
          </Text>
        </Div>
        <Div w="25%" textAlign="center">
          <Text textSize="reportHeading2" textColor="#555555" textWeight="600">
            高
          </Text>
        </Div>
      </Div>
      {/* bar圖-顏色 */}
      <Div
        border="2px solid"
        borderColor="#555555"
        h="20px"
        w="100%"
        rounded="xl"
        m={{ t: "1rem" }}
        d="flex"
        flexDir="row"
      >
        {/* 益生菌or致病菌的bar顏色不一樣 */}
        {type === "good" ? (
          <>
            <Div w="25%" h="17px" bg="#FF9696" rounded={{ l: "xl" }} />
            <Div w="50%" h="17px" bg="#FEDD8D" />
            <Div w="25%" h="17px" bg="#69BDA9" rounded={{ r: "xl" }} />
          </>
        ) : (
          <>
            <Div w="25%" h="17px" bg="#69BDA9" rounded={{ l: "xl" }} />
            <Div w="50%" h="17px" bg="#FEDD8D" />
            <Div w="25%" h="17px" bg="#FF9696" rounded={{ r: "xl" }} />
          </>
        )}
      </Div>

      {/* bar圖-箭頭 */}
      <Div w="100%" m={{ t: "-8px" }}>
        <Div
          w={`${
            forwardPosition === 0 ? forwardPosition + 6 : forwardPosition + 5
          }%`}
          d="flex"
          justify="end"
        >
          <Image w="35px" src={Forward} />
        </Div>
      </Div>

      {/* 圖 & 印章 */}
      <Div
        d="flex"
        flexDir="column"
        justify="center"
        align="center"
        m={{ t: "2rem" }}
      >
        <Text
          textSize="reportHeading2"
          textColor="#242424"
          textWeight="600"
          textAlign="center"
        >
          『{name}』腸道中的{title}比例被評定為...
        </Text>
        <Div m={{ t: "1rem" }}>
          <Image src={imageSrc} w="165px" />
        </Div>
        <Div
          border="5px solid"
          borderColor={color}
          m={{ t: "1rem" }}
          p={{ x: "1rem", y: "0.625rem" }}
          rounded="lg"
        >
          <Text
            textSize="24px"
            textColor={color}
            textWeight="600"
            textAlign="center"
          >
            {text}
          </Text>
        </Div>
      </Div>

      {/* 建議 */}
      <Div m={{ t: "2rem" }}>
        <Text textSize="reportBody2" textColor="#595959">
          💡
          {advise}
        </Text>
      </Div>
    </Div>
  );
};

const RatioResultPage = ({ name, type, forwardPosition }) => {
  const { resultImageSrc } = getRatioShow(type, forwardPosition);
  return (
    <Div
      h="100%"
      d="flex"
      flexDir="column"
      align="center"
      rounded={{
        b: "xl",
      }}
      m={{ t: "8rem", x: "2.5rem" }}
    >
      <Text
        textSize="reportHeading2"
        textColor="#242424"
        textWeight="600"
        textAlign="center"
      >
        根據檢測結果，『{name}』腸道菌中最多的
        {type == "good" ? "益生菌" : "致病菌"}是...
      </Text>
      <Div m={{ t: "2.25rem" }}>
        <Image src={resultImageSrc} w="225px" />
      </Div>
    </Div>
  );
};

const Disease = ({ name, pet }) => (
  <Div
    h="100%"
    d="flex"
    flexDir="column"
    align="center"
    rounded={{
      b: "xl",
    }}
    m={{ t: "4.3rem", x: "3rem" }}
  >
    <Div>
      <Text
        tag="span"
        textSize="reportHeading2"
        textColor="#555555"
        textWeight="600"
      >
        住在腸胃道中的致病菌，常常也會帶來毛孩的
        <Text
          tag="span"
          textSize="reportHeading2"
          textColor="#EB4C27"
          textWeight="600"
        >
          疾病與身體不適
        </Text>
        。
      </Text>

      <Div m={{ t: "0.75rem" }}>
        <Image w="135px" src={CardPage15Top} />
      </Div>
    </Div>

    <Div m={{ t: "3.3rem" }}>
      <Text
        tag="span"
        textSize="reportHeading2"
        textColor="#555555"
        textWeight="600"
      >
        根據檢測結果，我們可以知道 『{name}』 罹患
        <Text
          tag="span"
          textSize="reportHeading2"
          textColor="#EB4C27"
          textWeight="600"
        >
          以下常見疾病的風險程度
        </Text>
        。
      </Text>

      <Div textAlign="end" m={{ t: "0.75rem" }}>
        <Image w="90px" src={CardPage15Bottom} />
      </Div>
    </Div>
  </Div>
);

const getDiseaseShow = (pet) => {
  if (pet === "cat") {
    return [
      {
        chineseName: "炎症性腸炎",
        engName: "Inflammatory bowel disease(IBD)",
        context:
          "是貓咪常見的一種慢性腸道疾病，常見症狀包括持續或間歇性腹瀉、嘔吐、食慾減退和體重下降。",
      },
    ];
  } else if (pet === "dog") {
    return [
      {
        chineseName: "胃扭轉",
        engName: "Gastric Dilatation-Volvulus(GDV)",
        context:
          "是一種嚴重的消化系統疾病，死亡率極高，其症狀為進食後兩到三個小時內出現腹腔膨大、乾嘔、流口水、喘氣等。",
      },
      {
        chineseName: "慢性腸病",
        engName: "Chronic Enteropathy(CE)",
        context:
          "是犬隻常見的一種慢性腸道疾病，症狀包含反覆的腹瀉及嘔吐，伴隨體重減輕。",
      },
    ];
  }
};

const DiseaseResult = ({ name, pet, title, result, diseaseIndex }) => {
  const diseaseInfo = getDiseaseShow(pet);
  //呼叫getDiseaseShow，會回傳一個陣列，貓不須陣列
  const { chineseName, engName, context } = diseaseInfo[diseaseIndex];

  return (
    <Div
      d="flex"
      flexDir="column"
      justify="center"
      align="center"
      p={{ x: "1rem", y: "1.5rem" }}
    >
      <Text textSize="reportHeading1" textColor="#242424" textWeight="600">
        {title}
      </Text>
      <Div
        m={{ t: "2rem" }}
        pos="relative"
        d="flex"
        flexDir="column"
        justify="center"
        align="end"
        w="100%"
      >
        <Div pos="absolute" top="-20px">
          <Image w="45px" src={CardPage16} />
        </Div>
        <Div
          w="100%"
          p={{ y: "0.5rem", x: "1.5rem" }}
          border="2px solid"
          borderColor="#242424"
          bg="#D1EFFF"
          textAlign="center"
          rounded="16px"
        >
          <Text textSize="heading3" textColor="#242424" textWeight="600">
            {chineseName}
          </Text>
          <Text textSize="body1" textColor="#242424" textWeight="400">
            {engName}
          </Text>
        </Div>
      </Div>
      <Text
        textSize="body1"
        textColor="#555555"
        textWeight="400"
        m={{ t: "0.75rem", b: "0rem" }}
      >
        {context}
      </Text>

      <Div
        m={{ t: "2rem" }}
        d="flex"
        flexDir="column"
        justify="center"
        align="center"
      >
        <Text
          textSize="reportHeading2"
          textColor="#242424"
          textWeight="600"
          m={{ x: "3rem" }}
          textAlign="center"
        >
          『{name}』患有{chineseName} 的風險為...
        </Text>
        <Image
          src={
            result > 75
              ? DiseaseHigh
              : result >= 25 && result <= 75
              ? DiseaseMedium
              : DiseaseLow
          }
          w="150px"
          m={{ t: "0.75rem" }}
        />
        <Text
          textSize="heading1"
          textColor={
            result > 75
              ? "#FF9696"
              : result >= 25 && result <= 75
              ? "#FFC842"
              : "#69BDA9"
          }
          textWeight="600"
          m={{ t: "1rem" }}
        >
          {result > 75
            ? "高風險"
            : result >= 25 && result <= 75
            ? "中風險"
            : "低風險"}
        </Text>
      </Div>
    </Div>
  );
};

const ReportsEasyV2 = () => {
  useDocumentTitle("報告結果 - PetSci");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1280);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1280);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  //給予初始值，避免出現未定義的值
  const [report, setReport] = useState({
    pet_name: "",
    pet_image_url: "",
    pet_species: "",
    pet_birthday: "",
    pet_sex: "female",
    dominant_taxon: {
      type: "good",
      bac_img_src: "xxxx",
      bac_name_ch: "xx菌",
      bac_name_en: "sdvsdv",
      occupation: "心血管醫生",
      brief:
        "與身體脂肪呈負相關，毛孩體內此菌越多，內臟脂肪相對較少，越不容易發生心血管疾病。",
    },
    probiotic_pathogen_scores: {
      probiotics: 25,
      pathogens: 25,
    },
    dominant_probiotic: {
      bac_name_ch: "xx菌",
      bac_name_en: "sdvsdv",
      occupation: "sdvvsd",
      brief: "dfbfdbf",
      bac_img_src: "xxxx",
    },
    dominant_pathogen: {
      bac_img_src: "xxxx",
      bac_name_ch: "xx菌",
      bac_name_en: "sdvsdv",
      occupation: "sdvvsd",
      brief: "dfbfdbf",
    },
    diseases: {
      gdv_risk: {
        pr_score: 25,
      },
      ibd_risk: {
        pr_score: 25,
      },
      ce_risk: {
        pr_score: 25,
      },
    },
  });

  //計算歲數
  function getAge(birth) {
    const birthDate = new Date(birth);
    const currentDate = new Date();

    let years = currentDate.getFullYear() - birthDate.getFullYear();
    let months = currentDate.getMonth() - birthDate.getMonth();

    // 如果當前月份小於生日的月份，則年齡 -1，月份加 12
    if (months < 0) {
      years--;
      months += 12;
    }

    // return `${years}歲${months}個月`;
    return `${years}歲`;
  }

  //若api回傳非200/402就給錯誤訊息
  const [showErrorNotification, setShowErrorNotification] = useState(false);

  const { reportSha } = useParams();
  const easyReadApiUrl = `${process.env.REACT_APP_API_URL}/users/report/easy-read-report/${reportSha}`;
  const fetchEasyReport = () => {
    fetch(easyReadApiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 404) {
          navigate(`/*`);
        } else if (response.status === 500) {
          setShowErrorNotification(true);
        } else {
          return response.json();
        }
      })
      .then((data) => {
        // console.log("reportData ", data.easy_report_results);
        const reportData = data.easy_report_results;
        setReport(reportData);
        //品種
        if (reportData.pet_species === "D") {
          setPet("dog");
        } else {
          setPet("cat");
        }
        //名字
        setName(reportData.pet_name);
        //性別
        if (reportData.pet_sex === "F") {
          setSex("母");
        } else {
          setSex("公");
        }
        //年齡
        setAge(getAge(reportData.pet_birthday));
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const navigate = useNavigate();

  // console.log("report " + JSON.stringify(report));

  useEffect(() => {
    fetchEasyReport();
  }, []);

  //console.log("report " + JSON.stringify(report));

  const [pet, setPet] = useState("cat");
  const [sex, setSex] = useState("母");
  const [name, setName] = useState("55");
  const [age, setAge] = useState("");

  //依據狗還是貓判斷總共有幾頁
  const [totalPage, setTotalPage] = useState(19);
  useEffect(() => {
    if (pet === "dog") {
      setTotalPage(20);
    }
  }, [pet]);

  const [page, setPage] = useState(1);
  //利用onSlideChange監測並改變當前頁數的顯示，並把page代入<CardSlide />
  const handleSlideChange = (swiper) => {
    setPage(swiper.activeIndex + 1);
  };
  //監測page的變化，只要在page1時就會有灰色遮罩
  const [showOverlay, setShowOverlay] = useState(true);
  useEffect(() => {
    if (page === 1) {
      const timer = setTimeout(() => {
        setShowOverlay(false);
      }, 6000);

      return () => clearTimeout(timer);
    }
  }, [page]);

  //最後一頁要前往詳細版時會顯示購買提示
  const [lockedTimer, setLockedTimer] = useState();
  useEffect(() => {
    if (totalPage === page && lockedTimer === true) {
      const timer = setTimeout(() => {
        setLockedTimer(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [page, lockedTimer]);

  // 點按專業版按鈕時就打api，若是200可以前往該頁，若是402要跳提示購買訊息
  const [unpaidPage, setUnpaidPage] = useState(false);

  const showDetailReport = (reportSha) => {
    const detailApiUrl = `${process.env.REACT_APP_API_URL}/users/report/premium-report/${reportSha}`;

    const fetchDetailReport = () => {
      fetch(detailApiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            navigate(`/reports/detail/${reportSha}`);
          } else if (response.status === 402) {
            setUnpaidPage(true);
            setLockedTimer(true);
          } else if (response.status === 404) {
            navigate(`/*`);
          } else if (response.status === 500) {
            setShowErrorNotification(true);
          } else {
            return response.json();
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    fetchDetailReport();
  };

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const onModalOpen = () => {
    setIsOpen(true);
  };
  const onModalClose = () => setIsOpen(false);

  //分享區域
  const [copied, setCopied] = useState(false);
  const copyUrl = () => {
    const url = window.location.href; // 獲取當前網址
    navigator.clipboard
      .writeText(url) // 將網址寫入剪貼簿
      .then(() => {
        setCopied(true); // 設置提示信息為「已複製」
        setTimeout(() => setCopied(false), 2000); // 兩秒後清除提示信息
      })
      .catch((err) => {
        console.error("複製失敗: ", err);
      });
  };

  const shareToLine = () => {
    const url = window.location.href;
    const text =
      "快來看牠的腸道菌項檢測結果，我發現好多酷酷的菌，快到科學毛怪一同檢測一波!";

    const lineShareUrl = `line://msg/text/${encodeURIComponent(
      text
    )}%20${encodeURIComponent(url)}`;
    window.location.href = lineShareUrl;
  };

  return (
    <>
      {isLoading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <NavBar />

          <Div
            h="100%"
            overflow="hidden"
            m={{ t: "65px" }}
            //禁止被複製
            style={{
              userSelect: "none",
              WebkitUserSelect: "none",
              MozUserSelect: "none",
              msUserSelect: "none",
            }}
          >
            <Div
              d="flex"
              flexDir="column"
              justify="start"
              align="center"
              p={{
                t: "1.5rem",
                b: "5rem",
                x: "1rem",
              }}
              h="115vh"
              bgImg={background}
              bgSize="cover"
              bgPos="center"
              overflow="hidden"
            >
              {/* 標題 */}
              <Div
                m={{ b: "1rem" }}
                p={{ x: "1.5rem", y: "0.5rem" }}
                bg="#ffffff"
                border="2px solid"
                borderColor="#242424"
                rounded="circle"
              >
                <Text
                  textSize=" reportHeading2"
                  textWeight="600"
                  textColor="#242424"
                >
                  🔎 探索毛孩腸道世界 🔍
                </Text>
              </Div>

              <Div
                id="card-section"
                d="flex"
                justify="center"
                align="start"
                w="100%"
              >
                {/* 左右鍵icon */}
                <Div
                  ref={prevRef}
                  pos="absolute"
                  transform="translateY(-50%)"
                  top="55%"
                  left="30%"
                  d={isMobile ? "none" : "flex"}
                >
                  <BannerBtnLeft />
                </Div>
                <Div
                  ref={nextRef}
                  pos="absolute"
                  transform="translateY(-50%)"
                  top="55%"
                  right="30%"
                  d={isMobile ? "none" : "flex"}
                >
                  <BannerBtnRight />
                </Div>

                <Div
                  // border="2px solid"
                  // borderColor="#242424"
                  w="85%"
                  maxW="350px"
                  h="665px"
                  maxH="665px"
                  rounded="24px"
                  pos="absolute"
                  zIndex="0"
                  // bg="#e3e3e3"
                />

                {/* 卡片 */}
                <Swiper
                  id="easyRead"
                  effect="coverflow"
                  // speed={100}
                  touchAngle={15}
                  spaceBetween={16}
                  modules={[Navigation, EffectCoverflow, HashNavigation]}
                  // cardsEffect={{
                  //   perSlideOffset: 1,
                  //   perSlideRotate: 1,
                  //   slideShadows: true,
                  // }}
                  coverflowEffect={{
                    slideShadows: false,
                    depth: 0,
                  }}
                  navigation={{
                    prevEl: prevRef.current,
                    nextEl: nextRef.current,
                  }}
                  hashNavigation={{
                    watchState: true,
                    replaceState: true,
                  }}
                  onBeforeInit={(swiper) => {
                    swiper.params.navigation.prevEl = prevRef.current;
                    swiper.params.navigation.nextEl = nextRef.current;
                  }}
                  // grabCursor="true"
                  onSlideChange={handleSlideChange}
                >
                  {/* page 1 */}
                  <SwiperSlide data-hash={page}>
                    {showOverlay && (
                      <Div
                        w="100%"
                        h="100%"
                        bg=" rgba(85,85,85,0.6)"
                        rounded="24px"
                        d="flex"
                        flexDir="column"
                        justify="center"
                        align="flex-end"
                        pos="absolute"
                        zIndex="1"
                        p={{ x: "0.5rem" }}
                      >
                        <Image w="110px" src={Swipe} />
                        <Text
                          textSize="reportHeading2"
                          textColor="#ffffff"
                          textWeight="600"
                        >
                          左滑查看報告
                        </Text>
                      </Div>
                    )}
                    <Div w="100%" h="100%" pos="absolute" zIndex="0">
                      <CardSlide
                        cardBackground={cardBg}
                        pageNum={page}
                        totalPage={totalPage}
                        content={
                          <Div
                            h="100%"
                            bgImg={cardPage1}
                            bgSize="contain"
                            bgRepeat="no-repeat"
                            bgPos="bottom"
                            d="flex"
                            flexDir="column"
                            align="center"
                            rounded={{
                              b: "xl",
                            }}
                            p={{ t: "20%", x: "2.5rem" }}
                          >
                            <Div
                              // p={{ x: "0.5rem" }}
                              d="flex"
                              flexDir="column"
                              justify="center"
                              align="center"
                            >
                              <Text
                                textSize="reportHeading4"
                                textColor="#555555"
                              >
                                哈囉 『{report.pet_name}』 的主人
                              </Text>
                              <Div m={{ y: "0.75rem" }} textAlign="center">
                                <Text
                                  textSize="reportHeading1"
                                  textColor="#242424"
                                  textWeight="600"
                                >
                                  這份報告將揭露毛孩
                                  <br />
                                  的腸道世界
                                </Text>
                              </Div>
                              <Div
                                rounded="circle"
                                w="126px"
                                h="126px"
                                border="2px solid"
                                borderColor="#A6A4A1"
                                bgImg={report.pet_image_url}
                                bgSize="cover"
                                bgPos="center"
                              />
                            </Div>
                          </Div>
                        }
                      />
                    </Div>
                  </SwiperSlide>

                  {/* page2 */}
                  <SwiperSlide data-hash={page}>
                    <CardSlide
                      cardBackground={cardBg}
                      pageNum={page}
                      totalPage={totalPage}
                      content={
                        <Div
                          h="100%"
                          bgImg={cardPage2}
                          bgSize="111%"
                          bgRepeat="no-repeat"
                          bgPos="bottom"
                          d="flex"
                          flexDir="column"
                          align="center"
                          rounded={{
                            b: "xl",
                          }}
                        >
                          <Div
                            m={{ t: "8rem", x: "2.5rem" }}
                            d="flex"
                            justify="center"
                            align="center"
                          >
                            <Text
                              textSize="reportHeading2"
                              textColor="#242424"
                              textWeight="600"
                            >
                              如果我們把住在腸道的微生物們，想像成是一個小小國家，那麼『
                              {report.pet_name}』腸道國的最大宗居民是...
                            </Text>
                          </Div>
                        </Div>
                      }
                    />
                  </SwiperSlide>

                  {/* page3 */}
                  <SwiperSlide data-hash={page}>
                    <CardSlide
                      cardBackground={
                        report.dominant_taxon.type === "good"
                          ? cardBgGood
                          : report.dominant_taxon.type === "bad"
                          ? cardBgBad
                          : cardBgNeutral
                      }
                      pageNum={page}
                      totalPage={totalPage}
                      content={
                        <BacIntro
                          title="最大宗居民"
                          //todo: 看回傳的變數怎麼取的，要在去修改BacIntro裡的判斷
                          bacType={report.dominant_taxon.type}
                          roleImage={report.dominant_taxon.bac_img_src}
                          chineseName={report.dominant_taxon.bac_name_ch}
                          engName={report.dominant_taxon.bac_name_en}
                          job={report.dominant_taxon.occupation}
                          feature={report.dominant_taxon.brief}
                        />
                      }
                    />
                  </SwiperSlide>

                  {/* page4 */}
                  <SwiperSlide data-hash={page}>
                    <CardSlide
                      cardBackground={cardBg}
                      pageNum={page}
                      totalPage={totalPage}
                      content={
                        <Div
                          h="100%"
                          bgImg={cardPage4}
                          bgSize="contain"
                          bgRepeat="no-repeat"
                          bgPos="bottom"
                          d="flex"
                          flexDir="column"
                          align="center"
                          rounded={{
                            b: "xl",
                          }}
                        >
                          <Div
                            m={{ t: "8%", x: "2rem" }}
                            border="2px solid"
                            rounded="xl"
                            p={{ x: "1.25rem", y: "1.5rem" }}
                            bg="#ffffff"
                          >
                            <Text
                              textSize="reportHeading2"
                              textColor="#555555"
                              textWeight="600"
                            >
                              身為飼主你知道嗎...
                              <Text
                                m={{ t: "0.5em", b: "0.5rem" }}
                                d="inline-block"
                              >
                                「
                                <Text d="inline-block" textColor="#EB4C27">
                                  腸道的環境
                                </Text>
                                」和毛孩的健康有很大的關係，從營養吸收、免疫系統、情緒反應，都會受到腸道菌群的影響。
                              </Text>
                              <Text d="inline-block">
                                其中，「足夠的
                                <Text d="inline-block" textColor="#EB4C27">
                                  益生菌
                                </Text>
                                可以幫助毛孩消化吸收養分、抵抗壞細菌」，提升整體的健康狀態。
                              </Text>
                            </Text>
                          </Div>
                        </Div>
                      }
                    />
                  </SwiperSlide>

                  {/* page5 */}
                  <SwiperSlide data-hash={page}>
                    <CardSlide
                      cardBackground={cardBg}
                      pageNum={page}
                      totalPage={totalPage}
                      content={
                        <Div
                          h="100%"
                          d="flex"
                          flexDir="column"
                          align="center"
                          rounded={{
                            b: "xl",
                          }}
                          m={{ t: "8rem", x: "2.5rem" }}
                        >
                          <Text
                            textSize="reportHeading2"
                            textColor="#242424"
                            textWeight="600"
                          >
                            根據檢測結果，『{name}』腸道中的益生菌比例是...
                          </Text>
                          <Div m={{ t: "2.25rem" }}>
                            <Image w="135px" src={cardPage5} />
                          </Div>
                        </Div>
                      }
                    />
                  </SwiperSlide>

                  {/* page6 */}
                  <SwiperSlide data-hash={page}>
                    <CardSlide
                      cardBackground={cardBgRatio}
                      pageNum={page}
                      totalPage={totalPage}
                      content={
                        <RatioPage
                          name={name}
                          title="益生菌"
                          type="good"
                          forwardPosition={
                            report.probiotic_pathogen_scores.probiotics
                          }
                        />
                      }
                    />
                  </SwiperSlide>

                  {/* page7 */}
                  <SwiperSlide data-hash={page}>
                    <CardSlide
                      cardBackground={cardBgRatio}
                      pageNum={page}
                      totalPage={totalPage}
                      content={
                        <RatioResultPage
                          name={name}
                          type="good"
                          forwardPosition={
                            report.probiotic_pathogen_scores.probiotics
                          }
                        />
                      }
                    />
                  </SwiperSlide>

                  {/* page8 */}
                  <SwiperSlide data-hash={page}>
                    <CardSlide
                      cardBackground={cardBgGood}
                      pageNum={page}
                      totalPage={totalPage}
                      content={
                        <BacIntro
                          title="益生菌班長"
                          bacType="good"
                          roleImage={report.dominant_probiotic.bac_img_src}
                          chineseName={report.dominant_probiotic.bac_name_ch}
                          engName={report.dominant_probiotic.bac_name_en}
                          job={report.dominant_probiotic.occupation}
                          feature={report.dominant_probiotic.brief}
                        />
                      }
                    />
                  </SwiperSlide>

                  {/* page9 */}
                  <SwiperSlide data-hash={page}>
                    <CardSlide
                      cardBackground={cardBg}
                      pageNum={page}
                      totalPage={totalPage}
                      content={
                        <Div
                          h="100%"
                          bgImg={CardPage9}
                          bgSize="cover"
                          bgRepeat="no-repeat"
                          bgPos="bottom"
                          d="flex"
                          flexDir="column"
                          align="center"
                          rounded={{
                            b: "xl",
                          }}
                        >
                          <Div
                            m={{ t: "11rem", x: "1rem" }}
                            d="flex"
                            flexDir="column"
                            justify="center"
                            align="center"
                            border="2px solid"
                            borderColor="#242424"
                            rounded="xl"
                            bg="#F7DE3E"
                          >
                            <Div
                              w="100%"
                              p={{ y: "0.2rem" }}
                              bg="#242424"
                              rounded={{ tl: "lg", tr: "lg" }}
                              textAlign="center"
                            >
                              <Text
                                textSize="reportHeading2"
                                textColor="#F7DE3E"
                                textWeight="600"
                              >
                                <Icon
                                  name="AlertSolid"
                                  size="24px"
                                  color="#F7DE3E"
                                  m={{ r: "0.25rem" }}
                                />
                                警告
                              </Text>
                            </Div>
                            <Div p={{ x: "2rem", y: "1.5rem" }}>
                              <Text
                                textSize="reportHeading2"
                                textColor="#242424"
                                textWeight="600"
                              >
                                但是...根據檢測結果我們也發現了一些值得關注的警訊！
                              </Text>
                            </Div>
                          </Div>
                        </Div>
                      }
                    />
                  </SwiperSlide>

                  {/* page10 */}
                  <SwiperSlide data-hash={page}>
                    <CardSlide
                      cardBackground={cardBg}
                      pageNum={page}
                      totalPage={totalPage}
                      content={
                        <Div
                          h="100%"
                          bgImg={CardPage10}
                          bgSize="contain"
                          bgRepeat="no-repeat"
                          bgPos="bottom"
                          d="flex"
                          flexDir="column"
                          align="center"
                          rounded={{
                            b: "xl",
                          }}
                        >
                          <Div m={{ t: "1.5rem", b: "-5px" }}>
                            <Image w="50px" src={Warning} />
                          </Div>

                          <Div
                            m={{ x: "2rem" }}
                            border="2px solid"
                            rounded="xl"
                            p={{ x: "1.25rem", y: "1rem" }}
                            bg="#ffffff"
                          >
                            <Text
                              textSize="reportHeading2"
                              textColor="#555555"
                              textWeight="600"
                            >
                              <Text m={{ t: "0.5em", b: "0.5rem" }}>
                                我們在『{name}』的腸胃中檢測到
                                <Text tag="span" textColor="#EB4C27">
                                  致病菌
                                </Text>
                                的存在 😭
                              </Text>
                              <Text tag="span" m={{ t: "0.5em", b: "0.5rem" }}>
                                致病菌可能導致
                                <Text tag="span" textColor="#EB4C27">
                                  腹瀉、嘔吐、消化不良、過敏等症狀
                                </Text>
                                ，影響 『{name}』整體的健康狀況～
                              </Text>
                            </Text>
                          </Div>
                        </Div>
                      }
                    />
                  </SwiperSlide>

                  {/* page11 */}
                  <SwiperSlide data-hash={page}>
                    <CardSlide
                      cardBackground={cardBg}
                      pageNum={page}
                      totalPage={totalPage}
                      content={
                        <Div
                          h="100%"
                          d="flex"
                          flexDir="column"
                          align="center"
                          rounded={{
                            b: "xl",
                          }}
                          m={{ t: "8rem", x: "2.5rem" }}
                        >
                          <Text
                            textSize="reportHeading2"
                            textColor="#242424"
                            textWeight="600"
                          >
                            根據檢測結果，『{name}』腸道中的致病菌比例是...
                          </Text>
                          <Div m={{ t: "2.25rem" }}>
                            <Image w="135px" src={cardPage5} />
                          </Div>
                        </Div>
                      }
                    />
                  </SwiperSlide>

                  {/* page12 */}
                  <SwiperSlide data-hash={page}>
                    <CardSlide
                      cardBackground={cardBgRatio}
                      pageNum={page}
                      totalPage={totalPage}
                      content={
                        <RatioPage
                          name={name}
                          title="致病菌"
                          type="bad"
                          forwardPosition={
                            report.probiotic_pathogen_scores.pathogens
                          }
                        />
                      }
                    />
                  </SwiperSlide>

                  {/* page13 */}
                  <SwiperSlide data-hash={page}>
                    <CardSlide
                      cardBackground={cardBgRatio}
                      pageNum={page}
                      totalPage={totalPage}
                      content={
                        <RatioResultPage
                          name={name}
                          type="bad"
                          forwardPosition={
                            report.probiotic_pathogen_scores.pathogens
                          }
                        />
                      }
                    />
                  </SwiperSlide>

                  {/* page14 */}
                  <SwiperSlide data-hash={page}>
                    <CardSlide
                      cardBackground={cardBgBad}
                      pageNum={page}
                      totalPage={totalPage}
                      content={
                        <BacIntro
                          title="致病菌大魔王"
                          bacType="bad"
                          roleImage={report.dominant_pathogen.bac_img_src}
                          chineseName={report.dominant_pathogen.bac_name_ch}
                          engName={report.dominant_pathogen.bac_name_en}
                          job={report.dominant_pathogen.occupation}
                          feature={report.dominant_pathogen.brief}
                        />
                      }
                    />
                  </SwiperSlide>

                  {/* 貓 page15 16 */}
                  {pet === "cat" && (
                    <>
                      <SwiperSlide data-hash={page}>
                        <CardSlide
                          cardBackground={cardBg}
                          pageNum={page}
                          totalPage={totalPage}
                          content={<Disease name={name} pet="cat" />}
                        />
                      </SwiperSlide>
                      <SwiperSlide data-hash={page}>
                        <CardSlide
                          cardBackground={cardBgRatio}
                          pageNum={page}
                          totalPage={totalPage}
                          content={
                            <DiseaseResult
                              name={name}
                              pet="cat"
                              title="疾病風險評估"
                              result={report.diseases.ibd_risk.pr_score}
                              diseaseIndex={0}
                            />
                          }
                        />
                      </SwiperSlide>
                    </>
                  )}

                  {/* 狗 page15 16 17 */}
                  {pet === "dog" && (
                    <>
                      <SwiperSlide data-hash={page}>
                        <CardSlide
                          cardBackground={cardBg}
                          pageNum={page}
                          totalPage={totalPage}
                          content={<Disease name={name} pet="dog" />}
                        />
                      </SwiperSlide>
                      <SwiperSlide data-hash={page}>
                        <CardSlide
                          cardBackground={cardBgRatio}
                          pageNum={page}
                          totalPage={totalPage}
                          content={
                            <DiseaseResult
                              name={name}
                              pet="dog"
                              title="疾病風險評估一"
                              result={report.diseases.gdv_risk.pr_score}
                              diseaseIndex={0}
                            />
                          }
                        />
                      </SwiperSlide>

                      <SwiperSlide data-hash={page}>
                        <CardSlide
                          cardBackground={cardBgRatio}
                          pageNum={page}
                          totalPage={totalPage}
                          content={
                            <DiseaseResult
                              name={name}
                              pet="dog"
                              title="疾病風險評估二"
                              result={report.diseases.ce_risk.pr_score}
                              diseaseIndex={1}
                            />
                          }
                        />
                      </SwiperSlide>
                    </>
                  )}

                  {/* page18 */}
                  <SwiperSlide data-hash={page}>
                    <CardSlide
                      cardBackground={cardBg}
                      pageNum={page}
                      totalPage={totalPage}
                      content={
                        <Div
                          d="flex"
                          flexDir="column"
                          align="center"
                          justify="center"
                          rounded={{
                            b: "xl",
                          }}
                          m={{ t: "50%", x: "2.5rem" }}
                        >
                          <Text
                            textSize="reportHeading2"
                            textColor="#242424"
                            textWeight="600"
                          >
                            最後，科學毛怪想要對『{name}』的主人說...
                          </Text>
                        </Div>
                      }
                    />
                  </SwiperSlide>

                  {/* page19 */}
                  <SwiperSlide data-hash={page}>
                    <CardSlide
                      cardBackground={cardBgRatio}
                      pageNum={page}
                      totalPage={totalPage}
                      content={
                        <Div
                          d="flex"
                          flexDir="column"
                          align="center"
                          justify="center"
                          rounded={{
                            b: "xl",
                          }}
                          m={{ x: "1.5rem", y: "1.5rem" }}
                        >
                          <Div
                            rounded="circle"
                            w="81px"
                            h="81px"
                            border="2px solid"
                            borderColor="#A6A4A1"
                            bgImg={report.pet_image_url}
                            bgSize="cover"
                            bgPos="center"
                          />
                          <Text
                            textSize="reportHeading2"
                            textColor="#242424"
                            textWeight="600"
                            m={{ y: "0.75rem" }}
                          >
                            {name} / {sex} / {age}
                          </Text>
                          <Div
                            d="flex"
                            flexDir="column"
                            justify="center"
                            align="center"
                            pos="relative"
                          >
                            <Div
                              bg="#555555"
                              rounded="120px"
                              pos="absolute"
                              top="0"
                            >
                              <Text
                                textColor="#ffffff"
                                textSize="20px"
                                textWeight="600"
                                p={{ x: "1.5rem", y: "0.8rem" }}
                                textAlign="center"
                              >
                                總體評價
                              </Text>
                            </Div>
                            <Div p={{ t: "8%" }}>
                              <Div
                                border="2px solid"
                                borderColor="#242424"
                                rounded="16px"
                                bgImg={CardPage19}
                                bgSize="cover"
                                bgPos="center"
                                m={{ b: "0.75rem" }}
                              >
                                <div
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "20px",
                                    color: "#242424",
                                    padding: "1.3rem",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: report.report_abstract,
                                  }}
                                />
                              </Div>
                            </Div>

                            {/* <Image src={CardPage19Btm} w="120px" /> */}

                            <Div m={{ t: "0.75rem" }} d="flex" flexDir="row">
                              <Div
                                d="flex"
                                flexDir="row"
                                align="center"
                                justify="center"
                                m={{ r: "1rem" }}
                                onClick={copyUrl}
                              >
                                <Div m={{ r: "0.5rem" }}>
                                  <Copy />
                                </Div>
                                <Text
                                  textSize="16px"
                                  textColor="neutral800"
                                  textWeight="700"
                                  textDecor="underline"
                                >
                                  複製連結
                                </Text>
                              </Div>

                              <Div
                                d="flex"
                                flexDir="row"
                                align="center"
                                justify="center"
                                m={{ l: "1rem" }}
                                onClick={shareToLine}
                              >
                                <Div m={{ r: "0.5rem" }}>
                                  <Line />
                                </Div>

                                <Text
                                  textSize="16px"
                                  textColor="neutral800"
                                  textWeight="700"
                                  textDecor="underline"
                                >
                                  分享連結
                                </Text>
                              </Div>
                            </Div>
                          </Div>
                        </Div>
                      }
                    />
                  </SwiperSlide>

                  {/* page20 */}
                  <SwiperSlide data-hash={page}>
                    <CardSlide
                      cardBackground={unpaidPage ? cardBgNeutral : cardBg}
                      pageNum={page}
                      totalPage={totalPage}
                      content={
                        <>
                          {unpaidPage ? (
                            <SwiperSlide>
                              {/* 當是未付費時，lockedTimer = true先鎖上顯示此頁，並記時 */}
                              {lockedTimer && (
                                <Div
                                  d="flex"
                                  flexDir="column"
                                  align="center"
                                  justify="center"
                                  rounded={{
                                    b: "xl",
                                  }}
                                  m={{ t: "6.5rem", x: "3rem" }}
                                >
                                  <Div
                                    w="70px"
                                    h="70px"
                                    bgImg={CardPage20}
                                    bgSize="cover"
                                    bgPos="center"
                                  />

                                  <Div m={{ t: "1rem" }}>
                                    <Text
                                      textSize="24px"
                                      textColor="#242424"
                                      textWeight="700"
                                      textAlign="center"
                                    >
                                      \ 歡迎解鎖更多內容 /
                                    </Text>

                                    <Div
                                      d="flex"
                                      flexDir="column"
                                      justify="center"
                                      align="center"
                                      m={{ t: "1.5rem" }}
                                    >
                                      <Text
                                        tag="span"
                                        textSize="16px"
                                        textColor="#242424"
                                        textWeight="700"
                                        m={{ b: "0.5rem" }}
                                      >
                                        想了解更多毛孩
                                        <Text
                                          tag="span"
                                          textSize="16px"
                                          textColor="#EB4C27"
                                          textWeight="700"
                                          textDecor="underline"
                                        >
                                          健康資訊
                                        </Text>
                                        嗎?
                                      </Text>
                                      <Text
                                        tag="span"
                                        textSize="16px"
                                        textColor="#242424"
                                        textWeight="700"
                                        m={{ b: "0.5rem" }}
                                      >
                                        想了解毛孩
                                        <Text
                                          tag="span"
                                          textSize="16px"
                                          textColor="#EB4C27"
                                          textWeight="700"
                                          textDecor="underline"
                                        >
                                          適合補充的營養
                                        </Text>
                                        嗎?
                                      </Text>
                                      <Text
                                        tag="span"
                                        textSize="16px"
                                        textColor="#242424"
                                        textWeight="700"
                                        m={{ b: "0.5rem" }}
                                      >
                                        想為毛孩健康
                                        <Text
                                          tag="span"
                                          textSize="16px"
                                          textColor="#EB4C27"
                                          textWeight="700"
                                          textDecor="underline"
                                        >
                                          採取行動
                                        </Text>
                                        嗎?
                                      </Text>
                                    </Div>
                                  </Div>
                                </Div>
                              )}

                              {/* 當時間過後，lockedTimer = false 切換，再顯示此頁 */}
                              {!lockedTimer && (
                                <Div
                                  d="flex"
                                  flexDir="column"
                                  justify="center"
                                  align="center"
                                >
                                  <Div
                                    d="flex"
                                    flexDir="column"
                                    align="center"
                                    justify="center"
                                    rounded={{
                                      b: "xl",
                                    }}
                                    m={{ t: "2rem", x: "3rem" }}
                                  >
                                    <Div>
                                      <Text
                                        textSize="20px"
                                        textColor="#242424"
                                        textWeight="700"
                                        textAlign="center"
                                      >
                                        專業腸道菌項檢測報告
                                      </Text>
                                    </Div>
                                    <Div m={{ t: "0.5rem" }}>
                                      <Image src={ToBeUnlocked} w="220px" />
                                    </Div>
                                    <Div
                                      d="flex"
                                      justify="center"
                                      align="center"
                                    >
                                      <Text
                                        tag="span"
                                        textSize="header"
                                        textColor="#242424"
                                        textWeight="700"
                                      >
                                        NT $
                                      </Text>
                                      <Text
                                        tag="span"
                                        textSize="2.25rem"
                                        textColor="#242424"
                                        textWeight="700"
                                      >
                                        999
                                      </Text>
                                    </Div>
                                    <Div
                                      m={{ t: "0.5rem" }}
                                      onClick={onModalOpen}
                                    >
                                      <Text
                                        tag="span"
                                        textSize="16px"
                                        textColor="#EB4C27"
                                        textWeight="700"
                                        textDecor="underline"
                                        cursor="pointer"
                                      >
                                        部分報告搶先看
                                      </Text>
                                    </Div>
                                  </Div>
                                  <Div
                                    d="flex"
                                    flexDir="row"
                                    justify="center"
                                    align="flex-start"
                                    m={{ t: "1.25rem" }}
                                  >
                                    <Div d="flex" flexDir="column">
                                      <Text
                                        textSize="subheading1"
                                        textWeight="500"
                                        textColor="#555555"
                                      >
                                        📍優勢菌概況
                                      </Text>
                                      <Text
                                        textSize="subheading1"
                                        textWeight="500"
                                        textColor="#555555"
                                      >
                                        📍益生菌比例
                                      </Text>
                                      <Text
                                        textSize="subheading1"
                                        textWeight="500"
                                        textColor="#555555"
                                      >
                                        📍致病菌比例
                                      </Text>
                                      <Text
                                        textSize="subheading1"
                                        textWeight="500"
                                        textColor="#555555"
                                      >
                                        📍疾病風險評估
                                      </Text>
                                      <Text
                                        textSize="subheading1"
                                        textWeight="500"
                                        textColor="#555555"
                                      >
                                        📍報告總評
                                      </Text>
                                      <Text
                                        textSize="subheading1"
                                        textWeight="500"
                                        textColor="#555555"
                                      >
                                        📍核心菌群
                                      </Text>
                                      <Text
                                        textSize="subheading1"
                                        textWeight="500"
                                        textColor="#555555"
                                      >
                                        📍菌種功能解析
                                      </Text>
                                    </Div>

                                    <Div
                                      d="flex"
                                      flexDir="column"
                                      m={{ l: "1rem" }}
                                    >
                                      <Text
                                        textSize="subheading1"
                                        textWeight="500"
                                        textColor="#555555"
                                      >
                                        📍多樣性分析
                                      </Text>
                                      <Text
                                        textSize="subheading1"
                                        textWeight="500"
                                        textColor="#555555"
                                      >
                                        📍報告摘要
                                      </Text>
                                      <Text
                                        textSize="subheading1"
                                        textWeight="500"
                                        textColor="#555555"
                                      >
                                        📍益生菌檢測
                                      </Text>
                                      <Text
                                        textSize="subheading1"
                                        textWeight="500"
                                        textColor="#555555"
                                      >
                                        📍致病菌檢測
                                      </Text>
                                      <Text
                                        textSize="subheading1"
                                        textWeight="500"
                                        textColor="#555555"
                                      >
                                        📍整體腸道菌比例
                                      </Text>
                                      <Text
                                        textSize="subheading1"
                                        textWeight="500"
                                        textColor="#555555"
                                      >
                                        📍客製化飲食推薦
                                      </Text>
                                    </Div>
                                  </Div>
                                  <Div
                                    m={{ t: "1.25rem" }}
                                    onClick={() =>
                                      window.open(
                                        "https://lin.ee/zjeRT9i",
                                        "_blank"
                                      )
                                    }
                                  >
                                    <Btn text="立即解鎖" />
                                  </Div>
                                </Div>
                              )}
                            </SwiperSlide>
                          ) : (
                            <Div
                              d="flex"
                              flexDir="column"
                              align="center"
                              justify="center"
                              rounded={{
                                b: "xl",
                              }}
                              m={{ t: "6.5rem", x: "3rem" }}
                            >
                              <Div
                                w="70px"
                                h="70px"
                                bgImg={CardPage20}
                                bgSize="cover"
                                bgPos="center"
                              />

                              <Div m={{ t: "1rem" }}>
                                <Text
                                  textSize="reportHeading2"
                                  textColor="#555555"
                                  textWeight="600"
                                  textAlign="center"
                                >
                                  想知道更詳細的報告資訊
                                  <br />
                                  請點擊此按鈕閱讀更多內容
                                </Text>

                                <Div
                                  d="flex"
                                  flexDir="column"
                                  justify="center"
                                  align="center"
                                >
                                  <Image
                                    w="35px"
                                    src={Forward}
                                    transform="rotate(0.5turn)"
                                  />
                                  <BtnWithIcon
                                    text="完整版報告"
                                    iconName={<PetsWhite />}
                                    hoverIconName={<PetsPurple />}
                                    onClick={() => showDetailReport(reportSha)}
                                  />
                                </Div>
                              </Div>
                            </Div>
                          )}
                        </>
                      }
                    />
                  </SwiperSlide>
                </Swiper>

                <Modal
                  isOpen={isOpen}
                  onClose={onModalClose}
                  rounded="md"
                  maxW="24rem"
                  align="center"
                  justify="center"
                >
                  <Icon
                    name="Cross"
                    pos="absolute"
                    top="1rem"
                    right="1rem"
                    size="16px"
                    onClick={onModalClose}
                    cursor="pointer"
                  />
                  <Text
                    textSize="header"
                    textWeight="600"
                    textColor="#F3B23E"
                    textAlign="center"
                  >
                    給 {name} 的食物營養建議
                  </Text>
                  <Div m={{ t: "0.5rem" }}>
                    <Div
                      style={{
                        borderLeft: "8px solid",
                        borderColor: "#555555",
                      }}
                    >
                      <Div m={{ l: "0.5rem" }}>
                        <Text
                          tag="span"
                          textSize="heading3"
                          textColor="neutral900"
                          textWeight="600"
                        >
                          經過腸道菌相檢測後，我們希望 {name}{" "}
                          <Text
                            tag="span"
                            textSize="heading3"
                            textColor="#EB4C27"
                            textWeight="600"
                            m={{
                              l: "0.5rem",
                              y: "0rem",
                            }}
                          >
                            額外
                          </Text>
                          攝取的營養是...
                        </Text>
                      </Div>
                    </Div>
                  </Div>
                  <Div m={{ t: "1rem", b: "1.5rem" }}>
                    <Text
                      textSize="reportEngBac"
                      textWeight="400"
                      textColor="#555"
                    >
                      食品營養推薦分析是我們的獨家技術,透過AI分析腸道所有居民的功能,來了解寵物的腸道中目前缺乏那些營養素，更進一步將營養素對應到可補充的食物，來幫助毛孩的腸胃更加健康。
                    </Text>
                  </Div>

                  <Div m={{ t: "2rem" }}>
                    <Image src={UnlockedTable} />
                  </Div>
                </Modal>
              </Div>
            </Div>
          </Div>
        </>
      )}

      {/* api回傳非200/402/500時顯示錯誤訊息 */}
      <Div d="flex" justify="center" align="center">
        <Notification
          d={showErrorNotification ? "flex" : "none"}
          bg="#FFE0E0"
          textColor="#F03D3D"
          isOpen={showErrorNotification}
          onClose={() => setShowErrorNotification(false)}
        >
          發生未知錯誤，請洽客服。
        </Notification>
        <Notification
          d={copied ? "flex" : "none"}
          bg="#E2FCF0"
          textColor="#0A9C55"
          isOpen={copied}
          onClose={() => setShowErrorNotification(false)}
        >
          已複製連結
        </Notification>
      </Div>
    </>
  );
};

export default ReportsEasyV2;
