import { Button, Modal, Icon, Div } from "atomize";
import React, { useState } from "react";

//modal內容
//children 為modal中顯示的內容
const ModalSize = ({ isOpen, onClose, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      rounded="md"
      maxW="22rem"
      m={{ y: "4rem", x: { xs: "1rem", lg: "auto" } }}
      align="center"
      justify="center"
    >
      <Icon
        name="Cross"
        pos="absolute"
        top="1rem"
        right="1rem"
        size="16px"
        onClick={onClose}
        cursor="pointer"
      />
      {children}
    </Modal>
  );
};

//引用的組件
// buttonProps設定button樣式
// chrildren 為react特殊的props，放置在<SmallModal></SmallModal>中都是children，被傳遞回components中
const SmallModal = ({ object, children }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Div cursor="pointer" onClick={() => setShowModal(true)}>
        {object}
      </Div>
      {/* <Button {...buttonProps} onClick={() => setShowModal(true)}>
        <Icon name="InfoSolid" size="20px" color="#EB4C27"/>
      </Button> */}
      <ModalSize isOpen={showModal} onClose={() => setShowModal(false)}>
        {children}
      </ModalSize>
    </>
  );
};

export default SmallModal;
