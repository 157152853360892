import html2canvas from 'html2canvas';
import React, { useEffect, useState } from 'react'

const Shares = () => {
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        const captureStyle = {
            background: 'linear-gradient(180deg, #FFCABE 0%, #FEF38D 100%)',
            padding: '2rem',
            width: '700px'
        };

        const targetElement = document.getElementById('captureTarget');
        Object.assign(targetElement.style, captureStyle);

        html2canvas(targetElement, { useCORS: true }).then(canvas => {
            const imgURL = canvas.toDataURL("image/png");
            setImageUrl(imgURL);
        });
    }, []);
    return (
        <div>
            {imageUrl ? <img src={imageUrl} alt="Captured content" style={{ maxWidth: '100%', height: 'auto' }} /> : <div>Loading...</div>}
            <div id="captureTarget">
                {/* This is the content you want to capture */}
            </div>
        </div>
    )
}

export default Shares