import React from 'react'
import { Div, Button } from "atomize";
import { Icon } from "atomize";


const Btn = ({ text, onClick, disabled }) => {
    return (
        <>

            <Button
                w="100%"
                rounded="lg"
                textSize="14px"
                textColor="#FFFFFF"
                bg="#79384A"
                d="flex"
                justify="center"
                align="center"
                textWeight="600"
                border="1px solid"
                borderColor="#79384A"
                hoverTextColor="#79384A"
                hoverBg="#FFFFFF"
                hoverBorderColor="#79384A"
                // m={{ b: "1rem" }}
                onClick={onClick}
                disabled={disabled}
            >
                {text}
            </Button>


        </>

    )
}

export default Btn
