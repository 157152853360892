import { Button, Div, Icon, Image, Tag, Text } from 'atomize';
import React, { useEffect, useRef, useState } from 'react';
import NavBar from './components/NavBar';
import PetProfile from './assets/images/pet_profile.png';
import GoodBac from './assets/images/good_bac.png';
import BadBac from './assets/images/bad_bac.png';
import { useNavigate, useParams } from 'react-router-dom';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import html2canvas from 'html2canvas';
import Btn from './components/Btn';
import useDocumentTitle from './components/useDocumentTitle';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const pieOption = {
    plugins: {
        legend: {
            position: 'right',
            labels: {
                usePointStyle: true,
                font: {
                    size: 13
                },

            },
            onClick: () => {
                // 簡單地返回，阻止默認的點擊行為
                return;
            }
        },
        datalabels: {
            formatter: (value, context) => {
                return value + '%';
            },
            color: '#FFFFFF',
            textStrokeColor: '#48222C',
            textStrokeWidth: '3',
            display: true,
            align: (context) => {  // 使用函數動態設定對齊方式
                const value = context.dataset.data[context.dataIndex];
                return value < 10 ? 'end' : 'center';
            },
            anchor: (context) => {  // 使用函數動態設定錨點
                const value = context.dataset.data[context.dataIndex];
                return value < 10 ? 'end' : 'center';
            },
            borderRadius: 3,
            font: (context) => {  // 使用函數動態設定字體大小
                const value = context.dataset.data[context.dataIndex];
                return {
                    size: value > 9 ? 20 : 13,
                    weight: 900
                };
            },
        }

    },
}


const textOutlineStyle = {
    WebkitTextStroke: '1.5px white',
    color: 'black'
};

const ReportsEasy = () => {
    useDocumentTitle('報告結果 - PetSci');
    const { id } = useParams();

    const navigate = useNavigate();

    const [profile, setProfile] = useState({})

    const [Info, setInfo] = useState({});
    const [TagData, setTagData] = useState({});
    const [similarity, setSimilarity] = useState({});

    const [ratioData, setRatioData] = useState({});

    const [food, setFood] = useState({});
    const [group, setGroup] = useState({});

    const [rank1, setRank1] = useState({});
    const [goodBac, setGoodBac] = useState({});
    const [badBac, setBadBac] = useState({});
    const [comment, setComment] = useState({});
    const [detailReport, setDetailReport] = useState();

    const [isLoading, setIsLoading] = useState(true);
    const [isProfileLoading, setIsProfileLoading] = useState(false);

    const easyReadApiUrl = `${process.env.REACT_APP_API_URL}/reports/${id}/easy-read/`;
    const refreshApiUrl = `${process.env.REACT_APP_API_URL}/users/token/refresh/`;

    const fetchReport = () => {
        const storedAccess = localStorage.getItem("access");

        fetch(easyReadApiUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${storedAccess}`,
            },
        })
            .then((response) => {
                if (response.status === 401) {
                    // alert("access 過期");
                    refreshRequst();
                }
                else if (response.status === 404) {
                    navigate("*")
                }
                else {
                    return response.json();
                }
            })
            .then((data) => {
                // console.log("data ", data);
                // console.log("dataTest ", data.pet_info.sex);
                setIsLoading(false);

                setProfile({
                    pet_profile: data.pet_img_url,
                })

                const newAge = getAge(data.pet_info.birth);
                setInfo({
                    pet_name: data.pet_info.pet_name,
                    birth: newAge,
                    sex: data.pet_info.sex,
                });

                const tagShow = {};
                Object.values(data.tags).forEach((key, index) => {
                    tagShow[`tag_${index + 1}`] = key.tag_name;
                });
                setTagData(tagShow);
                // console.log("tagSHow", tagShow);


                setSimilarity({
                    similarity: data.ratio.similarity * 100 + '%',
                    strain: data.pet_info.species,
                });
                setRatioData({
                    good_bac: data.ratio.good_bac,
                    bad_bac: data.ratio.bad_bac,
                })

                const foodData = data.food_recommend.Food; //{apple: 0.34, banana:0.24, mushroom:0.15}
                const foodKeys = Object.keys(foodData); //['apple', 'banana', 'mushroom']
                const foodShow = {}; //{no1:'apple, no2:'banana, no3: 'mushroom'}
                foodKeys.forEach((key, index) => {
                    foodShow[`no${index + 1}`] = key;
                });
                setFood(foodShow);

                const groupData = data.food_recommend.Group;
                const sortedGroupData = Object.fromEntries(  //array轉換成物件
                    Object.entries(groupData) //物件轉換成array  
                        .map(([k, v]) => [k, Math.round(v * 100)]) //每一個value轉換成%
                        .sort((a, b) => b[1] - a[1]) //排序
                );

                setGroup(sortedGroupData);

                setRank1({
                    img: data.rank_1_bac.img_src,
                    brief: data.rank_1_bac.brief,
                    species_name_ch: data.rank_1_bac.species_name_ch,
                    species_name: data.rank_1_bac.species_name,
                    description: data.rank_1_bac.description,
                    genus_name: data.rank_1_bac.genus_name,
                    genus_name_ch: data.rank_1_bac.genus_name_ch,
                });

                const GoodBacs = data.good_bacs;
                setGoodBac(GoodBacs);

                const BadBacs = data.bad_bacs;
                setBadBac(BadBacs);

                setComment({
                    commentDescription: data.comment,
                })
                setDetailReport(data.detail_report_url);

            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const refreshRequst = () => {
        const storedRefresh = localStorage.getItem("refresh");

        fetch(refreshApiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                refresh: storedRefresh,
            }),
        })
            .then((response) => {
                if (response.status === 401) {
                    localStorage.removeItem("access");
                    localStorage.removeItem("refresh");
                    navigate('/login');
                    // alert("refresh 過期 重新登入");
                } else if (response.status === 400) {
                    navigate('/login');
                }
                else {
                    return response.json();
                }
            })
            .then((data) => {
                const newAccess = data.access;
                localStorage.setItem("access", newAccess);
                fetchReport();
                //alert("第二次");
            })
            .catch((error) => {
                console.error("Error:", error);
                navigate('/login');
            });
    }

    //上傳檔案，隱藏<input type:file>觸發點集事件
    const fileInput = useRef(null);

    const handleButtonClick = () => {
        fileInput.current.click();
    }

    const handleProfileChange = (e) => {
        const uploadImage = e.target.files[0];
        // console.log(uploadImage);

        const formData = new FormData();
        formData.append('image', uploadImage);
        // console.log(formData)

        if (uploadImage) {
            setIsProfileLoading(true);
            const storedAccess = localStorage.getItem("access");

            fetch(easyReadApiUrl, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${storedAccess}`,
                },

                body: formData,
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setProfile({
                        pet_profile: data.url,
                    })
                    setIsProfileLoading(false);
                })
        }
    }

    //計算歲數
    function getAge(birth) {
        const birthDate = new Date(birth);
        const currentDate = new Date();

        let years = currentDate.getFullYear() - birthDate.getFullYear();
        let months = currentDate.getMonth() - birthDate.getMonth();

        // 如果當前月份小於生日的月份，則年齡 -1，月份加 12
        if (months < 0) {
            years--;
            months += 12;
        }

        // return `${years}歲${months}個月`;
        return `${years}歲`;
    }

    //計算比例寬度，以%作單位計算
    const widthNum = { good: ratioData.good_bac * 100, bad: ratioData.bad_bac * 100 };
    const widthTotal = widthNum.good + widthNum.bad;
    const goodWidth = (widthNum.good / widthTotal) * 100 + '%';
    const badWidth = (widthNum.bad / widthTotal) * 100 + '%';
    //計算顯示數值，以10作單位計算
    const showNum = { good: ratioData.good_bac * 10, bad: ratioData.bad_bac * 10 };
    const numTotal = 10;
    const goodNum = Math.round(showNum.good);
    const badNum = numTotal - goodNum;

    const FoodTag = ({ color, number, label }) => (
        <Tag
            bg={color}
            border="2px solid"
            borderColor="#48222C"
            textColor="#48222C"
            p={{ l: "1rem", y: "0.5rem" }}
            textSize="reportBody3"
            textWeight="700"
            rounded="md"
            m={{ b: '1rem' }}
            w="100%"
            justify="flex-start"
        >
            No.{number} {label}
        </Tag>
    );

    const pieData = {
        labels: Object.keys(group),
        datasets: [
            {
                data: Object.values(group),
                backgroundColor: [
                    '#9E0142',
                    '#FEDD8D',
                    '#69BDA9',
                    '#F2936A',
                    '#875CE0',
                    '#3757A9',
                ],
                borderColor: [
                    '#48222C',
                ],
                borderWidth: 2,
            },
        ],
    };

    useEffect(() => {
        fetchReport();
    }, []);

    // 下載圖片
    const [captureStyle, setCaptureStyle] = useState({}); //加背景 & padding
    const [isCommentVisible, setIsCommentVisible] = useState(true); //隱藏報告總評區段
    const [captureWidth, setCaptureWidth] = useState(true); //設定width寬度變化
    const [isSloganVisible, setIsSloganVisible] = useState(false); //設定截圖時出現

    const [shouldCapture, setShouldCapture] = useState(false); //確保 handleCapture 完全執行完畢後，再執行 useEffect
    //點按handleCapture觸發，給與captureStyle的值，調整下載寬度，並運行useEffect
    const handleCapture = () => {
        //alert("觸發handleCapture");
        setCaptureStyle({
            background: 'linear-gradient(180deg, #FFCABE 0%, #FEF38D 100%)',
            padding: '1.5rem',
        });
        setIsCommentVisible(false);
        setIsSloganVisible(true);

        let viewportMetaTag = document.querySelector('meta[name="viewport"]');
        if (!viewportMetaTag) {
            viewportMetaTag = document.createElement('meta');
            viewportMetaTag.name = "viewport";
            document.getElementsByTagName('head')[0].appendChild(viewportMetaTag);
        }
        viewportMetaTag.content = "width=1390px";
        setCaptureWidth(false);
        // alert("10021403");

        setTimeout(() => {
            setShouldCapture(true);
            //alert("應該要截圖")
        }, 500)
        //setShouldCapture(true);
    };

    useEffect(() => {
        if (shouldCapture) {

            if (Object.keys(captureStyle).length > 0) {
                const targetElement = document.getElementById('captureTarget');

                if (targetElement) {
                    html2canvas(targetElement, { allowTaint: true, useCORS: true }).then(canvas => {

                        let image = canvas.toDataURL("image/png");
                        if (navigator.userAgent.indexOf("Safari") > -1 && navigator.userAgent.indexOf("Chrome") === -1) {
                            // For Safari
                            // alert("safari");
                            let byteString = atob(image.split(',')[1]);
                            let ab = new ArrayBuffer(byteString.length);
                            let ia = new Uint8Array(ab);
                            for (let i = 0; i < byteString.length; i++) {
                                ia[i] = byteString.charCodeAt(i);
                            }
                            let blob = new Blob([ab], { type: 'image/png' });
                            let objectURL = URL.createObjectURL(blob);

                            let a = document.createElement('a');
                            a.href = objectURL;
                            a.download = '科學毛怪-菌相報告0128.png';

                            document.body.appendChild(a);
                            a.click();
                            document.body.removeChild(a);
                            setCaptureWidth(false);
                            alert("關閉此則訊息後，下一則訊息請按「檢視」，並長按「儲存到照片」即可。");
                        } else {
                            // For Chrome
                            // alert("chrome");
                            let a = document.createElement('a');
                            a.href = image;
                            a.download = '科學毛怪-菌相報告.png';
                            document.body.appendChild(a);
                            a.click();
                            document.body.removeChild(a);
                        }

                        //alert("下載");
                        setTimeout(() => {
                            //alert("回復原始寬度");
                            // // 截圖後恢復原樣
                            setCaptureStyle({});
                            setIsCommentVisible(true);
                            setCaptureWidth(true);
                            setIsSloganVisible(false);
                            // 恢復原始寬度
                            let viewportMetaTag = document.querySelector('meta[name="viewport"]');
                            if (viewportMetaTag) {
                                viewportMetaTag.content = "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no";
                                //alert("reload")
                                window.location.reload();
                            }
                        }, 5000);
                    })
                }
            }
            setShouldCapture(false);

        }
    }, [shouldCapture]); //當兩者的值有一發生變動時，就會運行

    function handleClickDetail(e) {
        e.preventDefault();  // 阻止默認的跳轉行為
        alert("文件密碼為您的手機號碼");
        window.open(e.currentTarget.href, '_blank');
    }

    return (
        <>
            {isLoading ? (
                <div className="loader-container">
                    <div className="loader"></div>
                </div>

            ) : (
                <>
                    <NavBar />

                    <Div d="flex" justify="center" align="center" m={{ t: "65px" }}>
                        <Div d="flex" justify="center" align={{ sm: "center" }} flexDir="column"
                            p={{ x: { xs: '1rem', sm: '3.5rem', md: '3.5rem', lg: '13rem', xl: '20rem' }, y: { xs: '3rem', lg: '5rem' } }}
                            //p={{ x: { xs: '1rem', sm: '3.5rem', lg: '27rem' }, y: { xs: '3rem', lg: '5rem' } }}
                            bg="linear-gradient(180deg, #FFCABE 0%, #FEF38D 100%)">

                            <Div d="flex" justify="center" align="center" flexDir="column" m={{ b: { xs: '2.5rem', md: '3.5rem', lg: '4rem' } }}>
                                <Div
                                    w="100%"
                                    m={{ b: { xs: '0.75rem', md: '1rem', lg: '1.6rem' } }} p={{ x: '2rem', y: '0.75rem' }}
                                    d="flex" justify="center" align="center" flexDir="column"
                                    bg="#ffffff" rounded="circle">
                                    <Text textSize={{ sm: " reportHeading2", md: "heading2" }} textWeight="700">
                                        腸毛相 ｜用科學精準調配毛孩每一餐
                                    </Text>
                                </Div>

                                <Div d="flex" flexDir="row" >
                                    <Div d="flex" flexDir="row" onClick={handleCapture}>
                                        <Icon name="Download" size="24px" color="#242424" m={{ r: '0.5rem' }} />
                                        <Text textSize="Body1">下載圖片</Text>
                                    </Div>
                                </Div>
                            </Div>


                            <div id="captureTarget" style={captureStyle} >
                                {/* part 1 */}
                                <Div w="100%">
                                    <Div d="flex" flexDir="row" justify="space-between" align="center">
                                        {/* 寵物資訊 */}
                                        <Div textAlign="start">
                                            {/* <Text textSize={{ xs: "reportHeading4", md: "reportHeading1" }} textWeight="600">
                                                我的名字叫...
                                            </Text> */}
                                            <Text textSize={{ xs: "40px", md: "reportPetName" }} textWeight="900" m={{ y: { xs: "0.5rem", md: '2.8rem' } }} style={textOutlineStyle}>
                                                {Info.pet_name}
                                            </Text>
                                            <Div>
                                                <Text textSize={{ xs: "reportBody3", md: "reportBody1" }} textWeight="400">
                                                    性別：{Info.sex === 'F' ? '可愛の女生' : '帥氣の男生'}
                                                </Text>
                                                <Text textSize={{ xs: "reportBody3", md: "reportBody1" }} textWeight="400">
                                                    歲數：{Info.birth}
                                                </Text>
                                            </Div>


                                            <Div m={{ y: { xs: '0.15rem', sm: '0.3rem', md: '0.4rem' } }}>
                                                {Object.values(TagData).map((tagValue) => (
                                                    <Tag
                                                        bg="#ffffff"
                                                        border="2px solid"
                                                        borderColor="#48222C"
                                                        textColor="#48222C"
                                                        p={{ x: { xs: '0.5rem', sm: "1.25rem", md: "1.5rem" }, y: "0.5rem" }}
                                                        textSize={{ xs: "reportHeading5", md: "reportTag" }}
                                                        textWeight="700"
                                                        rounded="md"
                                                        m={{ r: { xs: "0.3rem", md: '0.75rem' }, t: { xs: '0.3rem' } }}
                                                    >
                                                        # {tagValue}
                                                    </Tag>
                                                ))}
                                            </Div>

                                            <Div m={{ t: { xs: "0.5rem", md: '1rem' } }}>
                                                <Text textSize={{ xs: "17px", md: "reportCompareFont" }} textWeight="700" d="inline-block">
                                                    {/* 約有 */}
                                                    與
                                                    <Text textSize={{ xs: "26px", md: "reportComparePercent" }} textWeight="600" textColor="#48222C" d="inline-block" p={{ x: '0.1rem' }}>
                                                        &thinsp;{similarity.similarity}&thinsp;
                                                    </Text>
                                                    健康{similarity.strain === "D" ? '犬' : '貓'}相似
                                                </Text>
                                            </Div>

                                        </Div>
                                        {/* 圖片 */}
                                        <Div textAlign="end">
                                            <Div textAlign="center">
                                                <Image src={PetProfile} w="45%" />
                                                <Div bg="#FFFFFF" textAlign="center" m={{ l: { xs: '0.5rem', sm: "0rem" } }}
                                                    border="3px solid" borderColor="#48222C" rounded="xl" pos="relative" w={{ xs: "10.5rem", md: "13.5rem" }} h={{ xs: "14rem", md: "18rem" }}>
                                                    <input
                                                        type="file"
                                                        ref={fileInput}
                                                        style={{ display: "none" }}
                                                        onChange={handleProfileChange}
                                                    />
                                                    {/* <Div bgImg={profile.pet_profile} bgSize="cover" h={{xs: '11rem', md: '15rem', lg: '20rem'}} w={{xs: '8rem', md: '11rem', lg: '15rem'}}/> */}
                                                    {isProfileLoading ? (
                                                        <>
                                                            <div className="loader-container" height="50vh">
                                                                <div className="loader"></div>
                                                            </div>
                                                        </>

                                                    ) : (
                                                        <>
                                                            <Image src={profile.pet_profile} w="100%" h="100%" rounded="lg" />
                                                            <Button
                                                                bg="transparent"
                                                                textColor="transparent"
                                                                textSize="12px"
                                                                pos="absolute"
                                                                top="50%"
                                                                left="50%"
                                                                transform="translate(-50%, -50%)"
                                                                flexDir={{ xs: "column", md: "row" }}
                                                                align="center"
                                                                onClick={() => handleButtonClick()}
                                                                hoverBg="#ffffff"
                                                                hoverTextColor="#242424"
                                                                transition="all 0.3s"
                                                                h="auto"
                                                            >
                                                                上傳照片<br />直式為佳
                                                            </Button>
                                                        </>
                                                    )}

                                                </Div>

                                            </Div>
                                        </Div>
                                    </Div>
                                </Div>

                                {/* part 2 */}
                                <Div bg="#ffffff" border="2px solid" borderColor="#48222C" w="100%" rounded="md"
                                    m={{ t: '1.75rem', b: '0.75rem' }} p={{ y: '1rem', x: { xs: '1rem', md: '2rem' } }} >

                                    <Div w="100%">
                                        <Div d="flex" align="center" justify="center">
                                            <Text textSize="reportHeading1" textWeight="800" textColor="#242424">
                                                好壞菌比例
                                            </Text>
                                        </Div>

                                        <Div d="flex" flexDir="row">
                                            {/* 好菌圖 */}
                                            <Div d="flex" align="end" justify="center">
                                                <Image src={GoodBac} w="50px" h="50px" />
                                            </Div>
                                            {/* 比例 */}
                                            <Div d="flex" border="2px solid" borderColor="#48222C" m={{ t: '1rem' }} rounded="md" h="3rem" w="100%">
                                                <Div
                                                    className="ratio-part"
                                                    w="100%"
                                                    d="flex" flexDir="row" align="center"
                                                >
                                                    {/* 好菌比例 */}
                                                    <Div
                                                        className="ratio-part"
                                                        w={goodWidth}
                                                        bg="#85E0A3"
                                                        h="2.8rem"
                                                        d="flex" flexDir="row" justify="end" align="center"
                                                        rounded={{ tl: "md", bl: "md"}}
                                                    >
                                                        <Div d="flex" flexDir="row" align="center">
                                                            <Text textSize="reportComparePercent" textWeight="900"
                                                                d="flex" align="center" p={{ r: "0.5rem" }}>
                                                                {goodNum}
                                                            </Text>
                                                            <Text textSize="reportComparePercent" textWeight="900"
                                                                d="flex" align="center" >:</Text>
                                                        </Div>
                                                    </Div>
                                                    {/* 壞菌比例 */}
                                                    <Div
                                                        className="ratio-part"
                                                        w={badWidth}
                                                        bg="#A6A4A1"
                                                        h="2.8rem"
                                                        d="flex" flexDir="row" justify="space-between" align="center"
                                                        rounded={{ tr: "md", br: "md" }}
                                                    >
                                                        <Text textSize="reportComparePercent" textWeight="900"
                                                            d="flex" align="center" p={{ l: "0.5rem" }}>
                                                            {badNum}
                                                        </Text>
                                                    </Div>
                                                </Div>
                                            </Div>
                                            {/* 壞菌圖 */}
                                            <Div d="flex" align="end" justify="center">
                                                <Image src={BadBac} w="50px" h="50px" />
                                            </Div>
                                        </Div>

                                    </Div>


                                    <Div border="2px dashed" // 设置虚线的样式和颜色
                                        borderColor="#A6A4A1"
                                        w="100%" // 设置虚线的宽度，可以根据需要调整
                                        h="0" // 设置虚线的高度
                                        m={{ y: '1rem' }} // 可选的外边距，根据需要调整
                                    />

                                    <Div w="100%">
                                        <Div d="flex" align="center" justify="center">
                                            <Text textSize="reportHeading1" textWeight="700" textColor="#242424">
                                                餐桌大作戰：推薦食物
                                            </Text>
                                        </Div>

                                        <Div d="flex" flexDir={{ xs: "column", md: "row" }} justify="space-between" align="center">
                                            <Div>
                                                <Pie data={pieData} options={pieOption} />
                                            </Div>

                                            <Div d="flex" align="center" justify="center" flexDir="column" m={{ l: { md: '3rem' } }} w="100%">
                                                {Object.keys(food).map((key, index) => (
                                                    <FoodTag
                                                        key={index}
                                                        number={index + 1}
                                                        label={food[`no${index + 1}`]}
                                                        color={index % 2 === 0 ? '#FFCABE' : '#FEF38D'}
                                                    />
                                                ))}
                                            </Div>
                                        </Div>

                                    </Div>


                                    <Div border="2px dashed" // 设置虚线的样式和颜色
                                        borderColor="#A6A4A1"
                                        w="100%" // 设置虚线的宽度，可以根据需要调整
                                        h="0" // 设置虚线的高度
                                        m={{ b: '1.2rem' }} // 可选的外边距，根据需要调整
                                    />


                                    <Div w="100%">
                                        <Div d="flex" flexDir={{ xs: "column", md: "row" }}>
                                            {/* 左邊 */}
                                            <Div m={{ r: { md: "1rem" } }} d="flex" flexDir="column" flexGrow="1">
                                                <Div
                                                    bg="white"
                                                    rounded="xl"
                                                    m={{ b: "20px" }}
                                                    borderColor="#48222C"
                                                    border="2px solid"
                                                    flexGrow="1"
                                                >
                                                    <Div bg="#FFCABE" d="flex" justify="center" align="center" p={{ y: '0.19rem' }} rounded={{ tl: 'xl', tr: 'xl', br: '0', bl: '0' }}>
                                                        <Text textSize="reportHeading2" textWeight="700">
                                                            主要居民
                                                        </Text>
                                                    </Div>
                                                    <Div p={{ x: "1.5rem", y: '1rem' }} justify="center" textAlign="center" d="flex" align="center" flexDir="column">
                                                        <Image src={rank1.img} w="45%" maxW="200px" m={{ y: '0.5rem' }} />
                                                        <Text textSize="reportHeading2" textColor="#242424" textWeight="700" textAlign="center" m={{ y: '0.5rem' }}>
                                                            {rank1.brief}
                                                        </Text>
                                                        <Text textSize="reportHeading4" textColor="#242424" textWeight="400" textAlign="center" m={{ y: '0.5rem' }}>
                                                            {rank1.species_name_ch ? rank1.species_name_ch : rank1.genus_name_ch}
                                                        </Text>
                                                        <Text textSize="reportBody3" textColor="#242424" textWeight="600" textAlign="center" m={{ y: '0.5rem' }}>
                                                            📍{rank1.description}
                                                        </Text>
                                                    </Div>
                                                </Div>
                                            </Div>

                                            {/* 右邊 */}
                                            <Div d="flex" flexDir="column" flexGrow="1">
                                                <Div
                                                    bg="white"
                                                    rounded="xl"
                                                    borderColor="#48222C"
                                                    border="2px solid"
                                                    m={{ b: "1rem" }}
                                                    flexGrow="1"
                                                >
                                                    <Div bg="#85E0A3" d="flex" justify="center" align="center" p={{ y: '0.19rem' }} rounded={{ tl: 'xl', tr: 'xl', br: '0', bl: '0' }}>
                                                        <Text textSize="reportHeading2" textWeight="700">
                                                            好菌們
                                                        </Text>
                                                    </Div>
                                                    <Div d="flex" justify="center" flexDir="row" align="center" h="80%">
                                                        <Div p={{ x: "1rem", y: '1rem' }}
                                                            d="flex" justify="center" flexDir="row" align="center">
                                                            {Object.values(goodBac).map((good_bac) => (
                                                                <Div d="flex" flexDir="column" justify="center" align="center">
                                                                    <Image src={good_bac.img_src} w="60%" maxW="200px" />
                                                                    <Text textSize="reportHeading3" textColor="#242424" textWeight="700" textAlign="center">
                                                                        {good_bac.brief}
                                                                    </Text>
                                                                    <Text textSize="reportHeading4" textColor="#242424" textWeight="400" textAlign="center">
                                                                        {good_bac.species_name_ch ? good_bac.species_name_ch : good_bac.genus_name_ch}
                                                                    </Text>
                                                                </Div>
                                                            ))}
                                                        </Div>
                                                    </Div>

                                                </Div>
                                                <Div
                                                    bg="white"
                                                    rounded="xl"
                                                    m={{ b: '20px' }}
                                                    borderColor="#48222C"
                                                    border="2px solid"
                                                    flexGrow="1"
                                                >
                                                    <Div bg="#A6A4A1" d="flex" justify="center" p={{ y: '0.19rem' }} rounded={{ tl: 'xl', tr: 'xl', br: '0', bl: '0' }}>
                                                        <Text textSize="reportHeading2" textWeight="700">
                                                            壞菌們
                                                        </Text>
                                                    </Div>
                                                    <Div d="flex" justify="center" flexDir="row" align="center" h="80%">
                                                        <Div p={{ x: "1rem", y: '1rem' }}
                                                            d="flex" justify="center" flexDir="row" align="center">
                                                            {Object.values(badBac).map((bad_bac) => (
                                                                <Div d="flex" flexDir="column" justify="center" align="center">
                                                                    <Image src={bad_bac.img_src} w="60%" maxW="200px" />
                                                                    <Text textSize="reportHeading3" textColor="#242424" textWeight="700" textAlign="center">
                                                                        {bad_bac.brief}
                                                                    </Text>
                                                                    <Text textSize="reportHeading4" textColor="#242424" textWeight="400" textAlign="center">
                                                                        {bad_bac.species_name_ch ? bad_bac.species_name_ch : bad_bac.genus_name_ch}
                                                                    </Text>
                                                                </Div>
                                                            ))}
                                                        </Div>
                                                    </Div>

                                                </Div>
                                            </Div>
                                        </Div>
                                    </Div>

                                    {isCommentVisible && <div>
                                        <Div border="2px dashed" // 设置虚线的样式和颜色
                                            borderColor="#A6A4A1"
                                            w="100%" // 设置虚线的宽度，可以根据需要调整
                                            h="0" // 设置虚线的高度
                                            m={{ b: '1rem' }} // 可选的外边距，根据需要调整
                                        />

                                        <Div w="100%">
                                            <Div d="flex" align="center" justify="center">
                                                <Text textSize="reportHeading1" textWeight="700" textColor="#242424">
                                                    報告總評
                                                </Text>
                                            </Div>
                                            <Div d="flex" align="center" justify="center" m={{ t: '1rem' }}>
                                                <Text textSize="reportBody2" textWeight="400" textColor="#242424">
                                                    {comment.commentDescription}
                                                </Text>
                                            </Div>
                                        </Div>
                                    </div>}
                                </Div>
                                {isSloganVisible && <div>
                                    <Div d="flex" align="center" justify="center" flexDir="row">
                                        <Text textSize="reportHeading1" textWeight="700" textColor="#79384A">
                                            快來測毛孩腸道居民！
                                        </Text>
                                        <Text textSize="reportHeading2" textWeight="700" textColor="#242424">
                                            @petsci_tw IG 帳號一探究竟
                                        </Text>
                                    </Div>
                                </div>}

                            </div>

                            <Div d="flex" justify="center" align="center" flexDir="column" m={{ t: "3.5rem" }}>
                                <Div m={{ b: "1rem" }}>
                                    <a href={detailReport} target="_blank" onClick={(e) => handleClickDetail(e)}>
                                        <Btn text="&emsp;&emsp;查看更多的報告內容&emsp;&emsp;" />
                                    </a>
                                </Div>

                                <Text textSize="body1" m={{ t: "0.5rem" }}>
                                    有任何問題，可洽科學毛怪 IG 或 E-mail
                                </Text>
                                <Div>
                                    <a href="https://www.instagram.com/petsci_tw/" target="_blank">
                                        <Icon name="Instagram" size="24px" color="#EB4C27" />
                                    </a>
                                    <a href="mailto:petsci.service@gmail.com">
                                        <Icon name="Email" size="24px" color="#EB4C27" m={{ l: "24px" }} />
                                    </a>
                                </Div>
                            </Div>

                        </Div>
                    </Div>
                </>
            )}

        </>
    )
}

export default ReportsEasy