import React, { useState } from "react";
import NavBar from "./components/NavBar";
import { Div, Text } from "atomize";
import EditPetInfo from "./EditPetInfo";
import EditCustomerInfo from "./EditCustomerInfo";

const EditInfo = () => {
  //控制tab的點擊
  const [selectedInfo, setSelectedInfo] = useState("pet");
  const handleTabClick = (infoType) => {
    setSelectedInfo(infoType);
  };

  return (
    <>
      <NavBar />
      <Div
        m={{ t: { xs: "3.5rem", md: "5rem", lg: "5rem" } }}
        d="flex"
        justify="center"
        align="center"
      >
        {/* <Text
          textSize={{ xs: "heading2", lg: "heading1" }}
          textWeight="600"
          textColor="neutral900"
        >
          {selectedInfo === "pet" ? "修改寵物資訊" : "修改飼主資訊"}
        </Text> */}
      </Div>

      <Div d="flex" justify="center" align="center">
        <Div
          m={{
            t: { xs: "0.7rem", md: "1rem", lg: "1.2rem" },
            b: "1rem",
          }}
        >
          {/* tab */}
          <Div
            id="tab"
            m={{
              b: "1rem",
            }}
            d="flex"
            flexDir="row"
          >
            <Div
              style={{
                borderBottom:
                  selectedInfo === "pet" ? "3px solid #F3B23E" : "none",
              }}
              p={{ x: "0.5rem", b: "0.5rem" }}
              onClick={() => handleTabClick("pet")}
            >
              <Text
                textSize="header"
                textColor={selectedInfo === "pet" ? "neutral900" : "neutral600"}
                textWeight="600"
                cursor="pointer"
              >
                寵物資訊
              </Text>
            </Div>
            <Div
              style={{
                borderBottom:
                  selectedInfo === "customer" ? "3px solid #F3B23E" : "none",
              }}
              p={{ x: "0.5rem", b: "0.5rem" }}
              m={{ l: "1.5rem" }}
              onClick={() => handleTabClick("customer")}
            >
              <Text
                textSize="header"
                textColor={
                  selectedInfo === "customer" ? "neutral900" : "neutral600"
                }
                textWeight="600"
                cursor="pointer"
              >
                飼主資訊
              </Text>
            </Div>
          </Div>

          <Div w={{ xs: "350px", md: "450px", lg: "530px" }}>
            {selectedInfo === "pet" ? <EditPetInfo /> : <EditCustomerInfo />}
          </Div>
        </Div>
      </Div>
    </>
  );
};

export default EditInfo;
