import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const LineLoginRedirect = () => {
  //從當前的url中取得動態參數
  //const { code } = useParams();
  //alert(code);
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const code = query.get("code");

  const apiurl = `${process.env.REACT_APP_API_URL}/users/login/line-token/`;

  useEffect(() => {
    if (code) {
      fetch(apiurl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: code,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          localStorage.setItem("access", data.access);
          localStorage.setItem("refresh", data.refresh);

          // 獲取重定向，否則就到/petlist頁面
          const redirectPath =
            localStorage.getItem("redirectPath") || "/petlist";
          localStorage.removeItem("redirectPath");

          navigate(redirectPath, { replace: true });

        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [code, navigate]);

  return <div>重新導向中</div>;
};

export default LineLoginRedirect;
