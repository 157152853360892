import {
  Button,
  Col,
  Div,
  Icon,
  Image,
  Input,
  Notification,
  Row,
  Text,
} from "atomize";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  ConfigProvider,
  Divider,
  Select,
  DatePicker,
  Radio,
  AutoComplete,
} from "antd";
import Logo from "../src/assets/images/PetSci_Logo.png";
import InputField from "./components/InputField";
import Btn from "./components/Btn";
import useDocumentTitle from "./components/useDocumentTitle";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import InputTextarea from "./components/InputTextarea";

const Info = () => {
  useDocumentTitle("填寫資訊 - PetSci");

  useEffect(() => {
    fetchCustomerInfo();
    fetchPetInfo();
  }, []);

  const customerInfoApiUrl = `${process.env.REACT_APP_API_URL}/users/register/owner-info/`;
  const petInfoApiUrl = `${process.env.REACT_APP_API_URL}/users/register/pet-info/`;

  const fetchCustomerInfo = useCallback(() => {
    const storedAccess = localStorage.getItem("access");

    fetch(customerInfoApiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedAccess}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          //alert("access 過期");
          refreshRequest(fetchCustomerInfo);
          throw new Error("Unauthorized"); //使不執行.then(data)，直接跳到.catch()
        } else {
          return response.json();
        }
      })
      .then((data) => {
        //console.log("data", data);
        setCustomerInfo({
          customerName: data.customer_info.customer_name,
          phoneNumber: data.customer_info.phone_number,
          email: data.customer_info.email,
          gender: data.customer_info.gender,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  });

  const fetchPetInfo = useCallback(() => {
    const storedAccess = localStorage.getItem("access");

    fetch(petInfoApiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedAccess}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          //alert("access 過期");
          refreshRequest(fetchPetInfo);
          throw new Error("Unauthorized"); //使不執行.then(data)，直接跳到.catch()
        } else {
          return response.json();
        }
      })
      .then((data) => {
        //console.log("selectedPetdata", data);
        setSelectedPetInfo(data);
        setNameOptions(data.map((item) => ({ value: item.pet_name })));
        // console.log(
        //   "petdata",
        //   data.map((item) => item.pet_name)
        // );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  });

  const [isPhoneValid, setIsPhoneValid] = useState("default");
  const [isEmailValid, setIsEmailValid] = useState("default");

  //個人資料
  const [customerInfo, setCustomerInfo] = useState({
    customerName: "",
    phoneNumber: "",
    email: "",
    gender: "",
    // verifyCode: "",
  });
  const handleCustomerChange = (e) => {
    const { name, value } = e.target; //解構賦值，事件中提取name和value

    if (name === "phoneNumber") {
      const phoneRegex = /^(0|\+?[1-9][0-9]{1,4})[0-9]{6,10}$/; // 修改为适合你需求的正则表达式
      if (phoneRegex.test(value)) {
        setIsPhoneValid("correct");
      } else {
        setIsPhoneValid("error");
      }
    } else if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(value)) {
        setIsEmailValid("correct");
      } else {
        setIsEmailValid("error");
      }
    }

    setCustomerInfo((prevInfo) => ({
      ...prevInfo, //保留先前的值
      [name]: value, //動態設置值，name包含name, phoneNumber, Value
    }));

    // console.log(customerInfo);
  };
  //驗證信箱
  const [emailVerify, setEmailVerify] = useState(false);
  const [verifyClick, setVerifyClick] = useState(false);
  const [timeLeft, setTimeLeft] = useState(180);

  useEffect(() => {
    if (verifyClick) {
      const intervalId = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime === 1) {
            clearInterval(intervalId);
            setVerifyClick(false);
            return 180;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [verifyClick]);

  const emailSendApiUrl = `${process.env.REACT_APP_API_URL}/users/register/email-verify-sending/`;

  const emailSend = useCallback(() => {
    setVerifyClick(true);
    const storedAccess = localStorage.getItem("access");

    fetch(emailSendApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedAccess}`,
      },
      body: JSON.stringify({
        client_email: customerInfo.email,
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          // alert("access 過期");
          refreshRequest(emailSend);
        } else {
          return response.json();
        }
      })
      // .then((data) => {
      //   console.log(data);
      // })
      .catch((error) => {
        console.error("Error:", error);
      });
  });
  //確認驗證碼
  const [verifyCode, setVerifyCode] = useState("");
  const handleVerifyCode = (value) => {
    // console.log(verifyCode);
    setVerifyCode(value);
  };
  const [verifyCheck, setVerifyCheck] = useState();

  const verifyCodeApiUrl = `${process.env.REACT_APP_API_URL}/users/register/email-verify-checking/`;
  const verifyEmail = () => {
    const storedAccess = localStorage.getItem("access");
    fetch(verifyCodeApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedAccess}`,
      },
      body: JSON.stringify({
        client_email: customerInfo.email,
        verification_code: verifyCode,
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          // alert("access 過期");
          refreshRequest(verifyEmail);
        } else if (response.status === 400) {
          setVerifyCheck(false);
        } else if (response.status === 200) {
          setEmailVerify(true);
          setVerifyCheck(true);
        } else {
          return response.json();
        }
      })
      // .then((data) => {
      //   console.log(data);
      // })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    if (verifyCode.length === 6) {
      verifyEmail();
    }
  }, [verifyCode]);

  //採檢管數量選擇，決定下面寵物資訊呈現幾個陣列，初始化為空
  const handleSelect = (value) => {
    setPetInfo(
      Array.from({ length: value }, () => ({
        pet_name: "",
        species: "",
        strain: "",
        sex: "",
        birth: null,
        pet_img_url: "https://imgur.com/UBeHe4G.jpg",
        collect_tube: {
          serial_number: "",
        },
        note: "",
      }))
    );
  };

  const [petInfo, setPetInfo] = useState([
    {
      pet_name: "",
      species: "",
      strain: "",
      sex: "",
      birth: null,
      pet_img_url: "https://imgur.com/UBeHe4G.jpg",
      collect_tube: {
        serial_number: "",
      },
      note: "",
    },
  ]);

  const renderPetInfoDivs = () => {
    return petInfo.map((info, index) => (
      <Div key={index} m={{ y: "1.5rem" }}>
        <Div
          d="flex"
          flexDir="row"
          m={{ t: "3.5rem" }}
          justify="start"
          align="center"
        >
          <Div
            bg="#F3B23E"
            p={{ x: "0.75rem", y: "0.75rem" }}
            rounded="0.75rem"
            w="7.5rem"
            d="flex"
            flexDir="row"
            justify="center"
          >
            <Text
              textSize="heading3"
              textWeight="600"
              textColor="#ffffff"
              textAlign="center"
            >
              毛孩資訊
            </Text>
          </Div>

          <Div m={{ l: "1rem" }}>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#F3B23E",
                  fontSize: 16,
                  controlInteractiveSize: 24,
                },
              }}
            >
              <Radio.Group
                onChange={(e) =>
                  handlePetChange(index, "species", e.target.value)
                }
                value={info.species}
                name={`species-${index}`}
              >
                <Radio value="D">狗</Radio>
                <Radio value="C">貓</Radio>
              </Radio.Group>
            </ConfigProvider>
          </Div>
        </Div>

        <Div
          d="flex"
          flexDir={{ xs: "column", md: "row" }}
          m={{ y: "1.5rem" }}
          w="100%"
        >
          <Div w="100%">
            <InputField
              label="採檢管編號"
              type="text"
              name={`tubeVersion-${index}`}
              value={info.collect_tube.serial_number || ""}
              onChange={(e) => handleTubeVersion(index, e.target.value)}
              maxLength={8}
            />

            {isSerialNumber ? (
              <Div d="flex" align="center" m={{ t: "0.25rem" }}></Div>
            ) : (
              <Div d="flex" align="center" m={{ t: "0.25rem" }}>
                <Icon
                  name="AlertSolid"
                  size="14px"
                  color="#555555"
                  m={{ r: "0.25rem" }}
                />
                <Text textSize="paragraph" textColor="#555555" textWeight="600">
                  採檢管編號錯誤
                </Text>
              </Div>
            )}
          </Div>
          <Div
            m={{
              l: { xs: "0rem", md: "1.5rem" },
              t: { xs: "1.5rem", md: "0rem" },
            }}
            w="100%"
          >
            <Text textSize="paragraph" textColor="#79384A" textWeight="600">
              毛孩姓名
            </Text>

            <Div name="name">
              <ConfigProvider
                theme={{
                  components: {
                    Select: {
                      hoverBorderColor: "#DFD9FC",
                      activeBorderColor: "#DFD9FC",
                      cellRangeBorderColor: "#DFD9FC",
                      controlOutline: "#E2DCFF",
                      colorPrimary: "#F3B23E",
                      colorPrimaryHover: "#F3B23E",
                      borderRadius: 8,
                      optionActiveBg: "#A6A4A1",
                      optionSelectedBg: "#A6A4A14D",
                      colorBorder: "#DFD9FC",
                      optionFontSize: 18,
                      fontSize: 18,
                    },
                  },
                }}
              >
                <AutoComplete
                  style={{ width: "100%", height: "40px", fontSize: "18px" }}
                  name={`pet_name-${index}`}
                  options={nameOptions}
                  placeholder="請選擇"
                  value={info.pet_name} // 添加這行來控制輸入值
                  onChange={(value) =>
                    handlePetChange(index, "pet_name", value)
                  }
                  onSelect={(value) => handlePetSelect(index, value)}
                  maxLength={10}
                  filterOption={(inputValue, option) =>
                    option?.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              </ConfigProvider>
            </Div>
          </Div>
        </Div>

        <Div
          d="flex"
          flexDir={{ xs: "column", md: "row" }}
          m={{ y: "1.5rem" }}
          w="100%"
        >
          <Div w="100%">
            <InputField
              label="毛孩品種"
              type="text"
              name={`strain-${index}`}
              value={info.strain}
              onChange={(e) => handlePetChange(index, "strain", e.target.value)}
              maxLength={10}
            />
          </Div>
          <Div
            m={{
              l: { xs: "0rem", md: "1.5rem" },
              t: { xs: "1.5rem", md: "0rem" },
            }}
            w="100%"
          >
            <Text textSize="paragraph" textColor="#79384A" textWeight="600">
              毛孩生日
            </Text>

            <Div name="birth">
              <ConfigProvider
                theme={{
                  components: {
                    DatePicker: {
                      hoverBorderColor: "#DFD9FC",
                      activeBorderColor: "#DFD9FC",
                      cellRangeBorderColor: "#DFD9FC",
                      controlOutline: "#E2DCFF",
                      colorPrimary: "#F3B23E",
                      colorPrimaryHover: "#F3B23E",
                      borderRadius: 8,
                      optionActiveBg: "#A6A4A1",
                      optionSelectedBg: "#A6A4A14D",
                      colorBorder: "#DFD9FC",
                      fontSize: 18,
                    },
                  },
                }}
              >
                <DatePicker
                  value={info.birth ? dayjs(info.birth) : null}
                  format={dateFormat}
                  style={{ width: "100%", height: "40px" }}
                  onChange={(date, dateString) =>
                    handleDateChange(index, date, dateString)
                  }
                />
              </ConfigProvider>
            </Div>
          </Div>
        </Div>

        <Div
          d="flex"
          flexDir={{ xs: "column", md: "row" }}
          m={{ y: "1.5rem" }}
          w="100%"
        >
          <Div w="100%">
            <Text textSize="paragraph" textColor="#79384A" textWeight="600">
              毛孩性別
            </Text>

            <Div>
              <Div name="sex">
                <ConfigProvider
                  theme={{
                    components: {
                      Select: {
                        colorBorder: "rgb(201, 206, 214)",
                        colorPrimary: "#DFD9FC",
                        controlOutline: "#E2DCFF",
                        colorPrimaryHover: "rgb(201, 206, 214)",
                        borderRadius: 8,
                        optionActiveBg: "#A6A4A1",
                        optionSelectedBg: "#A6A4A14D",
                        fontSize: 18,
                      },
                    },
                  }}
                >
                  <Select
                    value={info.sex}
                    style={{ width: "100%", height: "40px" }}
                    placeholder="尚未選擇"
                    options={[
                      {
                        value: "M",
                        label: "公",
                      },
                      {
                        value: "F",
                        label: "母",
                      },
                    ]}
                    onChange={(value) => handleSexSelect(index, value)}
                  />
                </ConfigProvider>
              </Div>
            </Div>
          </Div>
          <Div
            m={{
              l: { xs: "0rem", md: "1.5rem" },
              t: { xs: "1.5rem", md: "0rem" },
            }}
            w="100%"
          >
            <InputField
              label="購買版本"
              placeholder="新手入門版"
              value={buyingVersion}
              disabled
              cursor="not-allowed"
            />
          </Div>
        </Div>

        <Div m={{ b: "1.5rem" }}>
          <InputTextarea
            label="備註"
            placeholder="可填寫以下內容，供分析人員參考：疾病、重大手術 (結紮免填)、過敏食物、是否餵食保健品與益生菌，若以上都沒有，請填「無」。"
            name={`note-${index}`}
            value={info.note}
            onChange={(e) => handlePetChange(index, "note", e.target.value)}
            maxLength={100}
          />
        </Div>

        <Div
          w="160px"
          d="flex"
          flexDir="column"
          justify="center"
          align="center"
        >
          <Div>
            {info.isLoading ? (
              <>
                <Div d="flex" justify="center" align="center" h="160px">
                  <Div className="loader" />
                </Div>
              </>
            ) : (
              <Image src={info.pet_img_url} alt={`Profile ${index + 1}`} />
            )}
          </Div>
          <Div m={{ t: "1rem" }}>
            <Button
              bg="#ffffff"
              onClick={
                info.pet_img_url === "https://imgur.com/UBeHe4G.jpg"
                  ? () => handleProfileClick(index)
                  : undefined
              }
            >
              {/* 上傳檔案，隱藏<input type:file>觸發點集事件 */}
              <input
                type="file"
                ref={(el) => (fileInput.current[index] = el)}
                style={{ display: "none" }}
                onChange={(e) => handleProfileChange(index, e.target.files[0])}
                accept="image/*"
              />
              {info.pet_img_url === "https://imgur.com/UBeHe4G.jpg" && (
                <Text
                  textColor="neutral800"
                  textSize="subheading1"
                  textWeight="600"
                >
                  點擊上傳照片
                </Text>
              )}
              {info.isProfileUpload && (
                <Text
                  textColor="neutral800"
                  textSize="subheading1"
                  textWeight="600"
                >
                  上傳完成!
                </Text>
              )}
            </Button>
          </Div>
        </Div>
      </Div>
    ));
  };

  //採檢管編號
  const [tubeVersion, setTubeVersion] = useState("");
  const [isSerialNumber, setIsSerialNumber] = useState(true);
  const handleTubeVersion = (index, value) => {
    setTubeVersion(value); //取得採檢管編號打api

    //不同寵物要有不同採檢管編號
    const newPetInfo = [...petInfo];
    newPetInfo[index].collect_tube.serial_number = value;
    setPetInfo(newPetInfo);
  };
  //採檢管編號跳出對應購買版本
  const [buyingVersion, setBuyingVersion] = useState("");

  const tubeVersionApiUrl = `${process.env.REACT_APP_API_URL}/users/register/collect-tube-version/?serial_number=${tubeVersion}`;
  const serialNumber = () => {
    const storedAccess = localStorage.getItem("access");

    fetch(tubeVersionApiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedAccess}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setIsSerialNumber(true);
          return response.json();
        } else if (response.status === 401) {
          // alert("access 過期");
          refreshRequest(serialNumber);
        } else if (response.status === 400) {
          setIsSerialNumber(false);
        }
      })
      .then((data) => {
        //console.log("版本 " + data.version);
        setBuyingVersion(data.version);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    if (tubeVersion.length === 8) {
      serialNumber();
    }
  }, [tubeVersion]);

  //資料庫已有該飼主的寵物資訊
  const [selectedPetInfo, setSelectedPetInfo] = useState([
    {
      pet_name: "",
      species: "",
      strain: "",
      sex: "",
      birth: null,
      pet_img_url: "https://imgur.com/UBeHe4G.jpg",
      note: "",
    },
  ]);
  //顯示該飼主的所有寵物姓名選項
  const [nameOptions, setNameOptions] = useState();
  // console.log("option " + JSON.stringify(nameOptions));

  // 根據選擇的寵物姓名查找對應的寵物資料
  const handlePetSelect = (index, value) => {
    // console.log("毛孩姓名 " + value);
    // console.log("selectedPetInfo " + JSON.stringify(selectedPetInfo));

    //如果有找到選擇的值，吐出對應的該物件，並更新至petInfo
    const selectedPet = selectedPetInfo.find((pet) => pet.pet_name === value);
    // console.log("確保value==毛孩姓名 " + JSON.stringify(selectedPet));

    if (selectedPet) {
      // 更新其他相關欄位
      const newPetInfo = [...petInfo];
      newPetInfo[index] = {
        ...newPetInfo[index],
        pet_name: selectedPet.pet_name,
        species: selectedPet.species,
        strain: selectedPet.strain,
        birth: selectedPet.birth,
        sex: selectedPet.sex,
        pet_img_url: selectedPet.pet_img_url,
        note: selectedPet.note,
      };
      setPetInfo(newPetInfo);
    }
  };

  // console.log("選擇毛孩姓名之後的petInfo" + JSON.stringify(petInfo));

  //當值有變化時取值，放進petInfo的陣列中
  const handlePetChange = (index, field, value) => {
    const newPetInfo = [...petInfo];
    newPetInfo[index][field] = value;
    setPetInfo(newPetInfo);
  };

  //取date值，放進petInfo陣列中
  const dateFormat = "YYYY-MM-DD";
  const handleDateChange = (index, date, dateString) => {
    const newPetInfo = [...petInfo];
    newPetInfo[index].birth = dateString;
    setPetInfo(newPetInfo);
  };
  //取sex值，放進petInfo陣列中
  const handleSexSelect = (index, value) => {
    const newPetInfo = [...petInfo];
    newPetInfo[index].sex = value;
    setPetInfo(newPetInfo);
  };

  //上傳檔案，隱藏<input type:file>觸發點擊事件
  const profileApiUrl = `${process.env.REACT_APP_API_URL}/users/register/pet-picture-upload/`;
  const fileInput = useRef([]);
  const handleProfileClick = (index) => {
    if (fileInput.current[index]) {
      fileInput.current[index].click();
    }
  };

  //紀錄是否為範例圖片檔案
  //const [isExProfile, setIsExProfile] = useState(true);
  const handleProfileChange = useCallback((index, file) => {
    const uploadImage = file;
    const formData = new FormData();
    formData.append("image", uploadImage);

    if (uploadImage) {
      // setIsExProfile(false);

      const newPetInfo = [...petInfo];
      newPetInfo[index].isLoading = true;
      newPetInfo[index].isProfileUpload = false;
      setPetInfo(newPetInfo);

      const storedAccess = localStorage.getItem("access");

      fetch(profileApiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${storedAccess}`,
        },
        body: formData,
      })
        .then((response) => {
          if (response.status === 401) {
            // alert("access 過期");
            refreshRequest(() => handleProfileChange(index, file));
          } else if (response.status === 402) {
            alert("檔案格式錯誤，請重新上傳。");
          } else {
            return response.json();
          }
        })
        .then((data) => {
          //console.log("photoUpload " + data.url);
          //不同寵物要有不同圖片和loading
          const newPetInfo = [...petInfo];
          // newPetInfo[index].pet_img_url = data.url;
          newPetInfo[index].pet_img_url = data.url;
          newPetInfo[index].isLoading = false;
          newPetInfo[index].isProfileUpload = true;
          setPetInfo(newPetInfo);
          // setIsExProfile(false);
        });
    }
  });

  //檢查欄位必填；未必填給提示
  const [isFormValid, setIsFormValid] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  //field.trim()去掉字串前後的空白字符，用field.trim() !== ""檢查是否為空白
  const validateForm = () => {
    const isCustomerInfoComplete = Object.values(customerInfo).every(
      (field) =>
        String(field).trim() !== "" && field !== null && field !== undefined
    );

    const isPetInfoComplete = petInfo.every((pet) =>
      Object.values(pet).every(
        (field) =>
          String(field).trim() !== "" && field !== null && field !== undefined
      )
    );

    return isCustomerInfoComplete && isPetInfoComplete;
  };

  useEffect(() => {
    setIsFormValid(validateForm());
  }, [customerInfo, petInfo]);

  const registerApiUrl = `${process.env.REACT_APP_API_URL}/users/register/`;
  const handleSubmit = useCallback(() => {
    // console.log("test " + validateForm());
    // console.log("customerInfo:", JSON.stringify(customerInfo, null, "\t"));
    // console.log("petInfo:", JSON.stringify(petInfo, null, "\t"));

    const storedAccess = localStorage.getItem("access");

    if (isFormValid) {
      fetch(registerApiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedAccess}`,
        },
        body: JSON.stringify({
          customer_info: {
            customer_name: customerInfo.customerName,
            phone_number: customerInfo.phoneNumber,
            email: customerInfo.email,
            gender: customerInfo.gender,
          },
          pet_info: petInfo.map((info) => ({
            pet_name: info.pet_name,
            species: info.species,
            strain: info.strain,
            sex: info.sex,
            birth: info.birth,
            pet_img_url: info.pet_img_url,
            collect_tube: {
              serial_number: info.collect_tube.serial_number,
            },
            note: info.note,
          })),
        }),
      })
        .then((response) => {
          if (response.status === 401) {
            // alert("access 過期");
            refreshRequest(handleSubmit);
          } else if (response.status === 400) {
            setShowErrorNotification(true);
            // 设置定时器以在几秒后隐藏通知
            setTimeout(() => {
              setShowErrorNotification(false);
            }, 3000); // 3000 毫秒后关闭通知
          } else if (response.status === 200) {
            setShowNotification(true);
            // 设置定时器以在几秒后隐藏通知
            setTimeout(() => {
              setShowNotification(false);
            }, 3000); // 3000 毫秒后关闭通知

            navigate("/petlist");
          } else {
            return response.json();
          }
        })
        .then((data) => {
          const newAccess = data.access;
          localStorage.setItem("access", newAccess);
          // alert(data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setShowMessage(true);
    }
  });
  //送出成功或失敗的alert訊息
  const [showNotification, setShowNotification] = useState(false);
  const [showErrorNotification, setShowErrorNotification] = useState(false);

  const navigate = useNavigate();
  const refreshApiUrl = `${process.env.REACT_APP_API_URL}/users/login/token-refresh/`;
  const refreshRequest = useCallback((callback) => {
    const storedRefresh = localStorage.getItem("refresh");

    fetch(refreshApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refresh: storedRefresh,
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          // alert("refresh");
          localStorage.removeItem("access");
          localStorage.removeItem("refresh");
          navigate("/login");
        } else if (response.status === 400) {
          navigate("/login");
        } else {
          return response.json();
        }
      })
      .then((data) => {
        const newAccess = data.access;
        localStorage.setItem("access", newAccess);
        if (typeof callback === "function") {
          callback(); // 调用传入的回调函数
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        navigate("/login");
      });
  });

  return (
    <>
      {/* 空白nav bar */}
      <Div d="flex" align="center" pos="relative" zIndex="99" id="navbar">
        <Row>
          <Col size={{ xs: 12, lg: 12 }}>
            <Div
              d="flex"
              flexDir="column"
              pos="fixed"
              bg="#ffffff"
              w="100%"
              p={{
                y: "1rem",
                x: {
                  xs: "1rem",
                  md: "3.5rem",
                  lg: "4.5rem",
                },
              }}
            >
              <Div d="flex" flexDir="row" justify="space-between">
                <Div
                  d="flex"
                  align="center"
                  onClick={() => window.open("https://petsci.tw/", "_blank")}
                >
                  <Image src={Logo} w="7.5rem" />
                </Div>
              </Div>
            </Div>
          </Col>
        </Row>
      </Div>

      <Div
        m={{
          t: { xs: "5.5rem", md: "6.5rem", lg: "7rem" },
          b: { xs: "2rem", md: "4rem", lg: "5rem" },
        }}
        d="flex"
        justify="center"
        align="center"
      >
        <Div
          w={{ xs: "22rem", md: "33rem", lg: "43rem" }}
          rounded="12px"
          bg="#FFFFFF"
          shadow="3"
          p={{ x: { xs: "1.5rem", md: "5rem", lg: "6rem" }, y: "2.5rem" }}
        >
          <Div
            d="flex"
            justify="center"
            align="center"
            flexDir="column"
            m={{ b: "2rem" }}
          >
            <Text textSize="heading2" textWeight="600">
              填寫資訊
            </Text>
            <Divider />
          </Div>

          <Div
            d="flex"
            flexDir={{ xs: "column", md: "row" }}
            justify="start"
            align={{ xs: "start", md: "center" }}
          >
            <Div
              bg="#F3B23E"
              p={{ x: "0.75rem", y: "0.75rem" }}
              rounded="0.75rem"
              w="7.5rem"
              d="flex"
              flexDir="row"
              justify="center"
            >
              <Text
                textSize="heading3"
                textWeight="600"
                textColor="#ffffff"
                textAlign="center"
              >
                購買資訊
              </Text>
            </Div>

            <Div
              m={{
                l: { xs: "0rem", md: "1rem" },
                t: { xs: "1.5rem", md: "0rem" },
              }}
            >
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#F3B23E",
                    fontSize: 16,
                    controlInteractiveSize: 24,
                  },
                }}
              >
                <Radio.Group
                  onChange={handleCustomerChange}
                  name="gender"
                  value={customerInfo.gender}
                >
                  <Radio value="M">生理男</Radio>
                  <Radio value="F">生理女</Radio>
                </Radio.Group>
              </ConfigProvider>
            </Div>
          </Div>

          <Div
            d="flex"
            flexDir={{ xs: "column", md: "row" }}
            m={{ y: "1.5rem" }}
          >
            <Div>
              <InputField
                label="姓名"
                type="text"
                name="customerName" //name屬性標示輸入字段
                value={customerInfo.customerName}
                onChange={handleCustomerChange}
                maxLength={10}
              />
            </Div>

            <Div
              m={{
                l: { xs: "0rem", md: "1.5rem" },
                t: { xs: "1.5rem", md: "0rem" },
              }}
            >
              <InputField
                label="手機"
                type="tel"
                name="phoneNumber"
                value={customerInfo.phoneNumber}
                onChange={handleCustomerChange}
              />
              {isPhoneValid === "error" && (
                <Div d="flex" align="center" m={{ t: "0.25rem" }}>
                  <Icon
                    name="AlertSolid"
                    size="14px"
                    color="#555555"
                    m={{ r: "0.25rem" }}
                  />
                  <Text
                    textSize="paragraph"
                    textColor="#555555"
                    textWeight="600"
                  >
                    格式錯誤
                  </Text>
                </Div>
              )}
            </Div>
          </Div>

          <Div
            d="flex"
            flexDir={{ xs: "column", md: "row" }}
            m={{ y: "1.5rem" }}
            justify="flex-start"
            align={{ xs: "flex-start", md: "center" }}
          >
            <Div w={{ xs: "100%", md: "47%" }}>
              <Text textSize="paragraph" textColor="#79384A" textWeight="600">
                信箱
              </Text>
              <Input
                textSize="18px"
                type="email"
                name="email"
                value={customerInfo.email}
                onChange={handleCustomerChange}
                disabled={emailVerify}
                cursor={emailVerify ? "not-allowed" : "pointer"}
              />
              {emailVerify ? (
                <Div d="flex" align="center" m={{ t: "0.25rem" }}>
                  <Icon
                    name="Checked"
                    size="14px"
                    color="#0BAA60"
                    m={{ r: "0.25rem" }}
                  />
                  <Text
                    textSize="paragraph"
                    textColor="#0BAA60"
                    textWeight="600"
                  >
                    信箱驗證完成
                  </Text>
                </Div>
              ) : verifyClick ? (
                <Div d="flex" align="center" m={{ t: "0.25rem" }}>
                  <Icon
                    name="AlertSolid"
                    size="14px"
                    color="#555555"
                    m={{ r: "0.25rem" }}
                  />
                  <Text
                    textSize="paragraph"
                    textColor="#555555"
                    textWeight="600"
                  >
                    請至信箱收取驗證碼
                  </Text>
                </Div>
              ) : (
                <Div d="flex" align="center" m={{ t: "0.25rem" }}>
                  <Icon
                    name="AlertSolid"
                    size="14px"
                    color="#555555"
                    m={{ r: "0.25rem" }}
                  />
                  <Text
                    textSize="paragraph"
                    textColor="#555555"
                    textWeight="600"
                  >
                    尚未驗證
                  </Text>
                </Div>
              )}
              {isEmailValid === "error" && (
                <Div d="flex" align="center" m={{ t: "0.25rem" }}>
                  <Icon
                    name="AlertSolid"
                    size="14px"
                    color="#555555"
                    m={{ r: "0.25rem" }}
                  />
                  <Text
                    textSize="paragraph"
                    textColor="#555555"
                    textWeight="600"
                  >
                    格式錯誤
                  </Text>
                </Div>
              )}
            </Div>

            <Div
              m={{
                l: { xs: "0rem", md: "1.5rem" },
                t: { xs: "1.5rem", md: "0rem" },
              }}
              d="flex"
              justify="center"
              align="center"
            >
              <Div w="120px">
                <Btn
                  text="驗證"
                  onClick={() => emailSend()}
                  disabled={
                    verifyClick === true ||
                    verifyCheck === true ||
                    isEmailValid === "error"
                  }
                />
              </Div>

              <Div m={{ l: "1.5rem" }}>
                <Text
                  textColor={verifyClick ? "neutral800" : "#ffffff"}
                  textSize="body1"
                  textWeight="400"
                >
                  {timeLeft}S
                </Text>
              </Div>
            </Div>
          </Div>

          <Div w={{ xs: "100%", md: "47%" }} m={{ t: "1.5rem" }}>
            <InputField
              label="驗證碼"
              type="text"
              name="verify"
              disabled={emailVerify}
              cursor={emailVerify ? "not-allowed" : "pointer"}
              value={verifyCode}
              onChange={(e) => handleVerifyCode(e.target.value)}
            />
            {verifyCheck === false && (
              <Div d="flex" align="center" m={{ t: "0.25rem" }}>
                <Icon
                  name="AlertSolid"
                  size="14px"
                  color="#555555"
                  m={{ r: "0.25rem" }}
                />
                <Text textSize="paragraph" textColor="#555555" textWeight="600">
                  驗證碼錯誤
                </Text>
              </Div>
            )}
          </Div>

          <Div m={{ t: "1.5rem" }}>
            <Text textSize="paragraph" textColor="#79384A" textWeight="600">
              採檢管數量
            </Text>

            <Div w={{ xs: "100%", md: "47%" }}>
              <Div name="petNum">
                <ConfigProvider
                  theme={{
                    components: {
                      Select: {
                        colorBorder: "rgb(201, 206, 214)",
                        colorPrimary: "#DFD9FC",
                        controlOutline: "#E2DCFF",
                        colorPrimaryHover: "rgb(201, 206, 214)",
                        borderRadius: 8,
                        optionActiveBg: "#A6A4A1",
                        optionSelectedBg: "#A6A4A14D",
                        fontSize: 18,
                      },
                    },
                  }}
                >
                  <Select
                    style={{ width: "100%", height: "40px" }}
                    placeholder="尚未選擇"
                    options={[
                      {
                        value: "1",
                        label: "1",
                      },
                      {
                        value: "2",
                        label: "2",
                      },
                      {
                        value: "3",
                        label: "3",
                      },
                      {
                        value: "4",
                        label: "4",
                      },
                      {
                        value: "5",
                        label: "5",
                      },
                    ]}
                    onChange={handleSelect}
                  />
                </ConfigProvider>
              </Div>
            </Div>
          </Div>

          {renderPetInfoDivs()}

          <Div
            w="100%"
            d="flex"
            flexDir="column"
            justify="center"
            align="center"
            m={{ t: "3.5rem" }}
          >
            <Div w="232px" onClick={handleSubmit}>
              <Btn text="完成" />
            </Div>
            <Div d={showMessage ? "inline-block" : "none"}>
              <Div d="inline-block">
                <Icon
                  d="inline-block"
                  name="AlertSolid"
                  size="14px"
                  color="#EB4C27"
                  m={{ r: "0.25rem" }}
                />
                <Text
                  d="inline-block"
                  textSize="paragraph"
                  textColor="#EB4C27"
                  textWeight="600"
                >
                  請確認填寫所有欄位
                </Text>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>

      {/* 錯誤訊息 */}
      <Div d="flex" justify="center" align="center">
        <Notification
          d={showNotification ? "flex" : "none"}
          bg="#E2FCF0"
          textColor="#0A9C55"
          isOpen={showNotification}
          onClose={() => setShowNotification(false)}
        >
          已成功送出
        </Notification>

        <Notification
          d={showErrorNotification ? "flex" : "none"}
          bg="#FFE0E0"
          textColor="#F03D3D"
          isOpen={showErrorNotification}
          onClose={() => setShowErrorNotification(false)}
        >
          發生錯誤
        </Notification>
      </Div>
    </>
  );
};

export default Info;
