import React, { useEffect, useState } from "react";
import { Col, Div, Image, Row, Text, ThemeProvider } from "atomize";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/images/PetSci_Logo.png";
import Menu from "../assets/icon/menu.png";
import { ReactComponent as PetsPurple } from "../assets/icon/pets_purple.svg";
import Btn from "./Btn";

const theme = {
  grid: {
    containerWidth: {
      xs: "540px",
      sm: "720px",
      md: "960px",
      lg: "1156px",
      xl: "1156px",
    },
    gutterWidth: "16px",
  },
  textSize: {
    size: {
      heading1: "24px",
      header: "20px",
      body1: "16px",
      textInput: "16px",
    },
    height: {
      heading1: "auto",
      header: "160%",
      body1: "auto",
      textInput: "24px",
    },
  },
};

const useRWD = () => {
  const [navBarIcon, setNavBarIcon] = useState("mobile");

  const handleRWD = () => {
    if (window.innerWidth > 768) setNavBarIcon("text");
    else setNavBarIcon("Icon");
  };

  useEffect(() => {
    window.addEventListener("resize", handleRWD);
    handleRWD();
    return () => {
      window.removeEventListener("resize", handleRWD);
    };
  }, []);

  return navBarIcon;
};

const NavBar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [menuIconRotated, setMenuIconRotated] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
    setMenuIconRotated(!menuIconRotated);
  };
  //手機版Menu時，點擊選單外側收合
  useEffect(() => {
    const handleOutsideClick = (event) => {
      // 假设您的菜单图标和导航内容有一个共同的父元素，其id为"navbar"
      if (!document.getElementById("navbar").contains(event.target)) {
        setMenuOpen(false); // 如果点击事件不是发生在导航栏内部，则收起导航栏
        setMenuIconRotated(false);
      }
    };

    if (isMenuOpen) {
      // 如果导航栏是展开的，才监听点击事件
      window.addEventListener("click", handleOutsideClick);
    }

    // 清理函数，用于组件卸载时移除事件监听
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    const handleResize = () => {
      setMenuOpen(false);
      setMenuIconRotated(false);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem("access");
    if (accessToken) {
      setIsLoggenIn(true);
    }
  }, []);

  const navBarIcon = useRWD();

  const navigate = useNavigate();

  const [isLoggedIn, setIsLoggenIn] = useState(false);
  const handleLogin = () => {
    setIsLoggenIn(true);
    navigate("/login");
  };

  const handleLogout = () => {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    setIsLoggenIn(false);
    navigate("/");
  };

  const goIndex = () => {
    navigate("/");
  };

  const goPetList = () => {
    navigate("/petlist");
  };

  const goEditInfo = () => {
    navigate("/editinfo");
  };

  return (
    <ThemeProvider theme={theme}>
      <Div d="flex" align="center" pos="relative" zIndex="99" id="navbar">
        <Row>
          <Col size={{ xs: 12, lg: 12 }}>
            <Div
              d="flex"
              flexDir="column"
              pos="fixed"
              bg="#ffffff"
              w="100%"
              p={{
                y: "0.3rem",
                x: {
                  xs: "1rem",
                  md: "3.5rem",
                  lg: "4.5rem",
                },
              }}
            >
              <Div d="flex" flexDir="row" justify="space-between">
                <Div
                  d="flex"
                  align="center"
                  onClick={() => window.open("https://petsci.tw/", "_blank")}
                >
                  <Image src={Logo} w="7.5rem" />
                </Div>
                {/* 電腦版 */}
                {navBarIcon === "text" ? (
                  <Div d="flex" flexDir="row" justify="flex-end">
                    <Div
                      d="flex"
                      justify="center"
                      align="center"
                      cursor="pointer"
                      onClick={() =>
                        window.open(
                          "https://www.instagram.com/petsci_tw/",
                          "_blank"
                        )
                      }
                    >
                      <PetsPurple />
                      <Text
                        textSize="reportHeading2"
                        textWeight="600"
                        textColor="#79384A"
                        m={{ l: "0.25rem" }}
                      >
                        毛孩知識
                      </Text>
                    </Div>
                    <Div
                      d="flex"
                      justify="center"
                      align="center"
                      m={{ l: "1.5rem" }}
                      cursor="pointer"
                      onClick={() => goPetList()}
                    >
                      <PetsPurple />
                      <Text
                        textSize="reportHeading2"
                        textWeight="600"
                        textColor="#79384A"
                        m={{ l: "0.25rem" }}
                      >
                        查看報告
                      </Text>
                    </Div>
                    <Div
                      d="flex"
                      justify="center"
                      align="center"
                      m={{ l: "1.5rem" }}
                      cursor="pointer"
                      onClick={() => goEditInfo()}
                    >
                      <PetsPurple />
                      <Text
                        textSize="reportHeading2"
                        textWeight="600"
                        textColor="#79384A"
                        m={{ l: "0.25rem" }}
                      >
                        修改資訊
                      </Text>
                    </Div>
                    <Div
                      d="flex"
                      justify="center"
                      align="center"
                      m={{ l: "1.5rem" }}
                      cursor="pointer"
                      onClick={() =>
                        window.open("https://lin.ee/UiSQRwf", "_blank")
                      }
                    >
                      <PetsPurple />
                      <Text
                        textSize="reportHeading2"
                        textWeight="600"
                        textColor="#79384A"
                        m={{ l: "0.25rem" }}
                      >
                        聯絡我們
                      </Text>
                    </Div>
                    <Div
                      d="flex"
                      justify="center"
                      align="center"
                      m={{ l: "1.5rem" }}
                    >
                      <Btn
                        text={isLoggedIn ? "登出" : "登入/註冊"}
                        onClick={isLoggedIn ? handleLogout : handleLogin}
                      />
                    </Div>
                  </Div>
                ) : (
                  <Div d="flex" flexDir="row" align="center">
                    <Image
                      src={Menu}
                      h="24px"
                      w="24px"
                      onClick={toggleMenu}
                      style={{
                        transform: menuIconRotated ? "rotate(90deg)" : "none",
                      }}
                      transition
                    />
                  </Div>
                )}
              </Div>

              {/* 手機版 */}
              {isMenuOpen && (
                <Div
                  w="100%"
                  h="auto"
                  d="flex"
                  flexDir="column"
                  p={{ x: "5.5rem", y: "1.25rem" }}
                  bg="#ffffff"
                  justify="center"
                  align="center"
                  style={{
                    position: "absolute",
                    zIndex: 1000,
                    right: "0",
                    top: "60px",
                  }} // 添加定位样式
                >
                  <Div
                    d="flex"
                    justify="center"
                    align="center"
                    onClick={() =>
                      window.open(
                        "https://www.instagram.com/petsci_tw/",
                        "_blank"
                      )
                    }
                  >
                    <PetsPurple />
                    <Text
                      textSize="reportHeading2"
                      textWeight="600"
                      textColor="#79384A"
                      m={{ l: "0.25rem" }}
                    >
                      毛孩知識
                    </Text>
                  </Div>
                  <Div
                    d="flex"
                    justify="center"
                    align="center"
                    onClick={() => goPetList()}
                  >
                    <PetsPurple />
                    <Text
                      textSize="reportHeading2"
                      textWeight="600"
                      textColor="#79384A"
                      m={{ l: "0.25rem" }}
                    >
                      查看報告
                    </Text>
                  </Div>
                  <Div
                    d="flex"
                    justify="center"
                    align="center"
                    onClick={() => goEditInfo()}
                  >
                    <PetsPurple />
                    <Text
                      textSize="reportHeading2"
                      textWeight="600"
                      textColor="#79384A"
                      m={{ l: "0.25rem" }}
                    >
                      修改資訊
                    </Text>
                  </Div>
                  <Div
                    d="flex"
                    justify="center"
                    align="center"
                    onClick={() =>
                      window.open("https://lin.ee/UiSQRwf", "_blank")
                    }
                  >
                    <PetsPurple />
                    <Text
                      textSize="reportHeading2"
                      textWeight="600"
                      textColor="#79384A"
                      m={{ l: "0.25rem" }}
                    >
                      聯絡我們
                    </Text>
                  </Div>
                  <Div d="flex" justify="center" align="center">
                    <Btn
                      text={isLoggedIn ? "登出" : "登入/註冊"}
                      onClick={isLoggedIn ? handleLogout : handleLogin}
                    />
                  </Div>
                </Div>
              )}
            </Div>
          </Col>
        </Row>
      </Div>
    </ThemeProvider>
  );
};

export default NavBar;
