import { Button, Col, Div, Icon, Image, Row, Text } from "atomize";
import React, { useEffect, useState } from "react";
import Logo from "../src/assets/images/PetSci_Logo.png";
import LoginLeft1 from "./assets/images/login/login_left1.png";
import LoginLeft2 from "./assets/images/login/login_left2.png";
import LoginLeft3 from "./assets/images/login/login_left3.png";
import LoginRight1 from "./assets/images/login/login_right1.png";
import LoginRight2 from "./assets/images/login/login_right2.png";
import LoginRight3 from "./assets/images/login/login_right3.png";
import { ReactComponent as LineBotton } from "./assets/icon/linebtn.svg";
import { useNavigate } from "react-router-dom";
import NavBar from "./components/NavBar";

const useRWD = () => {
  const [device, setDevice] = useState("mobile");

  const handleRWD = () => {
    if (window.innerWidth < 720) setDevice("mobile");
    else if (window.innerWidth < 1156) setDevice("tablet");
    else setDevice("PC");
  };

  useEffect(() => {
    window.addEventListener("resize", handleRWD);
    handleRWD();
    return () => {
      window.removeEventListener("resize", handleRWD);
    };
  }, []);

  return device;
};

const LoginV2 = () => {
  const device = useRWD();

  const [clicked, setClicked] = useState(false);
  const handleMouseDown = () => {
    setClicked(true);
  };
  const handleMouseUp = () => {
    setClicked(false);
  };

  // const lineRedirect = `${process.env.REACT_APP_API_URL}/line-login-redirect/`;

  const lineLogin = () => {
    const baseUrl = "https://access.line.me/oauth2/v2.1/authorize";
    const params = new URLSearchParams({
      response_type: "code",
      client_id: "2005422425", // 替换成你的实际 client_id
      redirect_uri: `${window.location.origin}/line-login-redirect/`, // 替换成你的实际 redirect_uriX
      state: "meow",
      scope: "profile openid email",
    });
    const fullUrl = `${baseUrl}?${params.toString()}`;
    window.location.href = fullUrl;
  };

  return (
    <>
      {/* 空白nav bar */}
      <Div d="flex" align="center" pos="relative" zIndex="99" id="navbar">
        <Row>
          <Col size={{ xs: 12, lg: 12 }}>
            <Div
              d="flex"
              flexDir="column"
              pos="fixed"
              bg="#ffffff"
              w="100%"
              p={{
                y: "1rem",
                x: {
                  xs: "1rem",
                  md: "3.5rem",
                  lg: "4.5rem",
                },
              }}
            >
              <Div d="flex" flexDir="row" justify="space-between">
                <Div
                  d="flex"
                  align="center"
                  onClick={() => window.open("https://petsci.tw/", "_blank")}
                >
                  <Image src={Logo} w="7.5rem" />
                </Div>
              </Div>
            </Div>
          </Col>
        </Row>
      </Div>

      <Div
        p={{
          X: {
            xs: "1rem",
            md: "3.5rem",
            lg: "0rem",
          },
          t: { xs: "7rem", md: "8.5rem", lg: "4.5rem" },
        }}
      >
        <Row
          d="flex"
          justify={{ xs: "center", md: "space-between" }}
          align="center"
        >
          {device === "PC" && (
            <Col size={{ lg: 3 }}>
              <Div textAlign="left">
                <Image src={LoginLeft1} w="60%" />
                <Image src={LoginLeft2} w="60%" />
                <Image src={LoginLeft3} w="60%" />
              </Div>
            </Col>
          )}

          {device === "tablet" && (
            <Col size={{ lg: 3 }}>
              <Div textAlign="left" d="flex" flexDir="column">
                <Image src={LoginLeft1} w="50%" />
                <Image src={LoginLeft2} w="50%" />
                <Image src={LoginLeft3} w="50%" />
              </Div>
            </Col>
          )}

          <Div rounded="12px" bg="#FFFFFF" shadow="3">
            <Div p={{ y: "4rem" }} m={{ x: "1.5rem" }} justify="center">
              <Div
                d="flex"
                justify="center"
                align="center"
                flexDir="column"
                m={{ b: "2rem" }}
              >
                <Text textSize="heading2" textWeight="600">
                  會員註冊/登入
                </Text>
              </Div>

              <Div
                d="flex"
                justify="center"
                align="center"
                flexDir="row"
                onClick={() => lineLogin()}
              >
                <Div
                  bg={clicked ? "#048C3C" : "#06C755"}
                  hoverBg={clicked ? "#048C3C" : "#05B34D"}
                  rounded="6px"
                  d="flex"
                  justify="center"
                  align="center"
                  flexDir="row"
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                  onMouseLeave={handleMouseUp}
                >
                  <Div
                    d="flex"
                    justify="center"
                    align="center"
                    style={{
                      borderRight: "solid",
                      borderColor: "rgba(0, 0, 0, 0.08)",
                    }}
                  >
                    <LineBotton />
                  </Div>
                  <Div p={{ r: "48px", l: "48px" }}>
                    <Text
                      textColor="#ffffff"
                      textSize="22px"
                      textWeight="500"
                      cursor="pointer"
                    >
                      Log in
                    </Text>
                  </Div>
                </Div>
              </Div>

              <Div d="flex" justify="center" align="center" flexDir="column">
                <Text textSize="body1" m={{ t: "2.5rem" }}>
                  有任何問題，可洽科學毛怪 IG 或 E-mail
                </Text>
                <Div>
                  <a
                    href="https://www.instagram.com/petsci_tw/"
                    target="_blank"
                  >
                    <Icon name="Instagram" size="24px" color="#EB4C27" />
                  </a>
                  <a href="mailto:petsci.service@gmail.com">
                    <Icon
                      name="Email"
                      size="24px"
                      color="#EB4C27"
                      m={{ l: "24px" }}
                    />
                  </a>
                </Div>
              </Div>
            </Div>
          </Div>

          {device === "PC" && (
            <Col size={{ lg: 3 }}>
              <Div textAlign="right">
                <Image src={LoginRight1} w="60%" />
                <Image src={LoginRight2} w="60%" />
                <Image src={LoginRight3} w="60%" />
              </Div>
            </Col>
          )}

          {device === "tablet" && (
            <Col size={{ lg: 3 }}>
              <Div
                textAlign="right"
                d="flex"
                flexDir="column"
                justify="flex-end"
                align="flex-end"
              >
                <Image src={LoginRight1} w="50%" />
                <Image src={LoginRight2} w="50%" />
                <Image src={LoginRight3} w="50%" />
              </Div>
            </Col>
          )}

          {device === "mobile" && (
            <Div d="flex" flexDir="row" justify="">
              <Image src={LoginLeft1} w="50%" />
              <Div p={{ t: "2rem" }}>
                <Image src={LoginRight2} w="100%" />
              </Div>
            </Div>
          )}
        </Row>
      </Div>
    </>
  );
};

export default LoginV2;
