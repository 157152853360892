import "./App.css";
import React from "react";
import { Routes, Route } from "react-router-dom";
import LogIn from "./LogIn";
import PetList from "./PetList";
import ReportsEasy from "./ReportsEasy";
import Shares from "./Shares";
import Gogomaumua from "./Gogomaumua";
import ReportsEasyV2 from "./ReportsEasyV2";
import ReportsDetail from "./ReportsDetail";
import { Helmet } from "react-helmet";
import LoginV2 from "./LoginV2";
import Info from "./Info";
import EditInfo from "./EditInfo";
import LineLoginRedirect from "./LineLoginRedirect";
import PrivateRoute from "./components/PrivateRoute";
// 定義一些簡單的組件，用於模擬頁面

function App() {
  //設定<meta content="noIndex"，使dev環境不會被估狗搜尋到
  const robotsMeta = process.env.REACT_APP_ROBOTS_META || "";

  return (
    <>
      <Helmet>
        {robotsMeta === "noindex" && <meta name="robots" content="noindex" />}
      </Helmet>

      <Routes>
        {/* 狗狗貓貓首頁未上線，暫以登入頁作為首頁
        <Route path="/" index element={<Gogomaumua />} /> */}
        <Route path="/" index element={<LoginV2 />} />
        <Route path="/login" index element={<LoginV2 />} />
        {/* <Route path="/login" index element={<LoginV2 />} /> */}
        <Route
          path="/line-login-redirect"
          index
          element={<LineLoginRedirect />}
        />
        <Route
          path="/info"
          index
          element={
            //目的是記住用戶從哪裡來，登入後直接導向該頁
            <PrivateRoute>
              <Info />
            </PrivateRoute>
          }
        />
        <Route path="/editInfo" index element={<EditInfo />} />
        {/* 這是會員登入頁 */}
        {/* <Route path="login" element={<LogIn />} /> */}
        <Route path="/petlist" element={<PetList />} />
        {/* <Route path="reports/:id/easy-read" element={<ReportsEasy />} /> */}
        <Route path="shares" element={<Shares />} />
        <Route path="/reports/easy-read/:reportSha" element={<ReportsEasyV2 />} />
        <Route path="/reports/detail/:reportSha" element={<ReportsDetail />} />
        {/* 只有內部人員知道、無視有無付費加購 */}
        <Route path="/reports/bar/PeSciGetRich/:reportId" element={<ReportsDetail />} />
        <Route path="*" element={<p>找不到頁面</p>} />
      </Routes>
    </>
  );
}

export default App;
