import React, { useState } from "react";
import { Text, Textarea } from "atomize";
import "../index.css";

const InputTextarea = ({ label, placeholder, onChange, name, value, maxLength }) => {
  const [inputState, setInputState] = useState("default");

  const handleBlur = () => {
    setInputState("default");
  };

  const handlefocus = () => {
    setInputState("focus");
  };

  const getFocusShadow = () => {
    switch (inputState) {
      case "default":
        return "0px 0px 0px 4px #A6A4A1";
      case "focus":
        return "0px 0px 0px 4px #E2DCFF";
      case "correct":
        return "0px 0px 0px 4px #C6F1DD";
      case "error":
        return "0px 0px 0px 4px #FFE0E0";
      default:
        return "#FFE0E0";
    }
  };

  return (
    <>
      <Text textSize="paragraph" textColor="#79384A" textWeight="600">
        {label}
      </Text>
      <Textarea
        className="input"
        placeholder={placeholder}
        textSize="heading3"
        d="flex"
        w="100%"
        p={{ x: "1rem", y: "0.75rem" }}
        rounded="lg"
        state={inputState}
        onFocus={handlefocus}
        onBlur={handleBlur}
        onChange={onChange}
        name={name}
        value={value}
        focusShadow={getFocusShadow()} // 聚焦状态的阴影效果
        maxH="6.5rem"
        maxLength={maxLength}
      />
    </>
  );
};

export default InputTextarea;
