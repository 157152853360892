import { Button, Div, Image, Text } from "atomize";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ConfigProvider, DatePicker, Divider, Radio, Select } from "antd";
import InputField from "./components/InputField";
import Btn from "./components/Btn";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import InputTextarea from "./components/InputTextarea";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { ReactComponent as BannerBtnRight } from "./assets/icon/banner_btn_right.svg";
import { ReactComponent as BannerBtnLeft } from "./assets/icon/banner_btn_left.svg";

const FieldName = ({ name }) => (
  <Div
    bg="#F3B23E"
    p={{ x: "0.5rem", y: "0.5rem" }}
    rounded="0.75rem"
    w={{ xs: "4.5rem", md: "7.5rem", xl: "7.5rem" }}
    style={{ whiteSpace: "nowrap" }}
  >
    <Text
      textSize="heading3"
      textWeight="600"
      textColor="#ffffff"
      textAlign="center"
    >
      {name}
    </Text>
  </Div>
);

const EditPetInfo = () => {
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    fetchPetInfo();
  }, []);

  //原始的寵物資訊
  const [originalPetInfo, setOriginalPetInfo] = useState([]);

  const petInfoApiUrl = `${process.env.REACT_APP_API_URL}/users/register/pet-info/`;

  const fetchPetInfo = useCallback(() => {
    const storedAccess = localStorage.getItem("access");

    fetch(petInfoApiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedAccess}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          // alert("access 過期");
          refreshRequest(fetchPetInfo);
          throw new Error("Unauthorized"); //使不執行.then(data)，直接跳到.catch()
        } else {
          return response.json();
        }
      })
      .then((data) => {
        const deepCopyData = JSON.parse(JSON.stringify(data));
        setOriginalPetInfo(deepCopyData);
        setTempPetInfo(data);
        // console.log("tempPetInfo " + JSON.stringify(tempPetInfo));

        setPageLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  });

  //編輯狀態(看是編輯哪一個index，設定編輯狀態)
  const [editStatus, setEditStatus] = useState(
    Array(originalPetInfo.length).fill(false)
  );
  const editPetClick = (index) => {
    //取消後再次修改，得更新回原始資料
    fetchPetInfo();

    //每次點按修改鍵時都先初始化為false，看點擊哪一格，才設定正在編輯
    const newEditStatus = Array(originalPetInfo.length).fill(false);
    newEditStatus[index] = true;
    setEditStatus(newEditStatus);

    setIsProfileUpload(false);
  };

  //資料更新(看是編輯哪一個index，更新每個欄位的值)
  //要更新的寵物資訊
  const [tempPetInfo, setTempPetInfo] = useState([]);

  const handlePetChange = (index, field, value) => {
    //把原先的資料保留，更新該index的每個field(欄位標的)的值
    const newPetInfo = [...tempPetInfo];
    newPetInfo[index][field] = value;
    setTempPetInfo(newPetInfo);

    // console.log("原本的 " + JSON.stringify(originalPetInfo));
    // console.log("修改後的 " + JSON.stringify(tempPetInfo));
  };

  const handleDateChange = (index, date, dateString) => {
    //把原先的資料保留，更新該index的每個date的值
    const newPetInfo = [...tempPetInfo];
    newPetInfo[index].birth = dateString;
    // setPetInfo(newPetInfo);
    setTempPetInfo(newPetInfo);
  };

  //取sex值，放進petInfo陣列中
  const handleSexSelect = (index, value) => {
    const newPetInfo = [...tempPetInfo];
    newPetInfo[index].sex = value;
    setTempPetInfo(newPetInfo);
  };

  //上傳大頭貼
  const profileApiUrl = `${process.env.REACT_APP_API_URL}/users/register/pet-picture-upload/`;

  const fileInput = useRef(null);
  const handleProfileClick = () => {
    fileInput.current.click();
  };
  const [isLoading, setIsLoading] = useState(false);
  const [isProfileUpload, setIsProfileUpload] = useState(false);
  const handleProfileChange = useCallback((index, file) => {
    const uploadImage = file;
    const formData = new FormData();
    formData.append("image", uploadImage);

    if (uploadImage) {
      setIsProfileUpload(false);
      setIsLoading(true);

      const storedAccess = localStorage.getItem("access");

      fetch(profileApiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${storedAccess}`,
        },
        body: formData,
      })
        .then((response) => {
          if (response.status === 401) {
            // alert("access 過期");
            refreshRequest(() => handleProfileChange(index, file));
          } else {
            return response.json();
          }
        })
        .then((data) => {
          // 不同寵物要有不同圖片和loading
          const newPetInfo = [...tempPetInfo];
          // newPetInfo[index].img = data.url;
          newPetInfo[index].pet_img_url = data.url;
          setTempPetInfo(newPetInfo);
          setIsLoading(false);
          setIsProfileUpload(true);
          // console.log("原本的圖片 " + JSON.stringify(originalPetInfo));
          // console.log("新上傳的圖片 " + JSON.stringify(tempPetInfo));
        });
    }
  });

  //比對有變動過的值後再送出
  const getChangedFields = () => {
    return tempPetInfo
      .map((info, index) => {
        const originalInfo = originalPetInfo[index];
        const changes = {
          index: index,
        };

        //tempPetInfo的每一格和originalPetInfo比對改變的欄位和值，紀錄在changes中
        Object.keys(info).forEach((key) => {
          if (info[key] !== originalInfo[key]) {
            changes[key] = info[key];
          }
        });

        return changes;
      })
      .filter((change) => Object.keys(change).length > 0); // 過濾掉沒有變更的對象
  };

  const handlePetSave = useCallback((index) => {
    const changedData = getChangedFields();
    const dataToSend = {
      pet_id: originalPetInfo[index].id,
      ...changedData[index],
    };
    //把紀錄改哪一格指標的刪掉
    delete dataToSend.index;
    // console.log("dataToSend " + JSON.stringify(dataToSend));

    const storedAccess = localStorage.getItem("access");
    const updatePetInfoApiUrl = `${process.env.REACT_APP_API_URL}/users/register/pet-info/`;

    fetch(updatePetInfoApiUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedAccess}`,
      },
      body: JSON.stringify(dataToSend),
    })
      .then((response) => {
        if (response.status === 401) {
          // alert("access 過期");
          refreshRequest(handlePetSave);
        } else if (response.status === 200) {
          //每次點按修改鍵時都先初始化為false，看點擊哪一格，才設定正在編輯
          const newEditStatus = Array(originalPetInfo.length).fill(false);
          newEditStatus[index] = false;
          setEditStatus(newEditStatus);
        } else {
          return response.json();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  });
  const cancelPetClick = (index) => {
    //把原先的狀態保留，且設定不能編輯
    const newEditStatus = [...editStatus];
    newEditStatus[index] = false;
    setEditStatus(newEditStatus);

    setTempPetInfo(originalPetInfo);
  };

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  //看寵物有幾隻
  const renderPetInfoDivs = () => {
    return (
      <>
        {tempPetInfo.map((info, index) => (
          <SwiperSlide key={info.id}>
            <Div
              d="flex"
              flexDir="column"
              justify="center"
              align={editStatus[index] ? "flex-end" : "center"}
            >
              {/* 白框 */}
              <Div
                w={{ xs: "22rem", md: "27rem", lg: "32rem" }}
                rounded="12px"
                bg="#FFFFFF"
                border={editStatus[index] ? "3px solid" : "none"}
                borderColor={editStatus[index] ? "neutral600" : "none"}
                shadow="3"
                p={{
                  x: { xs: "1.5rem", md: "2rem", lg: "2.5rem" },
                  y: { xs: "1.5rem", md: "1.6rem", lg: "1.7rem" },
                }}
              >
                <Div d="flex" justify="center" align="center" flexDir="column">
                  <Text textSize="heading2" textWeight="600">
                    修改寵物資訊
                  </Text>
                  <Divider style={{ margin: "16px" }} />
                </Div>

                <Div d="flex" flexDir="row" justify="start" align="center">
                  <FieldName name="姓名" />

                  <Div w="100%">
                    {editStatus[index] ? (
                      <Div
                        d="flex"
                        flexDir={{ xs: "column", md: "row" }}
                        m={{ l: "1.3rem" }}
                      >
                        <Div w="100%">
                          <InputField
                            type="text"
                            name={`petname-${index}`} //name屬性標示輸入字段
                            value={info.pet_name}
                            onChange={(e) =>
                              handlePetChange(index, "pet_name", e.target.value)
                            }
                            maxLength={10}
                          />
                        </Div>
                      </Div>
                    ) : (
                      <Div m={{ l: "2rem" }}>
                        <Text textSize="heading3" textColor="neutral600">
                          {info.pet_name}
                        </Text>
                      </Div>
                    )}
                  </Div>
                </Div>

                <Div
                  d="flex"
                  flexDir="row"
                  justify="start"
                  align="center"
                  m={{ t: "1rem" }}
                >
                  <FieldName name="品種" />

                  <Div w="100%">
                    {editStatus[index] ? (
                      <Div
                        d="flex"
                        flexDir={{ xs: "column", md: "row" }}
                        m={{ l: "1.3rem" }}
                      >
                        <Div w="100%">
                          <InputField
                            type="text"
                            name={`variety-${index}`} //name屬性標示輸入字段
                            value={info.strain}
                            onChange={(e) =>
                              handlePetChange(index, "strain", e.target.value)
                            }
                            maxLength={10}
                          />
                        </Div>
                      </Div>
                    ) : (
                      <Div m={{ l: "2rem" }}>
                        <Text textSize="heading3" textColor="neutral600">
                          {info.strain}
                        </Text>
                      </Div>
                    )}
                  </Div>
                </Div>

                <Div
                  d="flex"
                  flexDir="row"
                  justify="start"
                  align="center"
                  m={{ t: "1rem" }}
                >
                  <FieldName name="生日" />

                  <Div w="100%">
                    {editStatus[index] ? (
                      <Div
                        d="flex"
                        flexDir={{ xs: "column", md: "row" }}
                        m={{ l: "1.3rem" }}
                      >
                        <ConfigProvider
                          theme={{
                            components: {
                              DatePicker: {
                                hoverBorderColor: "#DFD9FC",
                                activeBorderColor: "#DFD9FC",
                                cellRangeBorderColor: "#DFD9FC",
                                controlOutline: "#E2DCFF",
                                colorPrimary: "#F3B23E",
                                colorPrimaryHover: "#F3B23E",
                                borderRadius: 8,
                                optionActiveBg: "#A6A4A1",
                                optionSelectedBg: "#A6A4A14D",
                                colorBorder: "#DFD9FC",
                                fontSize: 18,
                              },
                            },
                          }}
                        >
                          <DatePicker
                            style={{ width: "100%", height: "40px" }}
                            value={dayjs(info.birth)}
                            onChange={(date, dateString) =>
                              handleDateChange(index, date, dateString)
                            }
                          />
                        </ConfigProvider>
                      </Div>
                    ) : (
                      <Div m={{ l: "2rem" }}>
                        <Text textSize="heading3" textColor="neutral600">
                          {info.birth}
                        </Text>
                      </Div>
                    )}
                  </Div>
                </Div>

                {/* <Div
                  d="flex"
                  flexDir="row"
                  justify="start"
                  align="center"
                  m={{ t: "1rem" }}
                >
                  <FieldName name="性別" />

                  <Div w="100%">
                    {editStatus[index] ? (
                      <>
                        <Div
                          d="flex"
                          flexDir={{ xs: "column", md: "row" }}
                          m={{ l: "1.3rem" }}
                        >
                          <Div w="100%">
                            <ConfigProvider
                              theme={{
                                components: {
                                  Select: {
                                    colorBorder: "rgb(201, 206, 214)",
                                    colorPrimary: "#DFD9FC",
                                    controlOutline: "#E2DCFF",
                                    colorPrimaryHover: "rgb(201, 206, 214)",
                                    borderRadius: 8,
                                    optionActiveBg: "#A6A4A1",
                                    optionSelectedBg: "#A6A4A14D",
                                    fontSize: 18,
                                  },
                                },
                              }}
                            >
                              <Select
                                name={`sex-${index}`}
                                defaultValue={info.sex}
                                style={{
                                  width: "100%",
                                  height: "40px",
                                }}
                                options={[
                                  {
                                    value: "M",
                                    label: "公",
                                  },
                                  {
                                    value: "F",
                                    label: "母",
                                  },
                                ]}
                                onChange={(value) =>
                                  handlePetChange(index, "sex", value)
                                }
                              />
                            </ConfigProvider>
                          </Div>
                        </Div>
                      </>
                    ) : (
                      <Div m={{ l: "2rem" }}>
                        <Text textSize="heading3" textColor="neutral600">
                          {info.sex}
                        </Text>
                      </Div>
                    )}
                  </Div>
                </Div> */}

                <Div
                  d="flex"
                  flexDir="row"
                  justify="start"
                  align={editStatus[index] ? "start" : "center"}
                  m={{ t: "1rem" }}
                >
                  <FieldName name="備註" />

                  <Div w="100%">
                    {editStatus[index] ? (
                      <Div
                        d="flex"
                        flexDir={{ xs: "column", md: "row" }}
                        m={{ l: "1.3rem" }}
                      >
                        <Div w="100%">
                          <InputTextarea
                            placeholder="可填寫以下內容，供分析人員參考：疾病、重大手術 (結紮免填)、過敏食物、是否餵食保健品與益生菌，若以上都沒有，請填「無」。"
                            name={`note-${index}`}
                            value={info.note}
                            onChange={(e) =>
                              handlePetChange(index, "note", e.target.value)
                            }
                            maxLength={100}
                          />
                        </Div>
                      </Div>
                    ) : (
                      <Div m={{ l: "2rem" }}>
                        <Text textSize="heading3" textColor="neutral600">
                          {info.note}
                        </Text>
                      </Div>
                    )}
                  </Div>
                </Div>

                {/* 大頭貼 */}
                {editStatus[index] ? (
                  <>
                    <Div
                      d="flex"
                      flexDir="column"
                      justify="center"
                      align="flex-start"
                      m={{ t: "1rem" }}
                    >
                      <Div d="flex" flexDir="column" justify="center">
                        <Div
                          d="flex"
                          flexDir="column"
                          justify="center"
                          align="center"
                        >
                          {isLoading ? (
                            <Div
                              d="flex"
                              justify="center"
                              align="center"
                              h="160px"
                            >
                              <Div className="loader" />
                            </Div>
                          ) : (
                            <Image w="150px" src={info.pet_img_url} />
                          )}
                          <Div m={{ t: "1rem" }}>
                            <Button
                              bg="#ffffff"
                              onClick={() => handleProfileClick(index)}
                            >
                              {/* 上傳檔案，隱藏<input type:file>觸發點集事件 */}
                              <input
                                type="file"
                                ref={fileInput}
                                style={{ display: "none" }}
                                onChange={(e) =>
                                  handleProfileChange(index, e.target.files[0])
                                }
                              />
                              {isProfileUpload ? (
                                <Text
                                  textColor="neutral800"
                                  textSize="subheading1"
                                  textWeight="600"
                                >
                                  上傳完成!
                                </Text>
                              ) : (
                                <>
                                  {isLoading ? (
                                    <Text
                                      textColor="neutral800"
                                      textSize="subheading1"
                                      textWeight="600"
                                    >
                                      上傳中...
                                    </Text>
                                  ) : (
                                    <Text
                                      textColor="neutral800"
                                      textSize="subheading1"
                                      textWeight="600"
                                    >
                                      點擊修改照片
                                    </Text>
                                  )}
                                </>
                              )}
                            </Button>
                          </Div>
                        </Div>
                      </Div>
                    </Div>
                  </>
                ) : (
                  <Div m={{ t: "1rem" }}>
                    <Image w="150px" src={info.pet_img_url} />
                  </Div>
                )}
              </Div>

              {/* 儲存 */}
              {editStatus[index] ? (
                <Div
                  m={{ t: { xs: "1rem", lg: "2rem" } }}
                  d="flex"
                  justify="flex-end"
                  align="center"
                >
                  <Div w="108px">
                    <Button
                      w="100%"
                      rounded="lg"
                      textSize="14px"
                      textColor="#79384A"
                      bg="#ffffff"
                      d="flex"
                      justify="center"
                      align="center"
                      textWeight="600"
                      border="2px solid"
                      borderColor="#79384A"
                      hoverTextColor="#79384A"
                      hoverBg="#FFFFFF"
                      hoverBorderColor="#79384A"
                      onClick={() => cancelPetClick(index)}
                    >
                      取消
                    </Button>
                  </Div>
                  <Div m={{ l: "0.75rem" }} w="108px">
                    <Btn text="確定" onClick={() => handlePetSave(index)} />
                  </Div>
                </Div>
              ) : (
                <Div
                  w="232px"
                  m={{ t: { xs: "1rem", lg: "2rem" } }}
                  d="flex"
                  justify="center"
                  align="center"
                >
                  <Btn text="修改" onClick={() => editPetClick(index)} />
                </Div>
              )}
            </Div>
          </SwiperSlide>
        ))}
      </>
    );
  };

  const navigate = useNavigate();
  const refreshApiUrl = `${process.env.REACT_APP_API_URL}/users/login/token-refresh/`;
  const refreshRequest = useCallback((callback) => {
    const storedRefresh = localStorage.getItem("refresh");

    fetch(refreshApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refresh: storedRefresh,
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          // alert("refresh");
          localStorage.removeItem("access");
          localStorage.removeItem("refresh");
          navigate("/login");
        } else if (response.status === 400) {
          navigate("/login");
        } else {
          return response.json();
        }
      })
      .then((data) => {
        const newAccess = data.access;
        localStorage.setItem("access", newAccess);
        if (typeof callback === "function") {
          callback(); // 调用传入的回调函数
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        navigate("/login");
      });
  });

  return (
    <>
      {pageLoading ? (
        <Div d="flex" justify="center" align="center" w="100%" h="100vh">
          <Div d="flex" justify="center" align="center" className="loader" />
        </Div>
      ) : (
        <Div d="flex" justify="center" align="center">
          {/* 左右鍵icon */}
          <Div
            id="left"
            ref={prevRef}
            pos="absolute"
            transform="translateY(-50%)"
            left={"10%"}
          >
            <BannerBtnLeft />
          </Div>
          <Div
            ref={nextRef}
            pos="absolute"
            transform="translateY(-50%)"
            right={"10%"}
          >
            <BannerBtnRight />
          </Div>
          <Swiper
            className="mySwiper"
            modules={[Navigation]}
            spaceBetween={50}
            slidesPerView={1}
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;
            }}
          >
            {renderPetInfoDivs()}
          </Swiper>
        </Div>
      )}
    </>
  );
};

export default EditPetInfo;
