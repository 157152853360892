import React, { useState } from "react";
import { Div, Icon, Image, Input, Text } from "atomize";
import ErrorIcon from "../assets/icon/sm_right_icon.png";
import "../index.css";

const InputField = ({
  label,
  placeholder,
  type,
  name,
  value,
  onChange,
  disabled,
  cursor,
  maxLength,
}) => {
  //const phoneRegex = /^(0|\+?[1-9][0-9]{1,4})[0-9]{6,10}$/;
  const [inputState, setInputState] = useState("default");

  const handleBlur = () => {
    setInputState("default");
  };

  const handlefocus = () => {
    setInputState("focus");
  };

  // 检查输入
  // if (phoneRegex.test(inputValue) || inputValue === "") {
  //   setInputState("correct");
  //   onChange(inputValue, "correct");
  // } else {
  //   setInputState("error");
  //   onChange(inputValue, "error");
  // }
  // onChange(inputValue);

  // console.log(inputState)
  const getBorderColor = () => {
    switch (inputState) {
      case "default":
        return "#A6A4A1";
      case "focus":
        return "#7357FF";
      case "correct":
        return "#0BAA60";
      case "error":
        return "#F61818";
      default:
        return "#A6A4A1";
    }
  };

  const getFocusShadow = () => {
    switch (inputState) {
      case "default":
        return "0px 0px 0px 4px #A6A4A1";
      case "focus":
        return "0px 0px 0px 4px #E2DCFF";
      case "correct":
        return "0px 0px 0px 4px #C6F1DD";
      case "error":
        return "0px 0px 0px 4px #FFE0E0";
      default:
        return "#FFE0E0";
    }
  };
  // console.log(inputState)
  // console.log(getBorderColor())

  // const gerErrorMessage = () => {
  //   return inputState === "error" ? (
  //     <Div d="flex" align="center" m={{ t: "0.25rem" }}>
  //       <Icon
  //         name="AlertSolid"
  //         size="14px"
  //         color="#EB4C27"
  //         m={{ r: "0.25rem" }}
  //       />
  //       <Text textSize="paragraph" textColor="#EB4C27" textWeight="600">
  //         {errormsg}
  //       </Text>
  //     </Div>
  //   ) : null;
  // };

  return (
    <>
      <Text textSize="paragraph" textColor="#79384A" textWeight="600">
        {label}
      </Text>
      <Input
        className="input"
        placeholder={placeholder}
        textSize="heading3"
        d="flex"
        w="100%"
        p={{ x: "1rem", y: "0.75rem" }}
        rounded="lg"
        state={inputState}
        onFocus={handlefocus}
        onBlur={handleBlur}
        type={type} //type="email" 決定輸入字段的類型
        name={name} //提交表單時，作為key區分不同輸入框
        value={value} //取得輸入框內的值
        onChange={onChange} //當有輸入內容時，用onChange事件更新內容
        // border="1px solid"
        // borderColor="gray500"
        // forcusBorderColor="gray500"
        // focusborderColor={getBorderColor()}
        focusShadow={getFocusShadow()} // 聚焦状态的阴影效果
        // hoverBorderColor="#007bff" // 默认状态下鼠标悬停的边框颜色
        disabled={disabled}
        cursor={cursor}
        maxLength={maxLength}
      />
      {/* <Text textSize="paragraph" textColor="#555555" textWeight="600">
        {gerErrorMessage()}
      </Text> */}
    </>
  );
};

export default InputField;
