import {
  Button,
  Col,
  Div,
  Icon,
  Image,
  Modal,
  Notification,
  Row,
  Tag,
  Text,
} from "atomize";
import React, { useCallback, useEffect, useState } from "react";
import NavBar from "./components/NavBar";
import { useNavigate } from "react-router-dom";
import useDocumentTitle from "./components/useDocumentTitle";
import SmallModal from "./components/SmallModal";
import { ReactComponent as Information } from "./assets/icon/infomation(red).svg";
import Step from "./components/Step";
import { ConfigProvider, Table } from "antd";
import CardPage20 from "./assets/images/report_easy/card_page20.png";
import Btn from "./components/Btn";
import ToBeUnlocked from "./assets/images/report_easy/tobeunlocked.png";
import LargeModal from "./components/LargeModal";

const PetList = () => {
  useDocumentTitle("報告列表 - PetSci");
  const navigate = useNavigate();

  const [petList, setPetList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // console.log("useEffect is running");
    fetchPetList();
  }, []);

  const petlistApiUrl = `${process.env.REACT_APP_API_URL}/users/report/overview`;

  const fetchPetList = useCallback(() => {
    const storedAccess = localStorage.getItem("access");

    fetch(petlistApiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedAccess}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          //alert("access 過期");
          refreshRequest(fetchPetList);
        } else {
          return response.json();
        }
      })
      .then((data) => {
        setIsLoading(false);
        const newAccess = data.access;
        localStorage.setItem("access", newAccess);

        setPetList(data.pets);
        // console.log("petlist " + JSON.stringify(data.pets));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  });

  const refreshApiUrl = `${process.env.REACT_APP_API_URL}/users/login/token-refresh/`;
  const refreshRequest = useCallback((callback) => {
    const storedRefresh = localStorage.getItem("refresh");

    fetch(refreshApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refresh: storedRefresh,
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          // alert("refresh");
          localStorage.removeItem("access");
          localStorage.removeItem("refresh");
          navigate("/login");
        } else if (response.status === 400) {
          navigate("/login");
        } else {
          return response.json();
        }
      })
      .then((data) => {
        const newAccess = data.access;
        localStorage.setItem("access", newAccess);
        if (typeof callback === "function") {
          callback(); // 调用传入的回调函数
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        navigate("/login");
      });
  });

  const [isPad, setIsPad] = useState(window.innerWidth < 768); //螢幕尺寸<768 =>true，隱藏某些欄位
  const [isMobile, setIsMobile] = useState(window.innerWidth < 394); //調整欄位寬度

  useEffect(() => {
    const handleResize = () => {
      setIsPad(window.innerWidth < 768);
      setIsMobile(window.innerWidth < 394);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isPad, isMobile]);

  const columns = [
    {
      title: "查看報告",
      dataIndex: "reportSha",
      key: "reportSha",
      width: "13rem",
      //width: "100px",
      render: (
        reportSha,
        record // 使用 record 參數來獲取當前行(dataSource)的所有數據(ant design的reder參數)
      ) => (
        <>
          <Div d="flex" flexDir="row">
            <Button
              rounded="lg"
              textSize="14px"
              bg="#79384A"
              d="flex"
              justify="center"
              align="center"
              textWeight="600"
              border="1px solid"
              borderColor="#79384A"
              hoverBg="#FFFFFF"
              hoverBorderColor="#79384A"
              hoverTextColor="#79384A"
              disabled={reportSha !== "" ? false : true}
              onClick={() =>
                reportSha !== ""
                  ? showEasyReport(record.reportSha)
                  : navigate("*")
              }
            >
              <Text>入門版</Text>
            </Button>

            <Div m={{ l: "0.5rem" }}>
              <Button
                rounded="lg"
                textSize="14px"
                bg="#79384A"
                d="flex"
                justify="center"
                align="center"
                textWeight="600"
                border="1px solid"
                borderColor="#79384A"
                hoverBg="#FFFFFF"
                hoverBorderColor="#79384A"
                hoverTextColor="#79384A"
                disabled={reportSha !== "" ? false : true}
                onClick={() => showDetailReport(record.reportSha)}
              >
                <Text>專業版</Text>
              </Button>
            </Div>
          </Div>
        </>
      ),
    },
    {
      // th
      title: "毛孩姓名",
      dataIndex: "name",
      key: "name",
      //width: isMobile ? "7.5rem" : "15rem",
      // tr
      render: (text) => (
        <Text textSize={isMobile ? "16px" : "header"} textWeight="600">
          {text}
        </Text>
      ),
    },
    {
      title: "品種",
      dataIndex: "strain",
      key: "strain",
      //width: isMobile ? "7.5rem" : "12rem",
      render: (text) => (
        <Text textSize={isMobile ? "16px" : "header"} textWeight="600">
          {text}
        </Text>
      ),
      responsive: isPad ? ["md"] : ["xl"], //reponsive為ant design中的屬性，<768px => 在["md"]尺寸時該列顯示
    },
    {
      title: "檢測申請日期",
      dataIndex: "createDate",
      key: " createDate",
      //width: isMobile ? "7.5rem" : "12rem",
      render: (text) => (
        <Text
          textSize={isMobile ? "16px" : "header"}
          textWeight="600"
          textColor="#000000"
        >
          {text}
        </Text>
      ),
      responsive: isPad ? ["md"] : ["xl"],
    },
    {
      title: "預估完成時間",
      dataIndex: "finishedDate",
      key: "finishedDate",
      sorter: (a, b) => a.name.localeCompare(b.name),
      defaultSortOrder: "descend",
      sortDirections: ["descend"],
      showSorterTooltip: false,
      // width: isMobile ? "10rem" : "12rem",
      render: (text) => (
        <Text
          textSize={isMobile ? "16px" : "header"}
          textWeight="600"
          textColor={text === "待檢體寄回評估" ? "#EB4C27" : "#555555"}
        >
          {text}
        </Text>
      ),
    },
    {
      title: "報告進度",
      dataIndex: "progressType",
      key: "progressType",
      // width: isMobile ? "10rem" : "12rem",
      render: (progressType) => {
        let bg = "#E2FCF0";
        let textColor = "#0A9C55";
        if (progressType === "尚未付款") {
          bg = "#FFE0E0";
          textColor = "#F03D3D";
        } else if (
          progressType === "未取包裹，已被退回" ||
          progressType === "退款處理中" ||
          progressType === "退款成功" ||
          progressType === "品質過低萃取失敗，準備重新寄出"
        ) {
          bg = "#FEF9DE";
          textColor = "#F3B23E";
        }
        return (
          <Tag
            bg={bg}
            textColor={textColor}
            textSize="16px"
            textWeight="600"
            p={{ x: "0.5rem", y: "0.25rem" }}
          >
            {progressType}
          </Tag>
        );
      },
    },
  ];

  // 點按專業版按鈕時就打api，若是200可以前往該頁，若是402要跳pop up訊息
  const [unpaidModal, setUnpaidModal] = useState(false);

  const showEasyReport = (reportSha) => {
    navigate(`/reports/easy-read/${reportSha}`);
  };

  //若api回傳非200/402/500就給錯誤訊息
  const [showErrorNotification, setShowErrorNotification] = useState(false);

  const showDetailReport = (reportSha) => {
    const detailApiUrl = `${process.env.REACT_APP_API_URL}/users/report/premium-report/${reportSha}`;

    const fetchDetailReport = () => {
      fetch(detailApiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            navigate(`/reports/detail/${reportSha}`);
          } else if (response.status === 402) {
            setUnpaidModal(true);
          } else if (response.status === 404) {
            navigate(`/*`);
          } else if (response.status === 500) {
            setShowErrorNotification(true);
          } else {
            return response.json();
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    fetchDetailReport();
  };

  const dataSource = petList.flatMap((pet) => {
    return pet.reports.map((report) => {
      //處理日期空格
      const formatDateString = (dateString) => {
        return dateString.split("-").join(" - ");
      };

      //處裡預估完成時間
      let finishedDateValue;

      switch (report.progress) {
        case "4_SendBack":
        case "5_Extract":
        case "6_OnMashine":
        case "7_Analysis":
        case "8_Finished":
          finishedDateValue = formatDateString(
            report.estimated_completion_time
          );
          break;
        default:
          finishedDateValue = "待檢體寄回評估";
      }

      //處理progressType
      let progressTypeValue;
      switch (report.progress) {
        case "0_WaitingMoney":
          progressTypeValue = "尚未付款";
          break;
        case "1_GetMoney":
          progressTypeValue = "收到款項";
          break;
        case "2_SendPac":
          progressTypeValue = "毛怪寄出檢測包";
          break;
        case "3_ArrPac":
          progressTypeValue = "飼主寄回檢體";
          break;
        case "4_SendBack":
          progressTypeValue = "已送達實驗室";
          break;
        case "5_Extract":
          progressTypeValue = "正在萃取中";
          break;
        case "6_OnMachine":
          progressTypeValue = "上機中";
          break;
        case "7_Analysis":
          progressTypeValue = "數據分析中";
          break;
        case "8_Finished":
          progressTypeValue = "完成報告";
          break;
        case "RePac":
          progressTypeValue = "未取包裹，已被退回";
          break;
        case "ReIng":
          progressTypeValue = "退款處理中";
          break;
        case "ReSuccess":
          progressTypeValue = "退款成功";
          break;
        case "LowQua":
          progressTypeValue = "品質過低萃取失敗，準備重新寄出";
          break;
        default:
          progressTypeValue = "-";
      }

      let createDateString = formatDateString(report.create_date);

      return {
        // key: (index + 1).toString(),
        name: pet.pet_name || "",
        strain: pet.strain || "",
        createDate: createDateString || "",
        finishedDate: finishedDateValue,
        progressType: progressTypeValue,
        reportSha: report.report_sha || "",
      };
    });
  });

  // console.log(dataSource);

  return (
    <>
      {/* api回傳非200/402/500時顯示錯誤訊息 */}
      <Div d="flex" justify="center" align="center">
        <Notification
          d={showErrorNotification ? "flex" : "none"}
          bg="#FFE0E0"
          textColor="#F03D3D"
          isOpen={showErrorNotification}
          onClose={() => setShowErrorNotification(false)}
        >
          發生未知錯誤，請洽客服。
        </Notification>
      </Div>

      {isLoading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <NavBar />

          <Div
            d="flex"
            justify="center"
            align="center"
            m={{ t: { xs: "5.5rem", md: "6.5rem", lg: "7rem" } }}
          >
            <Div
              m={{ b: { xs: "0.75rem", md: "1rem", lg: "1.6rem" } }}
              p={{ x: "2rem", y: "0.75rem" }}
              d="flex"
              justify="center"
              align="center"
              bg="#ffffff"
              rounded="circle"
            >
              <Text
                textSize={{ sm: " reportHeading2", md: "heading2" }}
                textWeight="700"
              >
                寵物報告列表
              </Text>
            </Div>
          </Div>

          {/* 步驟收合 */}
          <Div
            d={{ xs: "flex", sm: "none" }}
            justify="start"
            align="center"
            m={{ t: "1.5rem" }}
          >
            <Div
              h="2.5rem"
              w="2.5rem"
              bg="#FFFFFF"
              border="1px solid"
              borderColor="#A6A4A1"
              rounded="lg"
              m={{ l: "16px" }}
              d="flex"
              justify="center"
              align="center"
            >
              <SmallModal object={<Information />}>
                <Text
                  textSize="heading2"
                  textWeight="600"
                  textAlign="center"
                  m={{ t: "0rem", b: "1.5rem" }}
                >
                  檢測報告進度
                </Text>
                <Div d="flex" flexDir="row">
                  <Step borderColor="#F3B23E" step="STEP 1" text="收到款項" />
                  <Step
                    borderColor="#79384A"
                    step="STEP 2"
                    text="毛怪寄出檢測包"
                  />
                </Div>
                <Div d="flex" flexDir="row">
                  <Step
                    borderColor="#F3B23E"
                    step="STEP 3"
                    text="飼主寄回檢體"
                  />
                  <Step
                    borderColor="#79384A"
                    step="STEP 4"
                    text="已送達實驗室"
                  />
                </Div>
                <Div d="flex" flexDir="row">
                  <Step borderColor="#F3B23E" step="STEP 5" text="正在萃取中" />
                  <Step borderColor="#79384A" step="STEP 6" text="上機中" />
                </Div>
                <Div d="flex" flexDir="row">
                  <Step borderColor="#F3B23E" step="STEP 7" text="數據分析中" />
                  <Step borderColor="#79384A" step="STEP 8" text="完成報告" />
                </Div>
              </SmallModal>
            </Div>
          </Div>

          {/* 步驟展開 */}
          <Div
            d={{ xs: "none", sm: "flex" }}
            justify="space-around"
            m={{ x: { sm: "1rem", md: "3.5rem", lg: "4.5rem" }, t: "4rem" }}
          >
            <Row d="flex" flexGrow="1" justify="space-around">
              <Col
                d="flex"
                flexDir="row"
                justify="space-around"
                size={{ sm: 12, md: 12, lg: 6 }}
              >
                <Step borderColor="#F3B23E" step="STEP 1" text="收到款項" />
                <Step
                  borderColor="#79384A"
                  step="STEP 2"
                  text="毛怪寄出檢測包"
                />
                <Step borderColor="#F3B23E" step="STEP 3" text="飼主寄回檢體" />
                <Step borderColor="#79384A" step="STEP 4" text="已送達實驗室" />
              </Col>
              <Col
                d="flex"
                flexDir="row"
                justify="space-around"
                size={{ sm: 12, md: 12, lg: 6 }}
              >
                <Step borderColor="#F3B23E" step="STEP 5" text="正在萃取中" />
                <Step borderColor="#79384A" step="STEP 6" text="上機中" />
                <Step borderColor="#F3B23E" step="STEP 7" text="數據分析中" />
                <Step borderColor="#79384A" step="STEP 8" text="完成報告" />
              </Col>
            </Row>
          </Div>

          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#79384A",
              },
              components: {
                Table: {
                  headerBg: "#ffffff",
                  headerColor: "#A6A4A1",
                  borderColor: "#A6A4A1",
                  cellFontSize: "16px",
                },
              },
            }}
          >
            <Div
              bg={isMobile ? "transparent" : "#ffffff"}
              align="center"
              m={{
                y: { xs: "2.5rem", sm: "3.5rem", md: "4.5rem" },
                l: { xs: "1rem", md: "3.5rem" },
                x: { lg: "7rem" },
              }}
            >
              {/* 搜尋框 */}
              {/* <Div p={{ y: "1rem", x: isMobile ? "0rem" : "1rem" }}>
                <Div w={isMobile ? "100%" : "30%"} h="50%">
                  <Input
                    placeholder="Search asset"
                    p={{ x: "2.5rem" }}
                    prefix={
                      <Icon
                        name="Search"
                        size="20px"
                        cursor="pointer"
                        onClick={() => console.log("clicked")}
                        pos="absolute"
                        top="50%"
                        left="1rem"
                        transform="translateY(-50%)"
                      />
                    }
                  />
                </Div>
              </Div> */}
              {/* table */}
              <Table
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                scroll={{ x: 768 }}
              />
              {/* 分頁 */}
              {/* <Div p={{ y: "2rem" }} d="flex" justify="center">
                <Pagination showQuickJumper defaultCurrent={1} total={30} />
              </Div> */}
            </Div>
          </ConfigProvider>

          <Modal
            isOpen={unpaidModal}
            onClose={() => setUnpaidModal(false)}
            rounded="md"
            maxW="48rem"
            align={{ xs: "start", md: "center", lg: "center" }}
            justify="center"
          >
            <Icon
              name="Cross"
              pos="absolute"
              top="1rem"
              right="1rem"
              size="16px"
              onClick={() => setUnpaidModal(false)}
              cursor="pointer"
            />
            <Div>
              <Text textSize="header" textColor="#242424" textWeight="900">
                解鎖專業腸道菌相檢測報告
              </Text>
              <Row
                d="flex"
                justify="center"
                align="center"
                m={{ t: { xs: "1.5rem", md: "0rem" } }}
              >
                <Col size={{ xs: 12, md: 6, lg: 6 }}>
                  <Div
                    d="flex"
                    flexDir="column"
                    align="center"
                    justify="center"
                    rounded={{
                      b: "xl",
                    }}
                  >
                    <Div
                      w="70px"
                      h="70px"
                      bgImg={CardPage20}
                      bgSize="cover"
                      bgPos="center"
                    />

                    <Div m={{ t: "1rem" }}>
                      <Text
                        textSize="24px"
                        textColor="#242424"
                        textWeight="700"
                        textAlign="center"
                      >
                        \ 歡迎解鎖更多內容 /
                      </Text>

                      <Div
                        d="flex"
                        flexDir="column"
                        justify="center"
                        align="center"
                        m={{ t: "1.5rem" }}
                      >
                        <Text
                          tag="span"
                          textSize="16px"
                          textColor="#242424"
                          textWeight="700"
                          m={{ b: "0.5rem" }}
                        >
                          想了解更多毛孩
                          <Text
                            tag="span"
                            textSize="16px"
                            textColor="#EB4C27"
                            textWeight="700"
                            textDecor="underline"
                          >
                            健康資訊
                          </Text>
                          嗎?
                        </Text>
                        <Text
                          tag="span"
                          textSize="16px"
                          textColor="#242424"
                          textWeight="700"
                          m={{ b: "0.5rem" }}
                        >
                          想了解毛孩
                          <Text
                            tag="span"
                            textSize="16px"
                            textColor="#EB4C27"
                            textWeight="700"
                            textDecor="underline"
                          >
                            適合補充的營養
                          </Text>
                          嗎?
                        </Text>
                        <Text
                          tag="span"
                          textSize="16px"
                          textColor="#242424"
                          textWeight="700"
                          m={{ b: "0.5rem" }}
                        >
                          想為毛孩健康
                          <Text
                            tag="span"
                            textSize="16px"
                            textColor="#EB4C27"
                            textWeight="700"
                            textDecor="underline"
                          >
                            採取行動
                          </Text>
                          嗎?
                        </Text>
                      </Div>
                    </Div>
                  </Div>
                </Col>
                <Col size={{ xs: 12, md: 6, lg: 6 }}>
                  <Div
                    d="flex"
                    flexDir="column"
                    justify="center"
                    align="center"
                  >
                    <Div
                      d="flex"
                      flexDir="column"
                      align="center"
                      justify="center"
                      rounded={{
                        b: "xl",
                      }}
                      m={{ t: "2rem", x: "3rem" }}
                    >
                      <Div m={{ t: "0.5rem" }}>
                        <Image src={ToBeUnlocked} w="220px" />
                      </Div>
                      <Div d="flex" justify="center" align="center">
                        <Text
                          tag="span"
                          textSize="header"
                          textColor="#242424"
                          textWeight="700"
                        >
                          NT $
                        </Text>
                        <Text
                          tag="span"
                          textSize="2.25rem"
                          textColor="#242424"
                          textWeight="700"
                        >
                          999
                        </Text>
                      </Div>
                    </Div>
                    <Div
                      d="flex"
                      flexDir="row"
                      justify="center"
                      align="flex-start"
                      m={{ t: "1.25rem" }}
                    >
                      <Div d="flex" flexDir="column">
                        <Text
                          textSize="subheading1"
                          textWeight="500"
                          textColor="#555555"
                        >
                          📍優勢菌概況
                        </Text>
                        <Text
                          textSize="subheading1"
                          textWeight="500"
                          textColor="#555555"
                        >
                          📍益生菌比例
                        </Text>
                        <Text
                          textSize="subheading1"
                          textWeight="500"
                          textColor="#555555"
                        >
                          📍致病菌比例
                        </Text>
                        <Text
                          textSize="subheading1"
                          textWeight="500"
                          textColor="#555555"
                        >
                          📍疾病風險評估
                        </Text>
                        <Text
                          textSize="subheading1"
                          textWeight="500"
                          textColor="#555555"
                        >
                          📍報告總評
                        </Text>
                        <Text
                          textSize="subheading1"
                          textWeight="500"
                          textColor="#555555"
                        >
                          📍核心菌群
                        </Text>
                        <Text
                          textSize="subheading1"
                          textWeight="500"
                          textColor="#555555"
                        >
                          📍菌種功能解析
                        </Text>
                      </Div>

                      <Div d="flex" flexDir="column" m={{ l: "1rem" }}>
                        <Text
                          textSize="subheading1"
                          textWeight="500"
                          textColor="#555555"
                        >
                          📍多樣性分析
                        </Text>
                        <Text
                          textSize="subheading1"
                          textWeight="500"
                          textColor="#555555"
                        >
                          📍報告摘要
                        </Text>
                        <Text
                          textSize="subheading1"
                          textWeight="500"
                          textColor="#555555"
                        >
                          📍益生菌檢測
                        </Text>
                        <Text
                          textSize="subheading1"
                          textWeight="500"
                          textColor="#555555"
                        >
                          📍致病菌檢測
                        </Text>
                        <Text
                          textSize="subheading1"
                          textWeight="500"
                          textColor="#555555"
                        >
                          📍整體腸道菌比例
                        </Text>
                        <Text
                          textSize="subheading1"
                          textWeight="500"
                          textColor="#555555"
                        >
                          📍客製化飲食推薦
                        </Text>
                      </Div>
                    </Div>
                  </Div>
                </Col>
              </Row>
              <Div m={{ t: "1.5rem" }} d="flex" justify="center">
                <Div
                  w="200px"
                  onClick={() =>
                    window.open("https://lin.ee/zjeRT9i", "_blank")
                  }
                >
                  <Btn text="立即解鎖" />
                </Div>
              </Div>
            </Div>
          </Modal>
        </>
      )}
    </>
  );
};

export default PetList;
